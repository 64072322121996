import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import RecentPosts from "../../../components/RecentPosts";
import Tags from "../../../components/Tags";
import Instagram from "../../../components/Instagram";
import Twitter from "../../../components/twitter";
// images
import breadcumb from "../../../images/breadcumb/news-2.jpg";
import blog4 from "../../../images/blog-details/Assigned-in-UAE.jpg";
import {Helmet} from "react-helmet";

import "./style.scss";

const IntellectualProperty = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Legal Emirates </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/blogs/intellectual-property "
        />
        <meta
          name="description"
          content="Learn more about law terms and find about Legal Emirates attorneys, law teams and many more through our blogs. "
        />
      </Helmet>

      <header className="headerArea">
        {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
        <HeaderBotton className="headerBottomArea" />
      </header>
      <div
        className="blogPostArea blogPostLeftArea"
        style={{ paddingTop: "45px" }}
      >
        <center>
          <h1 style={{ fontWeight: "550" }}> Intellectual Property Rights</h1>
          <h2 style={{ fontWeight: "550" }}>
            {" "}
            How Intellectual Property Rights are Assigned in UAE
          </h2>
          <p style={{ fontWeight: "550" }}>25 Sep 2020/Dubai</p>
        </center>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="blogPostWrapper1">
                <div className="blogPostImg">
                  <img src={blog4} alt="" />
                </div>
                <div className="blogPostContent" style={{ marginTop: "30px" }}>
                  <p style={{ fontWeight: "550" }}>
                    This is a legal document, declaration of transferring the
                    intellectual property rights from any individual or
                    corporation onto another individual or corporation. If the
                    intellectual property that is permanently assigned to the
                    other party, the previous party will not have rights on that
                    property any longer.{" "}
                  </p>
                  <p style={{ fontWeight: "550" }}>
                    Trademarks, any inventions, logos, company or business
                    names, domain name rights, copyrights, etc. are also called
                    intellectual property rights. Any such agreement of property
                    rights can also have a release as if the assigner says that
                    he has no power to deal with legal matters involved related
                    to those property rights. This should be approved by all the
                    parties involved in that particular agreement.{" "}
                  </p>
                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Elements required when assigning the intellectual property
                    rights
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    The requirements for the declaration of any intellectual
                    property right may differ from state to state, but some of
                    the major ingredients are the same. If we talk about the
                    UAE, the property agreement will include defining the
                    property to be assigned, moral rights, release, warranties,
                    and wavier. Dispute resolution can be added to some
                    additional points after the willingness of both the parties.{" "}
                  </p>
                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Best time in drafting the intellectual property agreement in
                    UAE
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    The agreement of intellectual property rights may be
                    utilized for different types of situations in the UAE. The
                    time when the business entities are incorporating new
                    businesses or the time when parties buying or selling the
                    products.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Who should sign the agreement for assigning intellectual
                    property rights?
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    The party that assigns the rights of intellectual property
                    is mentioned as assigner, and the party that is receiving
                    the rights is referred to as assignee. Witnesses also
                    required to add their signatures and the public notary has
                    to certify the legal contract.{" "}
                  </p>

                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Mandatory provisions of an agreement of intellectual
                    property assignment{" "}
                  </h4>

                  <p style={{ fontWeight: "550" }}>
                    There is not any particular standard form of this kind of
                    agreement that is used in UAE. But writing and signing such
                    documents require huge care because these documents are the
                    bindings between two parties. Generally, in UAE, in most of
                    the trademark assignments there is at least one of the
                    provisions listed below:
                  </p>

                  <ul style={{ paddingLeft: "30px" }}>
                    <li>
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "18px",
                          marginBottom: "0px",
                        }}
                      >
                        • Assignment provision
                      </p>
                      <p style={{ fontWeight: "550" }}>
                        This provision requires one party to assign intellectual
                        property to another party.
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "18px",
                          marginBottom: "0px",
                        }}
                      >
                        • Disclosure provision
                      </p>
                      <p style={{ fontWeight: "550" }}>
                        This provision requires a party to inform another party
                        of intellectual property rights’ existence which was
                        developed as per the assignment provision.
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "18px",
                          marginBottom: "0px",
                        }}
                      >
                        • Power of attorney
                      </p>
                      <p style={{ fontWeight: "550" }}>
                        This provision for a power of attorney provides a
                        guarantee that a party may administer or register
                        ownership rights of intellectual property without the
                        presence of another party.
                      </p>
                    </li>
                  </ul>
                  <h4 style={{ fontWeight: "550", marginTop: "20px" }}>
                    {" "}
                    Kind of intellectual property
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    The most common kinds of intellectual properties involve
                    patents, trademarks, copyrights, and trade secrets.{" "}
                  </p>
                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    How can you make sure your property is not stolen?
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    Trademark registration in UAE is mandatory when providing
                    intellectual property protection. But the process is not so
                    simple as always, sometimes it becomes complex and
                    time-consuming. We suggest taking proper services of legal
                    experts who deal with such kind of matters. We have a team
                    of attorneys who are specialized in dealing with the
                    agreements and provisions required assigning or receiving
                    any intellectual property rights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default IntellectualProperty;
/* IntellectualProperty */
