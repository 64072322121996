import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const UPDATE_PCUSTOMER_BASIC_INFO = "UPDATE_PCUSTOMER_BASIC_INFO";
export const UPDATE_PCUSTOMER_BASIC_INFO_LOADING =
"UPDATE_PCUSTOMER_BASIC_INFO_LOADING";
export const UPDATE_PCUSTOMER_BASIC_INFO_LOADING_FAILED =
"UPDATE_PCUSTOMER_BASIC_INFO_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const updateBasicInfoCustomerLoading = ms => ({
    type: UPDATE_PCUSTOMER_BASIC_INFO_LOADING,
    payload: ms
    });
    
    export const updateBasicInfoCustomerLoadingFailed = ms => ({
    type: UPDATE_PCUSTOMER_BASIC_INFO_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function updateBasicInfoCustomer(obj) {
    const request = axios.patch(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/users/updateMe`,obj,{
        headers: { 'Authorization': 'bearer ' +localStorage.jwtToken}}
  );
    return dispatch => {

    dispatch(updateBasicInfoCustomerLoading());

    request
    .then(response => {   
 
    if (response.status == 200) {
      console.log(response,"response updateBasicInfoCustomer") 
    dispatch({
    type: UPDATE_PCUSTOMER_BASIC_INFO,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        updateBasicInfoCustomerLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(updateBasicInfoCustomerLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(updateBasicInfoCustomerLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(updateBasicInfoCustomerLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


