import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import "./style.css";
const PrivacyPolicy = ({ className }) => {
  return (
    <div className="PrivacyPolicy">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10">
            <h2 style={{ color: "white", marginTop: "20px" }}>
              LEGAL EMIRATES PRIVACY POLICY{" "}
            </h2>
            <h3 style={{ color: "white", textTransform: "capitalize" }}>
              {" "}
              APPLICATION OF THIS PRIVACY POLICY
            </h3>
            <p style={{ color: "whitesmoke" }}>
              These privacy policies apply to all the users and visitors who
              want to use the Legalemirates.com website operated and owned by
              BADER HAMAD AL ZAABI Advocate & Legal Consultations. Kindly read,
              the Policy we have on your personal information and go through all
              the points cautiously before taking our services to avoid any
              trouble. Reading this page will help you know the use of the
              content of this Website, legal rights, and obligations.
            </p>
            <p style={{ color: "whitesmoke" }}>
              Whilst reading these conditions if at any point you feel disagree
              with something then kindly discontinue using this Website or
              contact us immediately to avoid any inconvenience. The content of
              this Website always keeps updating including the privacy policies
              and terms and conditions so keep yourself up to date and try not
              to miss any important information during the use of the Website.
            </p>
            <p style={{ color: "whitesmoke" }}>
              This privacy policy is designed as per the rules of protecting
              user data. We respect and protect clients' and visitors’ privacy
              during the visit to our website or when sharing personal
              information through this website.{" "}
            </p>

            <h3 style={{ color: "white" }}>
              COLLECTION OF PERSONAL INFORMATION{" "}
            </h3>
            <p style={{ color: "whitesmoke" }}>
              This policy gives you an idea of how we collect, use, and manage
              the data you share with us through this website when you request
              something from us, when you ask us about legal services, or when
              there is a meeting with our attorney.{" "}
            </p>
            <p style={{ color: "whitesmoke" }}>
              We also collect data by just asking you for it, depending upon the
              services you want from us. We may ask you to provide:{" "}
            </p>
            <ul style={{ color: "whitesmoke" }}>
              <li>• Your name</li>
              <li>• Your email address</li>
              <li>• Your landline and cellphone numbers </li>
              <li>
                • Any information you share while filling out any contact form
                on this website
              </li>
              <li>• Your location (city, region, or town, etc.)</li>
              <li>• Your job title or about your business </li>
              <li>• Your IP Address </li>
              <li>• Yours delivered or uploaded data </li>
              <li>• Your curriculum vitae and cover </li>
            </ul>
            <p style={{ color: "whitesmoke" }}>
              In addition to all these, we also collect technical information
              regarding your visit to our website, for instance, the services
              you viewed, the information you searched for, page response time,
              length of visits, and page interaction data, etc.{" "}
            </p>

            <h3 style={{ color: "white" }}>
              {" "}
              PURPOSE OF COLLECTING INFORMATION{" "}
            </h3>
            <p style={{ color: "whitesmoke" }}>
              We may use this information to respond to your queries as well.
              However, mainly we use your personal information for our legal
              business purposes such as:
            </p>
            <ul style={{ color: "whitesmoke" }}>
              <li>
                • To respond to queries and provide our services accordingly
              </li>
              <li>
                • To make mutual relationship with our clients and visitors{" "}
              </li>
              <li>• To market our firm and legal services </li>
              <li>• To ensure network and information security</li>
              <li>
                • To keep in touch with you and inform you about new services{" "}
              </li>
              <li>• To notify you about the changes </li>
              <li>• To investigate any complaint, you make </li>
            </ul>
            <p style={{ color: "whitesmoke" }}>
              We advise you to provide true and authentic information about you
              and your legal issue and not to hide anything from us if you want
              the best services from us to fulfill your purpose.{" "}
            </p>

            <h3 style={{ color: "white" }}>
              HOW AND WHEN WE SHARE YOUR INFORMATION.
            </h3>

            <p style={{ color: "whitesmoke" }}>
              In some cases, we are obliged to disclose your information to
              achieve the best possible results for you. Nevertheless, we
              believe we must make sure the parties or people with whom we are
              sharing your information are fully trusted and will not use that
              information for other purposes. Your data may be shared with
              these:
            </p>
            <ul style={{ color: "whitesmoke" }}>
              <li>
                • Our accountants, auditors, and attorneys when we ask them for
                legal advice
              </li>
              <li>
                • Any third-party, because if we have to protect something, we
                have to disclose information{" "}
              </li>
              <li>
                • Any other third-party, to do things inline to carry out the
                needs of the court, government agency, or other law authorities{" "}
              </li>
            </ul>

            <h3 style={{ color: "white" }}>
              {" "}
              HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?{" "}
            </h3>
            <p style={{ color: "whitesmoke" }}>
              It is part of the policy to keep your personal information for a
              long time, the time required for the very specific purpose it has
              been collected from you. As these are legal matters, we are
              talking about and in some conditions, we are also bound by law to
              keep your information periods, some of the basic information may
              stay with us for a very long time.
            </p>

            <h3 style={{ color: "white" }}> THE RIGHTS YOU HAVE </h3>

            <p style={{ color: "whitesmoke" }}>
              You have these rights under the specific circumstances
            </p>
            <ul style={{ color: "whitesmoke" }}>
              <li>
                ✓ Access and Accuracy
                <p style={{ color: "whitesmoke" }}>
                  You have the right to ask about the copies of your data we
                  shared with any third party and you can take those copies too.
                  You can confirm the authenticity of the party with whom we are
                  sharing your data at any time during the process.{" "}
                </p>
              </li>
              <li>
                ✓ Erasure or Blocking
                <p style={{ color: "whitesmoke" }}>
                  This is also known as “the right to be forgotten” and in
                  simple terms, it enables you to ask for the erasure of the
                  data of your personal information that is being processed.{" "}
                </p>
              </li>
              <li>
                ✓ Data portability
                <p style={{ color: "whitesmoke" }}>
                  You have the right to use your personal information for your
                  purposes across different companies.{" "}
                </p>
              </li>
              <li>
                ✓ To Object
                <p style={{ color: "whitesmoke" }}>
                  You have the right to object to a certain type of processing
                  on legitimate grounds.{" "}
                </p>
              </li>
              <li>
                ✓ Rectification
                <p style={{ color: "whitesmoke" }}>
                  If you find any of your personal information inaccurate or
                  incomplete you can simply ask us to rectify it.{" "}
                </p>
              </li>
              <li>
                ✓ Complaints
                <p style={{ color: "whitesmoke" }}>
                  If you believe that the protection and security of your data
                  have been compromised, you have the right to record a
                  complaint to the data protection authority.{" "}
                </p>
              </li>
              <li>
                ✓ Withdraw and Clearance
                <p style={{ color: "whitesmoke" }}>
                  When personal information is processed on the ground of
                  agreement, you have the right to withdraw from the agreement.
                </p>
              </li>
            </ul>
            <h3 style={{ color: "white" }}> CONFIDENTIALLY </h3>

            <p style={{ color: "whitesmoke" }}>
              CONFIDENTIALLY We acknowledge that the information you share may
              be confidential, and we are obliged to maintain that information a
              secret and to protect that information in accordance with the
              privacy notice and applicable law. We assure you that we do not
              take any kind of advantage from your personal information; we do
              not sell or distribute the personal information to any third party
              for our interests.
            </p>
            <h3 style={{ color: "white" }}> INFORMATION SECURITY </h3>
            <p style={{ color: "whitesmoke" }}>
              Suitable management is implemented and technical measures have
              been taken by our law firm to protect your personal information
              from any loss, harm, or misuse.
            </p>

            <h3 style={{ color: "white" }}>CHANGES TO THIS PRIVACY POLICY</h3>
            <p style={{ color: "whitesmoke" }}>
              Our privacy policy may experience time-to-time changes. Please
              keep in touch with our management and keep on checking this policy
              every time you wish to take our services. The changes are
              effective as soon as they are written on this page.
            </p>
            <h3 style={{ color: "white" }}>CONTACT DETAILS</h3>
            <p style={{ color: "whitesmoke" }}>
              If you have any questions related to the policy or our way of
              processing your personal information, please contact us.
            </p>
          </div>
          <div className="col-lg-2 col-12"></div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
