import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const MoneyLaunderingContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>		Anti-Money Laundering    </h3>
               <p>Money laundering is the criminal act of earning money through illegal means. Money laundering can be done by any individual or any organization to gain money or property for personal advantages. Every financial firm is facing immense pressure to obey the Anti-Money Laundering rules and regulations. Dreaming skyrocket progress of your financial firm or organization and having the issues related to AML regulations at the same time is indeed a difficult problem to solve. In times like these where transactions of millions or even billions are being made the AML checks are not optional, they are mandatory.  </p>
               <p>Following are the services Law Firm is offering in this regard:</p>
                 <ul style={{color:'#666666'}}>
                  <li>•	Compliance Policy and Approaches  </li> 
                  <li>•	Assessment of the tools  </li>
                  <li>•	Anti-Money Laundering policy review  </li>  
                  <li>•	Anti-Money Laundering audit and reporting</li>
                  <li>•	Anti-corruption frameworks </li>
                  <li>•	Anti-financial frauds frameworks </li>
                  <li>•	And many more </li>
                     </ul>
              {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default MoneyLaunderingContent