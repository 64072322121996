import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const BussinessContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Business and corporate cases </h3>
        <p>
          Focusing on little details, we will provide solutions to the problems
          that can hurt your business or cause legal disputes. And we will help
          you in business formation, making company policy, sales and consumer
          goods, intellectual property, taxes and even file for bankruptcy
          because lawyers do also help in bad times. We can also provide our
          services for immigration law because it is an important factor when it
          comes to expanding businesses and needing foreign workers.{" "}
        </p>

        <p>
          The Law Firm provides services in all types of legal issues related to
          clients’ businesses and transactions. Our team will assist you in:{" "}
        </p>

        <ul style={{ color: "#666666" }}>
          <li>Preparing shareholder meetings;</li>
          <li>Online, physical document submission; </li>
          <li>Providing registered office;</li>
          <li>Interacting with regulatory authorities on your behalf; </li>
          <li>Residency and immigration matters;</li>
          <li>Certifying the documents as true copies; </li>
          <li>Witnessing the execution and much more.</li>
        </ul>
        <br />
        <h5>Client’s review after taking services from us </h5>
        <p>
          “This law firm has the most caring lawyers out there. Yes, I do
          personally know the CEO of this firm and that’s why I am not going to
          say so many good things. They are professional, honest, and get the
          job done on time. I had two separate issues and they helped me solve
          them within days. I would surely recommend this Law Firm”
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default BussinessContent;
