import React, { Component } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, Link } from 'react-router-dom'
import './style.scss'

class HeroSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            arrows: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        return (
            <Slider className={this.props.className} {...settings}>
                {this.props.sliders.map((slider, i) => (
                    <div
                        key={i}
                        className={`slideWrapper ${slider.images}`}
                    >
                        <div
                            className="sliderContent">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-lg-8">
                                        <p>{slider.text}</p>
                                        <h1><span>{slider.title}</span> <span>{slider.subTitle}</span></h1>
                                        {/* btnStyle btnStyle3 */}
                                        <div className="gggg btnStyle3" >
                                            <Link  className="btnjks" to={slider.rout}> <span  className="tag-home-slide-size16" >#</span><span  className="tag-home-slide-size15"><h1 style={{display:'inline',lineHeight:'45px'}}  className="tag-home-slide-size">{slider.button}</h1></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        )
    }
}
export default HeroSlider