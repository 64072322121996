import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
/* /news/ADJD_Official */
const cetagoryMenu = [
    {name:'Dubai Media Office',route:'/news/DXBMediaOffice'},
    {name:'Smart Dubai',route:'/news/SmartDubai'},
    { name: 'Ministry of Justice – United Arab Emirates',route:'/news/MOJ_UAE' },
    {name:'Dubai Courts',route:'/news/DubaiCourts'},
    {name:'DIFC Courts',route:'/news/DIFCCourts'},
    {name:'Dubai Police',route:'/news/dubaipolice'},
    { name: "Abu Dhabi's police",route:'/news/adpolice' },
    { name: 'Sharjah Police',route:'/news/ShjPolice' },
    { name: 'Ajman Police',route:'/news/ajmanpolice' },
    { name: 'Ras Al Khaimah Police',route:'/news/rakpolice' },
    { name: 'INTERPOL',route:'/news/interpol' },
    { name: 'The Public Prosecution-UAE',route:'/news/UAE_PP' },
    { name: 'Compte officiel de la Court internationale de Justice ',route:'/news/CIJ_ICJ' },
    { name: 'TAMM – Abu Dhabi Government Services ',route:'/news/Abudhabitamm' },
    { name: 'The official portal of Govt. of the UAE',route:'/news/uae' },
    { name: 'Sharjah e-Government',route:'/news/sharjah' },
    { name: 'Department of Digital Ajman',route:'/news/AjmanDigitalGov' },
    { name: 'RAK e-Government',route:'/news/RAKeGov' },
    { name: 'Ras Al Khaimah Courts',route:'/news/RakCourts' },
    { name: 'Smart Emirates government',route:'/news/UAEmGov' },
    { name: "Abu Dhabi Judicial Department",route:'/news/ADJD_Official' },
    {name:'Sharjah Government Media Bureau',route:'/news/Sharjahmedia'},
    {name:'Kentucky Courts',route:'/news/kentuckycourts'},
    { name: 'The Ministry of Health and Community Protection – UAE',route:'/news/mohap' },
    {name:'Sheikh Mohamed bin Zayed Al Nahyan ',route:'/news/MohammedZAid'},
    {name:'Sheikh Mohammed bin Rashid Al Maktoum',route:'/news/SheikhMohammed'},
    {name:'Hamdan bin Mohammed',route:'/news/Hamdan'},
    {name:'Mohammed bin Salman',route:'/news/PrinceSalman'},
    { name: 'President of Russia',route:'/news/KremlinRussia' },
    { name: 'Angela Merkel',route:'/news/AngelaMerkeICDU' },
    { name: 'Emmanuel Macron',route:'/news/EmmanuelMacron' },
    { name: 'Steffen Seibert',route:'/news/RegSprecher' },
    { name: 'Boris Johnson',route:'/news/BorisJohnson' },
    { name: 'Donald J. Trump',route:'/news/trump' },
    { name: 'Xi Jinping Looking At Things',route:'/news/Zaikandongxi' },
    { name: 'Imran Khan',route:'/news/ImranKhanPTI' },
    { name: 'Narendra Modi',route:'/news/Narendramodi' },
    { name: 'Abdelfattah Elsisi',route:'/news/AlsisiOfficial' },
    { name: 'Israel in Arabic',route:'/news/IsraelArabic' },
    { name: 'BBC News (World)',route:'/news/BBCWorld' },
    { name: 'CNN International',route:'/news/CNNInternational' },
    { name: 'RT, the global /news/ network',route:'/news/RT_com' },
    { name: 'Newspaper, every day',route:'/news/Alyaum' },
    { name: 'Gulf News Video',route:'/news/GulfNewsVideo' },
    { name: 'Bloomberg Government',route:'/news/BGOV' },
    { name: 'CNBC International',route:'/news/CNBCi' },
    { name: 'China Daily',route:'/news/ChinaDaily' },
    { name: 'Forbes',route:'/news/Forbes' },
    { name: 'CGTN',route:'/news/CGTNOfficial' },
    { name: 'Dubai for Government Excellence',route:'/news/dubaiexcellence' },
    { name: 'CDA Dubai',route:'/news/cdadubai' },
    { name: 'Dubai Economy',route:'newDubai_DED' },
    { name: 'Dubai Health Authority',route:'/news/dhadubai' },
    { name: 'Dubai Municipality',route:'/news/municipality' },
    {name:'Dubai Chamber',route:'/news/DubaiChamber'},
    { name: 'The Telecommunications Regulatory Authority – UAE',route:'/news/TheUAETRA' },
    { name: 'OML',route:'/news/OklaMuniLeague' },
    { name: 'NASA',route:'/news/NASA' },
    { name: 'European Space Agency',route:'/news/ESA' },
    { name: 'SpaceX',route:'/news/SpaceX' },
    { name: 'Emirates Space Agency',route:'/news/Uaespaceagency' },
    { name: 'The International Air Transport Association (IATA) ',route:'/news/IATA' },
    { name: 'Emirates Airline',route:'/news/emirates' },

    { name: 'SpacePort.ae',route:'/news/SpacePortUAE' },
    { name: 'Central intelligence Agency',route:'/news/CIA' },
    { name: 'United Nations',route:'/news/UnitedNations' },
    { name: 'World Health Organization (WHO)',route:'/news/who' },
    { name: 'UN Human Rights',route:'/news/humanrights' },
    { name: 'Health Department - Abu Dhabi',route:'/news/dohsocial' },
    { name: 'WOW Electronic Transport Services',route:'/news/wow' },
    { name: 'Office for Artificial Intelligence – UK',route:'/news/ArtificialIntelligence' },
    { name: 'MOI – Services',route:'/news/MOIUAE' },
    { name: 'US Labor Department',route:'/news/USLabor' },
    { name: 'Ministry of Human Resources and Emiratisation',route:'/news/UMOHRE_UAE' },
    { name: 'General Authority of Islamic Affairs and Endowments – UAE',route:'/news/AwqafUAE' },
    { name: 'Al Bayan Newspaper',route:'/news/AlBayan' },
    { name: 'Khaleej Times',route:'/news/khaleejtimes' },

    
  ]
  /* /news/Uaespaceagency */
function CetagorySidebar1 ({ className, title }) {
function closeNav(){
  
    document.getElementById("mySidenav").style.width = "0";
}
function openNav(){
   
    document.getElementById("mySidenav").style.width = "250px";  
}
    return (
        <div>
            {/* #c0b596 */}
        <div className="cetagoryWrap sidenav1">
            <h3 className="sidebarTitle" >News</h3>
            <ul>
       
                {cetagoryMenu.map((cetagory,i) => {
                  
                    return <li key={i}><Link exact style={{color:window.location.href.split('/')[window.location.href.split('/').length-1]===cetagory.route.split("/")[cetagory.route.split("/").length-1]?"#c0b596":""}} onClick={()=>{window.scrollTo({top:600, behavior: 'smooth'})}}  to={cetagory.route}>{cetagory.name}</Link></li>
               
                    
                } )}
             </ul>
             </div>
<div id="mySidenav" className="sidenav">
 
  <div className="cetagoryWrap">
            <h3 className="sidebarTitle" >Our News  <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a></h3>
            <ul>
                {cetagoryMenu.map((cetagory,i) => {
                  
                    return <li key={i}><Link exact onClick={closeNav} /* onClick={()=>{window.scrollTo(0,900)}} */ to={cetagory.route}>{cetagory.name}</Link></li>
               
                    
                } )}
             </ul>
             </div>
</div>
<div style={{marginBottom:'20px',marginTop:'-56px'}}>
<span className="sidenav2" style={{fontSize:'25px',cursor:'pointer'}} onClick={openNav}>&#9776;  News Menu</span>
</div> 
        </div>
    )
}
export default CetagorySidebar1