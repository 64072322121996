import React, { useState, Fragment,useEffect } from 'react'
import Complain from './complain'
import JoinTeam from './joinTeam'
import { NavLink, Link,withRouter } from 'react-router-dom'
import './style.scss'
import * as Actions from "./store/actions";
import { compose } from "redux";
import '../Form/style.scss';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import '../HeaderBottom/style.scss'
function HeaderTop (props) {
    const [logoFlag, setlogoFlag] = useState(false)
    const [authFlag, setAuthFlag] = useState(true)
    const [adminAuth, setAdminAuth] = useState(true)
    const dispatch = useDispatch();



    const CurrentUser = useSelector(
      ({ HeaderTop1 }) => HeaderTop1.authRiderReducer
       ); 
        useEffect(() =>
 {
  if(CurrentUser.isAuthenticated){
      setAuthFlag(true)
    /*   console.log(CurrentUser,"CurrentUser header") */
  }
}
);
/* authRiderReducer */
const CurrentAdmin = useSelector(
  ({ HeaderTop1 }) => HeaderTop1.AdminAuthRiderReducer
   ); 
    useEffect(() =>
{
if(CurrentAdmin.isAuthenticated){
  setAdminAuth(true)
  /* console.log(CurrentUser,"CurrentUser header") */
}
}
);


    window.setInterval(()=>{
        if(localStorage.hasOwnProperty('language')){
          if (localStorage.language == "Arabic"){
    setlogoFlag(true)
          }else{
            setlogoFlag(false)
          }
        }
    
      
    },100);
  function  handleAlert(){
      toast.error('Signup first to get access to our premium services', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    const language12=  <div 
    id="google_translate_element"
  >
      </div>
    return (
      <div className={props.className}>
        <div className="container">
          <div className="headerTopMainWrapper">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 col-lg-4">
                {/* <center>
                  <ul
                    hidden
                    className="d-flex accountLoginArea headerContact myaddressphoneclass"
                    style={{ paddingRight: logoFlag ? "0px" : "",display:"none !important" }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <a
                        style={{ color: "white" }}
                        href="https://g.page/r/CWT_mAPfa4MLEBA"
                        target="_blank"
                      >
                        Dubai, UAE.
                      </a>
                    </li>
                    <li className="my-class-number-space">
                      {" "}
                      <a href="tel:+97142555111" style={{ color: "white" }}>
                        <i className="fa fa-phone"></i> +9714 2555111
                      </a>
                    </li>
                    <li className="my-class-time">
                      <i className="fa fa-clock-o"></i> 8AM - 6PM
                    </li>
                  </ul>
                </center> */}
              </div>

              <div className="col-lg-8 col-md-12 col-sm-12">
                {authFlag || adminAuth ? (
                  <ul className="mainMenuWrap">
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a style={{ fontSize: "11px" }}>
                          {" "}
                          <i class="fa fa-ellipsis-v"></i>
                          <i class="fa fa-ellipsis-v"></i>
                          <i class="fa fa-ellipsis-v"></i> Counselling{" "}
                        </a>
                      </div>
                      <ul className="subMenu">
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-paid">
                            {" "}
                            <i
                              style={{ fontsize: "25px !important" }}
                              class="fa fa-address-card mr-2"
                            ></i>{" "}
                            Counselling paid writing
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="fa fa-address-card mr-2"></i> Counselling paid writing </a> */}
                        </li>
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-visually">
                            {" "}
                            <img
                              style={{ width: "20px", marginRight: "3px" }}
                              src={require("../../images/topHeader/322-01.png")}
                            />{" "}
                            Counselling is paid visually
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="fa fa-braille mr-2"></i> Counselling is paid visually </a> */}
                        </li>
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-free-time">
                            {" "}
                            <i
                              style={{ fontsize: "25px !important" }}
                              class="	fa fa-balance-scale mr-2"
                            ></i>{" "}
                            Free on Time Counselling
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="	fa fa-balance-scale mr-2"></i> Free on-time counseling</a> */}
                        </li>
                      </ul>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a
                          style={{ fontSize: "11px" }}
                          href="https://us02web.zoom.us/j/8109890170?pwd=UUlaaUQvMjdsNmdweWZwdzI3aldGdz09"
                          target="_blank"
                        >
                          Live Meeting{" "}
                        </a>
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a
                          style={{ fontSize: "11px" }}
                          href="https://calendly.com/legal_emirates"
                          target="_blank"
                        >
                          Appointment{" "}
                        </a>
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <JoinTeam />
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      <Complain />
                    </li>

                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">{language12}</div>
                    </li>
                  </ul>
                ) : (
                  /* second */

                  <ul className="mainMenuWrap">
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a style={{ fontSize: "11px" }}>
                          {" "}
                          <i class="fa fa-ellipsis-v"></i>
                          <i class="fa fa-ellipsis-v"></i>
                          <i class="fa fa-ellipsis-v"></i> Counselling{" "}
                        </a>
                      </div>
                      <ul className="subMenu">
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-paid">
                            {" "}
                            <i
                              style={{ fontsize: "25px !important" }}
                              class="fa fa-address-card mr-2"
                            ></i>{" "}
                            Counselling paid writing
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="fa fa-address-card mr-2"></i> Counselling paid writing </a> */}
                        </li>
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-visually">
                            {" "}
                            <img
                              style={{ width: "20px", marginRight: "3px" }}
                              src={require("../../images/topHeader/322-01.png")}
                            />{" "}
                            Counselling is paid visually
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="fa fa-braille mr-2"></i> Counselling is paid visually </a> */}
                        </li>
                        <li
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: logoFlag ? "right" : "",
                          }}
                        >
                          <NavLink exact to="/counselling-free-time">
                            {" "}
                            <i
                              style={{ fontsize: "25px !important" }}
                              class="	fa fa-balance-scale mr-2"
                            ></i>{" "}
                            Free on Time Counselling
                          </NavLink>
                          {/* <a style={{fontSize:'11px'}} href="#" target="_blank"> <i style={{fontsize:'20px !important'}} class="	fa fa-balance-scale mr-2"></i> Free on-time counseling</a> */}
                        </li>
                      </ul>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a
                          style={{ fontSize: "11px" }}
                          onClick={handleAlert}
                          href="javascript:void(0)"
                        >
                          Live Meeting{" "}
                        </a>
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <a
                          style={{ fontSize: "11px" }}
                          onClick={handleAlert}
                          href="javascript:void(0)"
                        >
                          Appointment{" "}
                        </a>
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">
                        <div
                          onClick={handleAlert}
                          className="livemeeting text-right"
                        >
                          <a style={{ fontSize: "11px", cursor: "pointer" }}>
                            Join Our Team
                          </a>
                        </div>
                      </div>
                    </li>
                    <li style={{ marginTop: "" }}>
                      <div
                        onClick={handleAlert}
                        className="livemeeting text-right"
                      >
                        <a style={{ fontSize: "11px", cursor: "pointer" }}>
                          Suggestion/Complaint
                        </a>
                      </div>
                    </li>

                    <li style={{ marginTop: "" }}>
                      {" "}
                      <div className="livemeeting text-right">{language12}</div>
                    </li>
                  </ul>
                )}

                {/* <a style={{color:'white'}} href="https://us04web.zoom.us/j/8109890170" target="_blank"  > click here</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

 export default compose(
  withReducer("HeaderTop1", reducer),
  connect(),withRouter
)(HeaderTop);  
