import {combineReducers} from 'redux';

import {getCustomerReducer } from './getCustomer';
import {updateProfileReducer} from './updateProfile'
import {updateBasicInfoReducer} from './updateBasicInfo'
import {getFilesReducer} from './getFilesDetail'
import {getCasesReducer} from './getCasesRed'
import {getInvoiceReducer} from './getInvoicesRed'
import {getUserInvoiceReducer} from './getUserInvoices'
const reducer = combineReducers({
   getUserInvoiceReducer,getInvoiceReducer,getCustomerReducer ,updateProfileReducer,updateBasicInfoReducer,getFilesReducer,getCasesReducer
    });
    
    export default reducer;
