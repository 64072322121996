import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const RealStateContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>	Real Estate Cases   </h3>
           <p>As one of the most experienced law firms in the UAE, we have solved hundreds of real estate cases with a very high success rate thanks to our well educated and trained real estate law attorneys. We have a history of many satisfied clients who have taken advantage of us in their legal terms and rights and many of them were able to reclaim their lost properties and investments which have been delayed or canceled.</p>
           <p>As one of the most experienced law firms in the UAE, we have solved hundreds of real estate cases with a very high success rate thanks to our well educated and trained real estate law attorneys. We have a history of many satisfied clients who have taken advantage of us in their legal terms and rights and many of them were able to reclaim their lost properties and investments which have been delayed or canceled.</p>
           <p>In UAE the real estate is one of the most disputed issues and it needs a special way to counter it, we not only take the precautions and protective measures but provide the best legal representations. </p>

           <ul style={{color:'#666666'}}>
               <li>•	Anyone can be our client and we advise across a range of matters:</li>
               <li>•	Financing and securities </li>
               <li>•	Establishment of Real Estate Funds & REITs</li>
               <li>•	Jointly owned property schemes</li>
               <li>•	Jointly owned property schemes</li>
               <li>•	Mixed-use development schemes </li>
               <li>•	Commercial transactions</li>
               <li>•	Dispute resolution </li>
               <li>•	Hotel & Resort Development and Management</li>
           </ul>
               {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default RealStateContent