import React, { Component } from "react";
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Form,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
class Appointment extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  componentWillMount(){

  }
  componentDidMount(){

  }
  render() {
    return (
      <div className="home-page-margin-top15">
     <p onClick={this.handleShow}>click this</p>
        <Modal show={this.state.show} onHide={this.handleClose} size="sm" centered>
          <Modal.Body>
            <div class="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'280px',height:'430px'}}></div>

           
          </Modal.Body>
      
        </Modal>
      </div>
    );
  }
}
export default Appointment;
