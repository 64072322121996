import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import RecentPosts from '../../../components/RecentPosts'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
import Twitter from '../../../components/twitter'
// images
import breadcumb from '../../../images/breadcumb/news-2.jpg'
import blog3 from '../../../images/blog-details/blog-1.jpg'
import {Helmet} from "react-helmet";

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Blogs' }
]

const BlogDetailPage1 = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs - Legal Emirates </title>
                <link rel="canonical" href="https://legalemirates.com/blogs/coronavirus " />
                <meta name="description" content="Learn more about law terms and find about Legal Emirates attorneys, law teams and many more through our blogs. " />
            </Helmet>

            <header className="headerArea">
 {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
            <div className="blogPostArea blogPostLeftArea" style={{paddingTop:'45px'}}>
                <center>
                   <h1 style={{fontWeight:'550'}}> Coronavirus</h1> 
                   <h2 style={{fontWeight:'550'}}>The Future won’t be the same, we hope a bright one. </h2>
                   <p style={{fontWeight:'550'}}>16 May 2020/Dubai</p>
                </center>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                     
                <div  className="blogPostWrapper1">
                    <div className="blogPostImg">
                        <img src={blog3} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                        <p style={{fontWeight:'550'}}>What will be the future of the world? What will be the future for our loved ones? What will happen to international business markets? How the communities will see each other from now on? What will be the religious standpoint of people around the world? These are maybe some of the hundreds of questions that are going through your mind these days. The answers to these questions cannot be given right now but we can predict the most possible future scenario after researching the presently available information. </p>
                        <p style={{fontWeight:'550'}}>There are many possibilities of the future that solely depend upon the actions the governments and societies are taking right now in response to coronavirus.</p>
                        <p style={{fontWeight:'550'}}>Mankind had faced many disasters in the past and evolved every time. For many of us, the most important geopolitical event that changed the world completely was the terrorist attacks of 9/11, the world was never the same as it was before 9/11. And there are many other incidents in recent history that shaped up the time we are living in. </p>
                        <p style={{fontWeight:'550'}}>However, this is a unique situation that has never occurred before but hopefully, we will use this crisis to rebuild the structure it has damaged. But there is also a chance that we may slide into something worse. </p>
                        <p style={{fontWeight:'550'}}>Not just some scientists or world leaders every human being must understand the situation and act accordingly because it is going to shape our future.</p>
                        <p style={{fontWeight:'550'}}>We cannot talk about every aspect here and its expected result, so there are some thoughts that will help one to understand the possibilities. </p>
                        <p style={{fontWeight:'550'}}>Some German researchers are already started research on life after coronavirus and they are predicting that life will not be the same as it was. Researchers are trying to imagine the world after this pandemic, asking themselves questions whether how much change is going to happen. </p>
                        <p style={{fontWeight:'550'}}>"We will wonder at the fact that social sacrifices that we had to make seldom led to loneliness," </p>
                        <p style={{fontWeight:'550'}}>said Matthias Horx, founder of the Zukunftsinstitut (Future Institute) in Frankfurt.</p>
                        <p style={{fontWeight:'550'}}>"Paradoxically, the physical distance forced by this virus has produced a new proximity," he said in an interview. "We have got to know people whom we otherwise would not have got to know. We have got in touch with old friends more frequently, strengthened connections that became distant and slack."</p>
                        <p style={{fontWeight:'550'}}>Social psychologist Ulrich Wagner believes that there will be different kinds of greetings in the future however he didn’t know how long this trend would last. </p>
                        <p style={{fontWeight:'550'}}>"The chances of there being fundamental behavioural changes - such as for example a boosted use of home office, a slowing down or greater contemplation instead of constant accelerated progress - will depend greatly on how and whether we change our consumption and our consumption expectations - and whether the economy rebounds after the crisis," Wagner said.</p>
                        <h4 style={{fontWeight:'550'}}>Economic Perspective </h4>
                        <p style={{fontWeight:'550'}}>Affecting the world’s modern economy (global trade, wages, productivity). From an economic perspective, there can be four possible futures:</p>
                        <ul>
                            <li>•	Fall of Barbarism </li>
                            <li>•	Vigorous state Capitalism </li>
                            <li>•	Or a transformation of a new global society built on mutual aid. </li>
                        </ul>    
                        <p style={{fontWeight:'550'}}>Every time the world has seen a change there was a new world order that initially was difficult to accept but as time passed it became normal with everyone’s life, just like the present, we are living in was not considered normal two or three decades back. Now the world is adopting a change that may not feel normal but it will become normal as it’s a part of evolution. </p>                    
                    </div>
                </div>
           
                  
                        </div>

                    </div>
                </div>
            </div>
           {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default BlogDetailPage1