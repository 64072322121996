import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'

  import ReCAPTCHA from "react-google-recaptcha";
  import { Search, Grid, Header, Segment ,Label,List,Icon,Input} from 'semantic-ui-react'





  const recaptchaRef = React.createRef();
class LawPortalsAreaDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
    } 
 
  }

  /* visitor */
    render() {

     /*   console.log(this.state.exp,"this.state.exp") */
        return (
          <div>
        <div className="ipad-customer-menu-items">
        <div className="container">
                <div className="row">
                 
         
           <div className="col-lg-4 col-md-6 col-12">
 
           <div className="officail-links-portals">
           <a href="https://www.adjd.gov.ae/EN/Pages/Home.aspx " style={{color:'white'}} target="_blank">
          <Icon name="law" /> UAE Judicial Department
          </a>
         </div>
             </div>
             <div className="col-lg-4 col-md-6 col-12">

<div className="officail-links-portals" >
  <a href="https://u.ae/en#/" style={{color:'white'}} target="_blank">
<Icon name="list alternate outline" />  	UAE Portal
</a>
</div>
 </div>
            <div className="col-lg-4 col-md-6 col-12">
 
            <div className="officail-links-portals" >
            <a href="https://www.dc.gov.ae/PublicServices/DynamicCategoryLinksPage.aspx?SiteCategoryId=8&lang=en" style={{color:'white'}} target="_blank">
          <Icon name="law" /> 	Dubai Courts Services
          </a>
         </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.dxbpp.gov.ae/default.aspx?src=301&id=909&Lang=en#:~:text=Report%20(by%20number)-,Public%20Prosecution%20was%20established%20to%20achieve%20judicial%20justice%20with%20respect,public%20right%20of%20the%20Emirate.&text=Court%20Sessions'%20Schedule-,Public%20Prosecution%20was%20established%20to%20achieve%20judicial%20justice%20with%20respect,public%20right%20of%20the%20Emirate" style={{color:'white'}} target="_blank">
<Icon name="list alternate outline" /> 	Govt. of Dubai Public Prosecution
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.moj.gov.ae/en/home.aspx" style={{color:'white'}} target="_blank">
<Icon name="audible" /> 	UAE Ministry of Justice
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.moj.gov.ae/en/services.aspx " style={{color:'white'}} target="_blank">
<Icon name="blackberry" /> 	UAE Ministry of Justice E-Services
</a>
</div>
   </div>
  
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.adjd.gov.ae/sites/eServices/EN/Pages/CaseInquiry.aspx" style={{color:'white'}} target="_blank">
<Icon name="dribbble square" /> 	UAE Judicial Department Case Inquiry
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.adjd.gov.ae/en/about-adjd " style={{color:'white'}} target="_blank">
<Icon name="fort awesome" /> 	UAE Judicial Department
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.rak.ae/wps/portal/rak/government-entities/courts-department/RAK+Courts+Department" style={{color:'white'}} target="_blank">
<Icon name="fort awesome alternate" /> 	Govt Ras Al Khaimah Courts Dept
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.rak.ae/wps/portal/rak/e-services/govt/public-prosecution-department/inquiry-status-services/case-inquiry-public-prosecution-guide" style={{color:'white'}} target="_blank">
<Icon name="firstdraft" /> 	Govt. Ras Al Khaimah Case Inquiry	
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.adjd.gov.ae/en/pages/eservicedirectory.aspx" style={{color:'white'}} target="_blank">
<Icon name="fort awesome" /> 	UAE Judicial Dept – Digital Services	
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://pp.gov.ae/webcenter/faces/oracle/webcenter/page/scopedMD/s9f590bbd_6f05_44aa_94b6_43b7f80537b6/Page94.jspx?_adf.ctrl-state=veje1uxsr_5&wc.contextURL=%2Fspaces%2Fpp&_afrLoop=3633339891954294#!" style={{color:'white'}} target="_blank">
<Icon name="phoenix framework" /> 		Public Prosecution Judiciary System
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://es.adpolice.gov.ae/trafficservices/registration/Register.aspx?Culture=en" style={{color:'white'}} target="_blank">
<Icon name="slideshare" /> Abu Dhabi Police e-services	
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/financialcircularpayment?firstView=true" style={{color:'white'}} target="_blank">
<Icon name="pied piper alternate" /> 		Dubai Police Criminal/Financial Cases
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/cybercrimeService?firstView=true" style={{color:'white'}} target="_blank">
<Icon name="leanpub" /> 	Dubai Police e-Crime	
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.dubaipolice.gov.ae/wps/portal/home/home" style={{color:'white'}} target="_blank">
<Icon name="newspaper outline" /> 		Dubai Police Services
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.dc.gov.ae/PublicServices/InquiryByCaseNumber.aspx?lang=en" style={{color:'white'}} target="_blank">
<Icon name="balance" /> 		Dubai Courts Case Inquiry
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.ajmanpolice.gov.ae/portal/" style={{color:'white'}} target="_blank">
<Icon name="newspaper outline" /> 		Ajman Police
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://pp.gov.ae/webcenter/portal/oracle/webcenter/page/scopedMD/s9f590bbd_6f05_44aa_94b6_43b7f80537b6/Page94.jspx?wc.contextURL=%2Fspaces%2Fpp&_afrLoop=3634972742454964&_adf.ctrl-state=veje1uxsr_204&leftWidth=0%25&showNav=true&showFooter=false&showHeader=false&rightWidth=0%25&centerWidth=100%25#!%40%40%3FshowNav%3Dtrue%26showFooter%3Dfalse%26_afrLoop%3D3634972742454964%26leftWidth%3D0%2525%26showHeader%3Dfalse%26wc.contextURL%3D%252Fspaces%252Fpp%26rightWidth%3D0%2525%26centerWidth%3D100%2525%26_adf.ctrl-state%3Dveje1uxsr_208" style={{color:'white'}} target="_blank">
<Icon name="compass outline" /> 		Police Prosecution Judiciary System
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.shjpolice.gov.ae/" style={{color:'white'}} target="_blank">
<Icon name="share square outline" /> 		Sharjah Police
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.rakpolice.gov.ae/?AspxAutoDetectCookieSupport=1 " style={{color:'white'}} target="_blank">
<Icon name="shield alternate" /> 		Ras Al Khaimah Police
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://fs.fujairahpolice.gov.ae/Default.aspx" style={{color:'white'}} target="_blank">
<Icon name="chess board" /> 		Fujairah Police
</a>
</div>
   </div>
   <div className="col-lg-4 col-md-6 col-12">
 
 <div className="officail-links-portals" >
 <a href="https://www.adpolice.gov.ae/en/Pages/home.aspx" style={{color:'white'}} target="_blank">
<Icon name="globe" /> 		Abu Dhabi Police
</a>
</div>
   </div>
          
          </div>

          </div>          
        
      
       {/*   <div className="officail-links-portals" onClick={()=> message.success(`this feature is coming soon`)}>
          <Icon name="arrow alternate circle right outline" />    Office Boy Services
         </div> */}
      
        
        </div>
          </div>
        )
    }
}

export default LawPortalsAreaDetail