import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import RecentPosts from "../../../components/RecentPosts";
import Tags from "../../../components/Tags";
import Instagram from "../../../components/Instagram";
import Twitter from "../../../components/twitter";
// images
import breadcumb from "../../../images/breadcumb/news-2.jpg";
import blog5 from "../../../images/blog-details/married-in-Dubai.jpg";
import {Helmet} from "react-helmet";

import "./style.scss";

const GetMarried = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Legal Emirates </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/blogs/dubai-marriage "
        />
        <meta
          name="description"
          content="If you are looking for visual consultations for your corporate or commercial issues, take live counseling here at Legal Emirates. "
        />
      </Helmet>

      <header className="headerArea">
        {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
        <HeaderBotton className="headerBottomArea" />
      </header>
      <div
        className="blogPostArea blogPostLeftArea"
        style={{ paddingTop: "45px" }}
      >
        <center>
          <h1 style={{ fontWeight: "550" }}> How to get married in Dubai?</h1>
          <p
            style={{ fontWeight: "550", fontSize: "18px", marginBottom: "0px" }}
          >
            {" "}
            Law practiced in the United Arab Emirates.
          </p>
          <p style={{ fontWeight: "550" }}>25 Sep 2020/Dubai</p>
        </center>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="blogPostWrapper1">
                <div className="blogPostImg">
                  <img src={blog5} alt="" />
                </div>
                <div className="blogPostContent" style={{ marginTop: "30px" }}>
                  <p style={{ fontWeight: "550" }}>
                    Marriage is the only legal way to live together for two
                    people persons of the opposite gender unless they are
                    relatives by blood or family members. Marriage is considered
                    to be a legal bond between a man and a woman and it has
                    respect among all the religions around the world. People
                    establish a relationship through marriage, have children,
                    and carry their names.
                  </p>

                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Unmarried man and woman cannot live together
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    In the UAE, it is wholly illegal the concept of living
                    together of opposite gender persons without having any
                    relation, either they must have spouses or family members by
                    blood. This rule is only for those relationships which are
                    not your blood relatives. If anyone caught sharing a place
                    and living together without having a valid relationship
                    among them, they will be sent to jail and if they are
                    foreigners living here, they will be deported. So, people
                    have to be very careful while living together with others,
                    who are not relatives.
                  </p>

                  <h4 style={{ fontWeight: "550" }}>
                    {" "}
                    Marriage- A Legal Contract
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    For getting married in Dubai, people must aware of some
                    important things. They must register their marriage in Dubai
                    so that their marriage shall be considered as legal. Many go
                    to court marriage in Dubai to get married. There is no
                    negative in committing court marriages but there are certain
                    requirements that should be fulfilled while doing so.
                  </p>
                  <p style={{ fontWeight: "550" }}>
                    For Muslims, they can get married in a court or by taking
                    services of a religious man at their homes. And for
                    non-Muslims, they can get married according to their own
                    rules and rituals.
                  </p>

                  <h4 style={{ fontWeight: "550" }}> Marriage in Dubai</h4>
                  <p style={{ fontWeight: "550" }}>
                    Some conditions need to be fulfilled in order to get married
                    following Shariah Law.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>Register Electronically</h4>
                  <p style={{ fontWeight: "550" }}>
                    Before doing any process of marriage and going to courts,
                    people must valid registration through electronic means. A
                    website for getting married in Dubai will help in this
                    regard. After fulfilling all the requirements, you will be
                    provided an ID which is required in the courts while getting
                    married.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>Employment Visa</h4>

                  <p style={{ fontWeight: "550" }}>
                    In order to get married in Dubai, one of the two persons
                    must have an employment or residence visa.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>
                    Premarital Medical Certificate{" "}
                  </h4>
                  <p style={{ fontWeight: "550" }}>
                    To check if any of the two persons is suffering from any
                    disease which can pass through others, the medical report is
                    needed. This is done to check some of the diseases in this
                    regard like thalassemia or HIV/AIDS etc.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>A Blood Relation </h4>
                  <p style={{ fontWeight: "550" }}>
                    Father of the bride along with two witnesses must present at
                    the time of the wedding. In case her father is not alive any
                    other male guardian can attend at his place, but the death
                    certificate is required.
                  </p>
                  <h4 style={{ fontWeight: "550" }}>Marriage Fee</h4>
                  <p style={{ fontWeight: "550" }}>
                    The fee structure of marriage in Dubai varies from one place
                    to another. You can also consult from marriage lawyers in
                    Dubai.
                  </p>
                  <p style={{ fontWeight: "550" }}>
                    You can take our services in this regard; we have expert
                    attorneys who consult the issues one faces getting married,
                    including all type of family matters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default GetMarried;
/* GetMarried  */

/* GetMarried */
