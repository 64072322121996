import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const FinancialContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Dispute & financial claims</h3>
        <p>
          UAE criminal law requires special knowledge because these law terms in
          UAE are derived from Islamic Shariah Laws. Our team of attorneys has a
          long history in dealing with criminal cases, we handle diverse
          litigation matters, we have represented clients before foreign state
          panels like Interpol and other international crime control agencies,
          in unfolding complex cases that are international in scope.{" "}
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default FinancialContent;
