import axios from "axios";
import setAuthToken from '../../setAuthToken';
import jwt_decode from 'jwt-decode';
import history from '../../../../history';
//GET ALL CARCATAGORY BY COUNTRY
export const CUTOMER_SIGN_IN_AUTH = "CUTOMER_SIGN_IN_AUTH";
export const CUTOMER_SIGN_IN_AUTH_LOADING =
"CUTOMER_SIGN_IN_AUTH_LOADING";
export const CUTOMER_SIGN_IN_AUTH_LOADING_FAILED =
"CUTOMER_SIGN_IN_AUTH_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const customerSignInAuthLoading = ms => ({
    type: CUTOMER_SIGN_IN_AUTH_LOADING,
    payload: ms
    });
    
    export const customerSignInAuthLoadingFailed = ms => ({
    type: CUTOMER_SIGN_IN_AUTH_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function customerSignInAuth(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/users/auth/signIn`,
    obj
    );
    
    return dispatch => {
    dispatch(customerSignInAuthLoading());
    request
    .then(response => {
       
    
    
        
    if (response.status === 200) {
        console.log(response)
        if(response.data.user.emailVerified === true){
            let  token  = response.data.user.token;
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('user',response.data.user.firstName);
            localStorage.setItem('loginFlag','login')
            localStorage.setItem('name',response.data.user.firstName+" "+response.data.user.lastName);
            localStorage.setItem('email',response.data.user.email);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(response.data.user.firstName));
            dispatch({
                type: CUTOMER_SIGN_IN_AUTH,
                payload: response.data
                });
        }
   
     /*    dispatch(setCurrentUser(decoded));  */   
    dispatch({
    type: CUTOMER_SIGN_IN_AUTH,
    payload: response.data
    });

   
    }
    else if (response.data.response.status === 404){
        dispatch({
            type: CUTOMER_SIGN_IN_AUTH,
            payload: response.data
            });
    }
     else {
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
    if (error.response) {
    if (error.response.status === 401 || error.response.status === 404 || error.response.status === 402 || error.response.status === 403 || error.response.status === 400) {
    dispatch(
        customerSignInAuthLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(customerSignInAuthLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(customerSignInAuthLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(customerSignInAuthLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


    export const setCurrentUser = decoded => {
        return {
            type: 'SET_CURRENT_USER',
            payload: decoded
        }
      }
      export const logoutUser = () => dispatch => {
     
        localStorage.removeItem('jwtToken');
        setAuthToken(false);
        dispatch(setCurrentUser(undefined));
       window.location.reload(history.push('/signin'));
       
       
        
      
      }