import React, { Component } from "react";
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Form,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import './style.css'
  import Joi from 'joi-browser';
  import { toast } from 'react-toastify';
class LogoArea extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }
  
  render() {
   
    return (
      <div className="logoArea">
          <Container>
              <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <img src={require('../../images/logo/logo.png')} style={{width:'100%'}} />
                  </Col>
              </Row>
          </Container>
       
      </div>
    );
  }
}
export default LogoArea;
