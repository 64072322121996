import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const MultinationalContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Multinational Companies Disputes </h3>
        <p>
          Many international companies are attracted to the UAE to grow due to
          the politically stable and economically bright region. The
          effectiveness of dispute resolution services is now an important
          portion
        </p>
        <p>
          The courts of the UAE are well aware and expert in dealing with cases
          related to foreign or local parties and they are relatively fast in
          considering evidence and reaching the final decisions. So, the parties
          should be aware of using this fact to their advantage. We as a law
          firm have quite an experience in representing arbitration matters in
          the courts here.{" "}
        </p>
        <p>
          The Law Firm is one of the region’s leading law firms and has a
          well-known experience in dealing with the disputes between national
          and multi-national companies in the UAE. We regularly conduct
          arbitration matters under the light of the UAE laws. Members of our
          team also guide international and local companies’ officials on how to
          solve a dispute between two or more companies.{" "}
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default MultinationalContent;
