import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const cetagoryMenu = [
  { name: "Criminal Law ", route: "speciality-criminal" },
  { name: "Business and Corporate Cases", route: "speciality-business" },
  { name: "International/Interpol Cases ", route: "speciality-interpol" },
  { name: "Labor Law", route: "speciality-labor" },
  { name: "Dispute & Financial Claims", route: "speciality-financial" },
  { name: "Family Law", route: "speciality-family" },
  { name: "Arbitration Law", route: "speciality-arbitration" },
  {
    name: "Construction and Infrastructure ",
    route: "speciality-construction",
  },
  { name: "Real Estate Law", route: "speciality-real-state" },
  {
    name: "Corporate & Mergers and Acquisitions  ",
    route: "speciality-merger",
  },
  { name: "Intellectual Property", route: "speciality-poverty" },
  { name: "Commercial Law ", route: "speciality-commercial" },
  { name: "Capital Markets ", route: "speciality-market" },
  { name: "Insurance Cases  ", route: "speciality-insurance" },
  { name: "Family Business Cases   ", route: "speciality-family-business" },
  { name: "Tax Law ", route: "speciality-tax" },
  { name: "Private Client and Notary Services  ", route: "speciality-notary" },
  { name: "Establishing Businesses ", route: "speciality-establish-business" },
  { name: "Anti-Money Laundering  ", route: "speciality-money-laundering" },
  { name: "Drug Law ", route: "speciality-drug" },
  {
    name: "	Multinational Companies Disputes ",
    route: "speciality-multinational-company",
  },
];

function CetagorySidebar({ className, title }) {
  const [angle, setAngle] = React.useState("down");
  const [index, setIndex] = React.useState(6);
  function handleClick() {
    if (angle === "up") {
      setAngle("down");
      setIndex(6);
    } else {
      setAngle("up");
      setIndex(cetagoryMenu.length);
    }
  }
  return (
    <div className={className}>
      <h3 className="sidebarTitle">{title}</h3>
      <ul>
        {cetagoryMenu.map((cetagory, i) => {
          if (i < index) {
            return (
              <li key={i}>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 900);
                  }}
                  to={cetagory.route} style={{color:window.location.href.split('/')[window.location.href.split('/').length-1]===cetagory.route.split("/")[cetagory.route.split("/").length-1]?"#c0b596":""}}
                >
                  {cetagory.name}
                </Link>
              </li>
            );
          }
        })}
        <li className="side-bar-collapase">
          <i onClick={handleClick} class={`fa fa-angle-${angle}`}></i>
        </li>
      </ul>
    </div>
  );
}
export default CetagorySidebar;
