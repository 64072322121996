import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const MergerContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>	Corporate & Mergers and Acquisitions   </h3>
                <p>Law Firm has a history of solving high-value Mergers and Acquisition deals in UAE. We got special skills in dealing with all types of transactions for both regional and international corporations. We cover regional and foreign mergers, demergers, acquisitions, and disposals. </p>
                <p>We have a full serviced corporate department always ready to guide on all the areas of business transactions including, incorporations and formations, corporate restructuring, franchising, and banking and finance. </p>
                <p>Covering all the aspects of M&A practice we offer:</p>
        
           <ul style={{color:'#666666'}}>
          <li>•	Preparing acquisition agreements </li>
          <li>•	Preparing shareholders agreements </li>
          <li>•	Joint venture agreements </li>
          <li>•	Advising on end deal matters </li>
          <li>•	Advising on UAE regulatory issues </li>
           </ul>
               {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default MergerContent