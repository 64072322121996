import React, { Component } from 'react'
import Joi from 'joi-browser'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../Form/style.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import * as Actions from "./store/actions";
  import { compose } from "redux";
  import { connect } from "react-redux";
  import reducer from "./store/reducers";
  import withReducer from "../../store/withReducer";
  import store from "../../store";
  import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class FormForgotPass extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show:false,
        email: '',
        error: {},
        passFlag:false,
        sendFlag:true
    } 
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    /* customerEmailSendPassReducer 
    sendEmailPassword
    */
   store.subscribe(() => {
    let resNumber1 = store.getState().FormForgotPass1.customerEmailSendPassReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null) {
          this.setState({show:true})
     console.log(resNumber1,'send email responce')
      } else {
          if(this.state.sendFlag){
            toast.error('Something went Wrong Please Enter the Correct Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                this.setState({sendFlag:false})
          }
      
      }
    }
  });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'email mast be A Valid Email';
                        break;
                    default:
                        err.message = 'email can not be empity';
                        break;
                }
            });
            return errors;
        }),
     
        address: Joi.string(),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            email: this.state.email,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {

            this.props.dispatch(Actions.sendEmailPassword({email:this.state.email}))
           setTimeout(()=>{
            this.setState({
                sendFlag:true,
                email: '',
            })
           },500)
           
           
        }
    }

    render() {

       
        return (
            <form onSubmit={this.submitHandler} className='contactForm'>
                <div className="row">
                    
                    <div className="col-12">
                        <div className="formInput form-control field-design">
                            <img src={require('../../images/signIn/03-01.png')} />
                            <input
                                placeholder="Email"
                                value={this.state.email}
                                name="email"
                                onChange={this.changeHandler}
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type="email" />

                            {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.email}</p>}
                        </div>
                    </div>
                  
                     
                   
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area">
                        <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/coming-soon'>  Terms & Conditions</NavLink></p>    
                        <button type="submit">Submit</button>
                        </div>
                       
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}  size="md" centered  >
       
       <Modal.Body style={{padding:'0px'}}>
         <div className="signup-verify-email-popup">
         <div className="signup-verify-email-popup-top-area">
             <center>
             <img src={require('../../images/signIn/emailverify.png')} />
             </center>
          </div>    
          <div className="signup-verify-email-popup-bottom-area">
      <center>
      <h3>Congratulations!</h3>  
           <p>You have successfully sent the request for password reset. Kindly check your Email ID to update your new password.</p>
      </center>
           <hr />
<center>   <button className="button-verify-email-address" style={{color:'black'}} onClick={()=>this.props.history.push('signin')} >OK</button>
       </center>
           </div>

         </div>
        
       </Modal.Body>
   
     </Modal> 
            </form>
        )
    }
}

export default compose(
  withReducer("FormForgotPass1", reducer),
  connect(),withRouter
)(FormForgotPass);  