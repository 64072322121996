import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../Form/style.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  
  import * as Actions from "./store/actions";
  import { compose } from "redux";
  import { connect } from "react-redux";
  import reducer from "./store/reducers";
  import withReducer from "../../store/withReducer";
  import store from "../../store";
  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class FormResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        email: '',
        password:'',
        password1:'',
        error: {},
        type:'password',
        type1:'password',
        passFlag:false,
        passFlag1:false,
        captcha:null,
        buttonFlag:true,
        passSubmitFlag:false,
        passFlag11:false,
        expired:false,
        errorMassage15:'The Link Has Expired',
        forgotFlag:false,
        forgotFlag66:false,
        show:false
    } 
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    let token=this.props.match.params.token
    localStorage.setItem('resetToken',token)
    this.props.dispatch(Actions.verifyCutomerToken({resetToken:token}))
    /*  changePassword
    ChangePasswordReducer
     */
    store.subscribe(() => {
        let resNumber1 = store.getState().FormResetPassword1.customerTekonVerificationReducer;
    
        if (!resNumber1.isLoading) {
          if (resNumber1.errMsg == null) {
          
          } else {
           this.setState({errorMassage15:resNumber1.errMsg,expired:true})
        
            console.log(resNumber1.errMsg);
          }
        }

/* change password */
let resNumber2 = store.getState().FormResetPassword1.ChangePasswordReducer;
    
if (!resNumber2.isLoading) {
  if (resNumber2.errMsg == null) {
    console.log(resNumber2, "resNumber2 resNumber2");
    if(this.state.forgotFlag66){
       /*  this.props.history.push('/signin') */
        this.setState({forgotFlag66:false,show:true})
      
      }
  
  } else {
      if(this.state.forgotFlag){
        setTimeout(()=>{this.props.history.push('/forgot-password')},1000)
        this.setState({forgotFlag:false})
        toast.error('Something went Wrong Please Try again later', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
     
    console.log(resNumber1.errMsg);
   
      console.log(resNumber1.errMsg);
  }
}

      });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
    schema = {
      
     
        password:Joi.string().min(7).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'password  [7 minimum character] ';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            password:this.state.password,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
            if(this.state.passFlag11){
        let obj={
            resetToken:localStorage.resetToken,
            newPassword:this.state.password
        }
        console.log(obj,"onggggggggggg")
        this.props.dispatch(Actions.changePassword(obj))
            this.setState({
                email: '',
                forgotFlag:true,
                forgotFlag66:true,
                password:'',
                pass2:''
            })
        }else{
            this.setState({passSubmitFlag:true})   
           }
         
           
        }
    }
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }
    handleHidePass1=()=>{
        this.setState({passFlag1:true,type1:'text'})
            }
            handleShowPass1=()=>{
                this.setState({passFlag1:false,type1:'password'})
            }
    handleConfirmPass=(e)=>{
        let pass2=e.target.value;
        this.setState({password1:pass2})
        if((pass2.match(this.state.password)) && ((pass2.toString()).length===(this.state.password.toString()).length)){
            this.setState({passFlag11:true})
        
        }
        else{
          this.setState({passFlag11:false,passSubmitFlag:true})
        }

    }
    onChangeCaptha=(value)=>{
        if(value===null){
          this.setState({captcha:null,buttonFlag:true})
        }
        else{
          this.setState({captcha:value,buttonFlag:false})
        }
      }
    render() {

       
        return (
          <div>
                {this.state.expired? 
                <div className="password-link-expiration-page">
                 <center>
                 <img style={{width:'150px',marginTop:'30px'}}  src={require('../../images/logo/logo2.png')} />
                <h1>{this.state.errorMassage15}</h1>
                 <p>Please try reset your password again.</p>
                 <img style={{width:'150px',marginTop:'30px',padding:'10px',borderRadius:'50%',border:'1px solid white'}}  src={require('../../images/signIn/sdasd.png')} /><br />
               <button className="back-to-forgot-reset"><Link style={{color:'#D6BB61'}} to='forgotpassword'>Back to reset Password</Link></button>
                 </center>
                </div>
                :
            <form  onSubmit={this.submitHandler} className='contactForm form-reset-password'>
                   <h2 style={{color:'white',fontSize:'26px',marginTop:'15px',marginBottom:'30px'}}><img style={{width:'40px',marginRight:'15px'}} src={require('../../images/signIn/01-01.png')} />Create new Password</h2>
                     
                <div className="row">
                    
                <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                                placeholder="********"
                                value={this.state.password}
                                name="password"
                                onChange={this.changeHandler}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type} />
                             {this.state.passFlag ?   <img className="password-image2" onClick={this.handleShowPass} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                           <div style={{marginTop:'8px'}} className={this.state.error.password ?"password-area" :"password-area1" }>
                            {this.state.error.password && <p style={{fontWeight:'550'}}>{this.state.error.password}</p>}
                             </div>    
                      {/* forgotpassword */}
                        </div>
                    
                     </div> 
                  
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Confirm Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                                placeholder="********"
                                value={this.state.password1}
                                name="password1"
                                onChange={this.handleConfirmPass}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type1} />
                             {this.state.passFlag1 ?   <img className="password-image2" onClick={this.handleShowPass1} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass1} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                          
                           {this.state.passSubmitFlag ? (
                this.state.passFlag11 ? (
               ""
                ) : (
                    <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    Password do not match 
                  </p>
                )
              ) : (
                ""
              )}        
                      {/* forgotpassword */}
                        </div>
                      
                     </div>   
                     <div className="col-12" style={{marginTop:'10px',marginBottom:'20px'}}>
                    <center>
                    <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                    </center>
                     </div>
                   
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area">
                      {/*   <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/terms-and-conditions'>  Terms & Conditions</NavLink></p>    
                       */}  <button  disabled={this.state.buttonFlag} style={{cursor:'pointer',marginTop:'5px',borderRadius:'12px',width:'100%'}} type="submit">Reset </button>
                       </div>
                       
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}  size="md" centered  >
       
          <Modal.Body style={{padding:'0px'}}>
            <div className="signup-verify-email-popup">
            <div className="signup-verify-email-popup-top-area">
                <center>
                <img src={require('../../images/signIn/emailverify.png')} />
                </center>
             </div>    
             <div className="signup-verify-email-popup-bottom-area">
         <center>
         <h3>Congratulations!</h3>  
              <p>Your Password is Successfully updated!</p>
         </center>
              <hr />
<center>   <button className="button-verify-email-address" style={{color:'black'}} onClick={()=>this.props.history.push('/signin')} >Sign In</button>
          </center>
              </div>

            </div>
           
          </Modal.Body>
      
        </Modal>           
            </form>
        }
            
          </div>
        )
    }
}
 
export default compose(
  withReducer("FormResetPassword1", reducer),
  connect(),withRouter
)(FormResetPassword);  