import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const ArbitrationContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>	Arbitration Law   </h3>
              <p>The Law Firm is one of the region’s leading law firm and has a well-known experience in dealing with cases which require Arbitration. We regularly conduct arbitrations under the laws of the UAE. Members of our team also guide our clients in investment arbitration matters of all kinds of treaties and advise in the light of complex issues of public international law. The team has a bundle of experience handling a wide range of areas in arbitration cases. </p>
              <p>Client’s review after taking services from us </p>
              <p>“Here at the Law Firm, I was treated way better than I could ever imagine from a law firm. They were so pleasing and helpful. They listened to my opinions first and guided me accordingly in my arbitration process. Attorneys here went above and beyond and did everything they could to ensure a peaceful outcome. I will recommend this Law firm”</p>
               {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default ArbitrationContent