import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import { Button } from 'semantic-ui-react'
import './style.css';
import { Icon } from 'semantic-ui-react'
const PageNotFoundArea = ({ className }) => {
    return (
        <div className="PageNotFoundArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-12">
                    
                    </div>
                    <div className="col-12 col-lg-6">
               <center>
                         <img src={require('../../images/logo/logo2.png')} style={{width:'120px',marginBottom:'20px'}} />
                         <h1 style={{color:'white',borderBottom:'3px solid lightgrey',maxWidth:'15ch'}}>Page Not Found</h1>
                         <img src={require('../../images/404.png')} style={{maxWidth:'300px',marginBottom:'20px'}} />
                       <h2>Sorry, we could not discover that page you are looking for</h2>
                       <p>We have removed a few things around, and your page must have gotten lost. Try finding the address again or just get back to our home page.</p>
                       <Link to='/'> <Button color="white"> Go to Home Page   <Icon  style={{marginLeft:'5px'}} loading name='spinner' /></Button> </Link>  
                       </center>
               
                    </div>
                    <div className="col-lg-3 col-12">
                    
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default PageNotFoundArea