import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const EstablishBusinessContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Establishing Businesses </h3>
        <p>
          The UAE attracts thousands of entrepreneurs from every part of the
          world to come and grow or establish their businesses here due to the
          region’s political stability and growing capacity. But there are
          several rules and regulations for establishing new business here, the
          UAE offers a supportive legal framework for businesses whether they
          are joint partnerships or a free zone buisness.{" "}
        </p>
        <p>
          We have a team of corporate attorneys who have years of experience in
          guiding the entrepreneurs establishing businesses on the UAE soil. Our
          team will help you to fulfill the requirements for getting a different
          business license, commercial license for all kinds of trading
          activities, professional license covering services and professions, or
          industrial license to establish or manufacture an industry.{" "}
        </p>
        <p>
          Apart from giving awareness of business laws, our team will help you:{" "}
        </p>

        <ul style={{ color: "#666666" }}>
          <li>• Understanding and entering the UAE Market </li>
          <li>• Set up permanent presence in the UAE </li>
          <li>• Being your commercial agent </li>
        </ul>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default EstablishBusinessContent;
