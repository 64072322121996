import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col,Button
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";

import { Icon,Table } from "semantic-ui-react";
class CounsellingUser extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      class:"my-first-class-name",
      filesData:["nothing"]
    };
  }
  componentWillMount(){
    this.props.dispatch(Actions.getFileCustomer());
  }
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.getFilesReducer;
    if (!resNumber1.isLoading) {
        if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
           
           if(resNumber1.data.length>0){
        /*     this.setState({filesData:resNumber1.data}) */
          }else{
            this.setState({filesData:["nothing"]})
          }
        } else {
            this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
            }
    } 
}
  componentDidMount(){

  }
  handleColor=()=>{
    this.setState({class:"my-first-class-name"})
  }
  render() {
    return (
      <div >
                          <Table celled fixed padded  selectable  style={{backgroundColor:'transparent'}}
>
    <Table.Header style={{fontSize:'12px',borderBottom:'1px solid white'}}>
    {this.state.filesData.length>0 ?
                  this.state.filesData[0]!="nothing"?
      <Table.Row>
        <Table.HeaderCell 
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
        <Table.HeaderCell
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>File No</Table.HeaderCell>
        <Table.HeaderCell
          style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        <Table.HeaderCell 
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>created At</Table.HeaderCell>
        
        <Table.HeaderCell
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Status</Table.HeaderCell>
     
      </Table.Row>
      :'':''}

      {this.state.filesData.length>0 ?
                  this.state.filesData[0]!="nothing"?
                this.state.filesData.map((item)=>{
                return <Table.Row>
      <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
           {item.fullName}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
              BZ/  {item.fileNo}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       {item.email}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       {item.mobileNumber}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       {item.createdAt ? item.createdAt.slice(0,10):'01-09-2020'}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       {item.fileStatus}
       </Table.Cell>
      </Table.Row>  
      
      
    })
    :<Table.Row>
         <Table.Cell
         style={{backgroundColor:'transparent',color:'white',borderRight:'1px solid white'}}
         colSpan='6'
textAlign='center'>
        <center>
      <div className="loader-for-tabel-invoce" style={{fontWeight:'550',color:'white',fontSize:'28px'}}>
   
      <div className="not-exist-area">
                <div>
                <i className="fi flaticon-search"></i>
                <p style={{fontWeight:'550',color:'white',fontSize:'28px'}}>No Counselling Exist Yet!</p>
                <br />
              
                </div>
              </div>
      </div>
      </center>    
    </Table.Cell>
    </Table.Row>:<Table.Row>
         <Table.Cell 
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
         textAlign='center' colSpan='6'  
textAlign='center'>
         <center>
   <div className="loader-for-tabel-invoce">
   <div className="loader1188">
    </div> 
  
   </div>

   </center>  
    </Table.Cell>
    </Table.Row>}
   
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
        </Table.Body>
        </Table>
      {/*   <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select style={{color:'#353535'}}  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select style={{color:'#353535'}} onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div> */}
      </div>
    );
  }
}
const mapStateToProps=(state)=> {
return {
    getFilesReducer:state.CounsellingUser1888.getFilesReducer
};
} 
export default compose(
withReducer("CounsellingUser1888", reducer),
connect(mapStateToProps)
)(CounsellingUser);
