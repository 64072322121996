import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const FamilyContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Family Law </h3>
        <h4>Divorce</h4>
        <p>
          Divorce proceedings regarded your residence, nationality, domicile,
          etc. Nationality can be an issue in UAE but you can apply for divorce
          whether you are a UAE national or not.{" "}
        </p>
        <h4>Finance on divorce </h4>
        <p>
          Finance maintenance for children during a divorce is of great
          importance, for instance, monthly child financial support, education
          charges, housing, and transportation, etc.
        </p>
        <h4>Child Maintenance </h4>
        <p>
          Child maintenance includes all types of necessary finances that are
          required to raise a child including housing, food, education, medical,
          etc. Following a divorce, the father will be responsible for all the
          expenses of his child.{" "}
        </p>
        <p>
          Child maintenance is not fixed and it is calculated according to the
          father’s income.{" "}
        </p>
        <p>Father will bear all these expenses until his child turns 21. </p>
        <p>
          The court will automatically pass such orders related to child
          maintenance after a divorce.{" "}
        </p>
        <p>
          Other things that are included in family-related legal matters are:
        </p>
        <h5>Cohabitation and unmarried family </h5>
        <h5>Children responsibility and custody</h5>
        <h5>Children international aspect</h5>
        <p>Client’s review after taking services from us </p>
        <p>
          “They got me through a very hard time with all of my divorce and child
          support issues. Thanks to their team I am finally free of all this”
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default FamilyContent;
