import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const MarketContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Capital Market </h3>
        <p>
          With more than 25 years of experience we are now one of the leading
          law firms dealing with local and international capital markets and
          legal matters. Our team of capital market lawyers is trained to
          provide clients the most significant guidance for their matters while
          entering new capital markets and what steps they need to make a
          positive impact while maintaining the rules and regulations of the
          region.{" "}
        </p>
        <p>
          We will guide you on each type of market, for example, primary market,
          secondary market, auction market, dealer market, and debt market.
          Apart from this our services in this category include:
        </p>
        <ul style={{ color: "#666666" }}>
          <li>• Pre-deal structuring</li>
          <li>• Drafting agreements</li>
          <li>• Setting investment policy</li>
          <li>• Securities law advisory </li>
          <li>• Conducting legal due diligence </li>
          <li>• Tax structuring </li>
          <li>• And much more </li>
        </ul>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default MarketContent;
