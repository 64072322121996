import React, { Component } from 'react'
import Joi from 'joi-browser'
import { toast } from 'react-toastify';
import { NavLink, Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PaymentAreaInvoice from './paymentInvoice/index'
import '../Form/style.scss';
import './style.scss'
import SignatureCanvas from 'react-signature-canvas'
import './formStyle.scss'
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import './switch.css'
  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class CustomerFileForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        fname:'',
        Nationality:'',
        Occupation:'',
        Employer:'',
        UAEAddress:'',
        PermanentAddress:'',
        MobileNumber:'',
        HomeTel:'',
        OfficeTel:'',
        VATRegistration:'',
        CompanyBank:'',
        Fax:'',
        email: '',
        description:'',
        case:'',
        LawyerName:'',
        caseNumber:'',
        OtherParty:'',
        Year:'',
        OPLawyer:'',
        Court:'',
        Stagelitgation:'',
        Accusation:'',
        description1:'',
        description2:'',
        AmountClaim:'',
        ProfessionalFees:'',
        InAdvance:'',
        FirstInstance:'',
        Appeal:'',
        CourtFee:'',
        Cassation:'',
        Execution:'',
        ProfessionalFee1:'',
        Max:'',
        OfficialFee55:'',
        Administrativefees:'',
        termsConditions:false,
        termsSubmitFlag:false,
        error: {},
        type:'password',
        passFlag:false,
        date88:'text',
        date99:'text',
        show: false,
        termsFlag:true,
        trimmedDataURL:null,
        signatureFlag:false,
        signatureFlag1:false,
        paymentInvoice:false,
        captcha:null,
        buttonFlag:true,
    } 
   
  }
 
  sigPad = {}
  sigPad1 = {}
  clear = () => {
    this.sigPad.clear()
  }
  clear1 = () => {
    this.sigPad1.clear()
    this.setState({signatureFlag:false,trimmedDataURL:null})
  }
  trim = () => {
      console.log(this.sigPad1.getTrimmedCanvas().toDataURL('image/png'),"ji")
    this.setState({trimmedDataURL: this.sigPad1.getTrimmedCanvas()
      .toDataURL('image/png'),signatureFlag:true}) 
  }
    schema = {
        fname: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Company Name can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        Occupation: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Occupation can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        Employer: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Authorized person name can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        UAEAddress: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'UAE Address can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        PermanentAddress: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Permanent Address can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        MobileNumber: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Mobile Number can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        HomeTel: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Compeny Tel can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        OfficeTel: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Office Tel can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        VATRegistration: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'VAT Registration Number can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        CompanyBank: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Company Bank Name can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        /* CompanyBank */
        Fax: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Fax can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        description:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Summary Of Matter can not be Empty ';
                        break;
                }
            });
            return errors;
        }),
        LawyerName:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Lawyer Name can not be Empty ';
                        break;
                }
            });
            return errors;
        }),
        caseNumber:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Case Number can not be Empty ';
                        break;
                }
            });
            return errors;
        }),
        OtherParty:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Other Party can not be Empty ';
                        break;
                }
            });
            return errors;
        }),
        Year:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Year can not be Empty ';
                        break;
                }
            });
            return errors;
        }),
        OPLawyer:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "OP'S Lawyer can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Court:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Court can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Stagelitgation:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Stage of litgation  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Accusation:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Accusation  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        description1:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Note  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        description2:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "The Advisory Opinion  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        AmountClaim:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Amount of Claim  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        ProfessionalFees:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Professional Fees  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        InAdvance:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "In Advance  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        FirstInstance:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "First Instance  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Appeal:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Appeal  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        CourtFee:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "CourtFee  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Cassation:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Cassation  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Execution:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Execution  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        ProfessionalFee1:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Professional Fee  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Max:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Max Fee  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        OfficialFee55:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Official Fee  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
        Administrativefees:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = "Administrative Fee  can not be Empty ";
                        break;
                }
            });
            return errors;
        }),
       
   
       
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'email mast be A Valid Email';
                        break;
                    default:
                        err.message = 'email can not be Empty';
                        break;
                }
            });
            return errors;
        }),
        Nationality:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Nationality must be select ';
                        break;
                }
            });
            return errors;
        }),
   
        case:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Case Type must be select ';
                        break;
                }
            });
            return errors;
        }),
    
       
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            fname:this.state.fname,
            Nationality:this.state.Nationality,
            Occupation:this.state.Occupation,
            Employer:this.state.Employer,
            UAEAddress:this.state.UAEAddress,
            PermanentAddress:this.state.PermanentAddress,
            MobileNumber:this.state.MobileNumber,
            HomeTel:this.state.HomeTel,
            OfficeTel:this.state.OfficeTel,
            VATRegistration:this.state.VATRegistration,
            CompanyBank:this.state.CompanyBank,
            LawyerName:this.state.LawyerName,
            Fax:this.state.Fax,
            email: this.state.email,
            description:this.state.description,
            caseNumber:this.state.caseNumber,
            OtherParty:this.state.OtherParty,
            Year:this.state.Year,
            OPLawyer:this.state.OPLawyer,
            Court:this.state.Court,
            Stagelitgation:this.state.Stagelitgation,
            Accusation:this.state.Accusation,
            description1:this.state.description1,
            description2:this.state.description2,
            AmountClaim:this.state.AmountClaim,
            ProfessionalFees:this.state.ProfessionalFees,
            InAdvance:this.state.InAdvance,
            FirstInstance:this.state.FirstInstance,
            Appeal:this.state.Appeal,
            CourtFee:this.state.CourtFee,
            Cassation:this.state.Cassation,
            Execution:this.state.Execution,
            ProfessionalFee1:this.state.ProfessionalFee1,
            Max:this.state.Max,
            OfficialFee55:this.state.OfficialFee55,
            Administrativefees:this.state.Administrativefees,

            case:this.state.case,
           
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
        if(this.state.signatureFlag){
            if(this.state.termsFlag){
                this.setState({termsSubmitFlag:true})   
                  /* 
                  termsSubmitFlag
                  termsFlag
                  */
               }else{
                   alert("You Have Successfully Entered the File Information")
                 this.setState({
                     termsSubmitFlag:false,
                     paymentInvoice:true,
                     fname:'',
                     Occupation:'',
                     Employer:'',
                     UAEAddress:'',
                     PermanentAddress:'',
                     MobileNumber:'',
                     HomeTel:'',
                     OfficeTel:'',
                     VATRegistration:'',
                     CompanyBank:'',
                     Fax:'',
                     description:'',
                     email: '',
                     case:'',
                     LawyerName:'',
                     caseNumber:'',
                     OtherParty:'',
                     Year:'',
                     OPLawyer:'',
                     Court:'',
                     Stagelitgation:'',
                     Accusation:'',
                     description1:'',
                     description2:'',
                     AmountClaim:'',
                     ProfessionalFees:'',
                     InAdvance:'',
                     FirstInstance:'',
                     Appeal:'',
                     CourtFee:'',
                     Cassation:'',
                     Execution:'',
                     ProfessionalFee1:'',
                     Max:'',
                     OfficialFee55:'',
                     Administrativefees:'',
     
                     Nationality:'',
                 })
                 window.scrollTo(0,0)
               }
        }else{
         this.setState({signatureFlag1:true})
        }
         
          
         
           
        }
    }
   
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }
    handleCVChange=(e)=>{
        this.changeHandler(e) 
        this.setState({
            cv: e.target.files[0],
          })
          document.getElementById("file-name99").innerHTML = e.target.files[0].name;
      }
      handleDateChange=(e)=>{
        /*   this.changeHandler(e) */
          this.setState({
              cv: e.target.files[0],
            })
            document.getElementById("file-name88").innerHTML = e.target.files[0].name;
        }
        handleConditions=()=>{
            if(this.state.termsFlag){
                this.setState({termsFlag:false})
            }else{
              this.setState({termsFlag:true})
            }
           
          }

          onChangeCaptha=(value)=>{
            if(value===null){
              this.setState({captcha:null,buttonFlag:true})
            }
            else{
              this.setState({captcha:value,buttonFlag:false})
            }
          }
    render() {
const options1=[
    { level: 'Select a Card* ', value: '' },
    { level: 'VISA Card', value: 'VISA Card' },
    { level: 'Master Card', value: 'Master Card' },
]
const optionsLawyer=[
    { level: 'Bader Al Zaabi', value: 'Bader Al Zaabi' },
    { level: 'Bader Al Zaabi', value: 'Bader Al Zaabi' },
    { level: 'Bader Al Zaabi', value: 'Bader Al Zaabi' },
]
const options2=[
    { level: 'Select Our Pricing Plan* ', value: '' },
    { level: 'Basic Plan', value: 'Basic Plan' },
    { level: 'Silver', value: 'Silver' },
    { level: 'Gold', value: 'Gold' }
]
  
        const options = [
            { level: 'Select a Case* ', value: 'Case Type' },
            { level: 'Criminal Cases', value: 'Criminal Cases' },
            { level: 'Corporate Law', value: 'Corporate Law' },
            { level: 'Arbitration', value: 'Arbitration' },
            { level: 'International/ Interpol Cases', value: 'International/ Interpol Cases' },
            { level: 'Financial Crime', value: 'Financial Crime' },
            { level: 'Family business', value: 'Family business' },
            { level: 'Commercial', value: 'Commercial' },
            { level: 'Banking', value: 'Banking' },
            { level: 'Construction & Infrastructure', value: 'Construction & Infrastructure' },
            { level: 'Competition', value: 'Competition' },
            { level: 'Data Privacy & Cybersecurity', value: 'Data Privacy & Cybersecurity' },
            { level: 'Intellectual Property', value: 'Intellectual Property' },
            { level: 'Merger & Acquisitions', value: 'Merger & Acquisitions' },
            { level: 'Private Notary', value: 'Private Notary' },
            { level: 'Insurance', value: 'Insurance' },
            { level: 'Capital Market', value: 'Capital Market' },
            { level: 'Private Client Services', value: 'Private Client Services' },
            { level: 'Family Law', value: 'Family Law' },
            { level: 'Business Law', value: 'Business Law' },
            { level: 'Real Estate Law', value: 'Real Estate Law' },
            { level: 'Injury Law', value: 'Injury Law' },
            { level: 'Educational Law', value: 'Educational Law' },
            { level: 'VAT Law', value: 'VAT Law' },
            { level: 'Tax Law Advisory', value: 'Tax Law Advisory' },
            { level: 'International Tax Law', value: 'International Tax Law' },
            { level: 'Technology/Cyber Law', value: 'Technology/Cyber Law' },
            { level: 'Chemical', value: 'Chemical' },
            { level: 'Aviation', value: 'Aviation' },
            { level: 'Consumer Product', value: 'Consumer Product' },
            { level: 'Financial institution', value: 'Financial institution' },
            { level: 'Manufacture and Industrial', value: 'Manufacture and Industrial' },
            { level: 'Maritime & Shipping', value: 'Maritime & Shipping' },
            { level: 'Media', value: 'Media' },
            { level: 'Mining and Metals', value: 'Mining and Metals' },
            { level: 'Oil and Gas', value: 'Oil and Gas' },
            { level: 'Pharmaceuticals and Healthcare', value: 'Pharmaceuticals and Healthcare' },
            { level: 'Power', value: 'Power' },
            { level: 'Private Equity', value: 'Private Equity' },
            { level: 'Sovereigns', value: 'Sovereigns' },
            { level: 'Telecommunication', value: 'Telecommunication' },
            { level: 'Space Laws', value: 'Space Laws' },
            { level: 'Establishing Business', value: 'Establishing Business' },
            { level: 'Anti-Money Laundering', value: 'Anti-Money Laundering' },
            { level: 'Drug Law', value: 'Drug Law' },
            { level: 'Multinational Disputes', value: 'Multinational Disputes' },
            { level: 'Labor Law', value: 'Labor Law' }

        ]
       
        return (
            <div>
              {this.state.paymentInvoice ? <PaymentAreaInvoice/> :
                 
            <form onSubmit={this.submitHandler} className='counsellingForm'>
                 <div className="caseFiletopAreaHeader">
                 <h1>Corporate File</h1>
                 <img src={require('../../images/logo/logo55.png')} />
                </div>
                <div className="caseFiletopAreaHeaderb">
            <p>  <p>Date  <span className="date-label-deisgin-box">{((new Date().getDate())+1) > 9? "" :0 }{(new Date().getDate())}</span> <span className="date-label-deisgin-box">{((new Date().getMonth())+1) > 9? "" :0 }{(new Date().getMonth())+1}</span> <span className="date-label-deisgin-box">{(new Date().getFullYear())}</span> </p>
              </p>
              <div className="file-no-area">
                <p>File no. BZ/0001</p>
                </div>
                    </div>
                  <hr />  
                <div className="client-area-design">
                <h3 className="customer-top-area-heading">  Client</h3>   
                </div>     
                <div className="row">
                <div className="col-12" style={{marginTop:'20px'}}>   
                <div className="row">
                    
                    <div className="col-12 col-md-6">
                    <div className="row">
                    
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>File Registration</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
    <label for="styled-checkbox-1"></label>
                   </div>

 
                    </div>  
                     </div>
                  {/*    <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>تسجيل الملف</p>   
                   <div>

<span className="checkbo-label-A" >A</span>
                   <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2"/>
    <label for="styled-checkbox-2"></label>
                   </div>

 
                    </div>  
                     </div> */}
                 </div> 
                 <div className="row">
                    
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Consultation</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3"/>
    <label for="styled-checkbox-3"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>استشارة</p>   
                   <div>

<span className="checkbo-label-A" >B</span>
                   <input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4"/>
    <label for="styled-checkbox-4"></label>
                   </div>

 
                    </div>  
                     </div> */}
                 </div> 
                 <div className="row">
                    
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Create a power of attorney</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value5"/>
    <label for="styled-checkbox-5"></label>
                   </div>

 
                    </div>  
                   </div>
                  {/*      <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>عمل وكالة</p>   
                   <div>

<span className="checkbo-label-A" >C</span>
                   <input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6"/>
    <label for="styled-checkbox-6"></label>
                   </div>

 
                    </div>  
                     </div> */}
                 </div>  
                 <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Behalf of another Person</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-be" type="checkbox" value="valuebe"/>
    <label for="styled-checkbox-be"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>نيابة عن شخص آخر
</p>   
                   

 
                    </div>  
                     </div> */}
                 </div> 
                     </div>



                     <div className="col-12 col-md-6">
                    <div className="row">
                    <div className="col-12 col-md-2">
                        </div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                      <p>Corporate</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-co" type="checkbox" value="valueco"/>
    <label for="styled-checkbox-co"></label>
                   </div>

 
                    </div>  
                     </div>
                     {/* <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>الشركات 
</p>   
                   

 
                    </div>  
                     </div> */}
                 </div> 
                 <div className="row">
                    <div className="col-12 col-md-2">
                        </div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                      <p>Bank</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-ba" type="checkbox" value="valueba"/>
    <label for="styled-checkbox-ba"></label>
                   </div>

 
                    </div>  
                     </div>
                  {/*    <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>بنوك
</p>   
                   

 
                    </div>  
                     </div> */}
                 </div> 
                 <div className="row">
                    <div className="col-12 col-md-2">
                        </div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                      <p>Individual</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-in" type="checkbox" value="valuein"/>
    <label for="styled-checkbox-in"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>فرد 
</p>   
                   

 
                    </div>  
                     </div> */}
                 </div> 
               
                
                
                     </div>

                 </div>  
                 </div>   
                 <div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Client Details</h3>    
             </div> 
                  
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Company Name*</p>
                            <input
                                placeholder=""
                              value={this.state.fname} 
                              onChange={this.changeHandler} 
                                name="fname"
                             
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.fname && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.fname}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Nationality*</p>
                        
                        <input className="Nationality1-list" 
                               value={this.state.Nationality} 
                               onChange={this.changeHandler} 
                               name="Nationality"
                        placeholder="search..."  autoComplete="on" style={{width:'100%'}}  list="Nationality1"  name="Nationality" />
                        <datalist id="Nationality1"  >
                                       {/*      <option selected disabled>Nationality</option> */}
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
                </datalist>
                        {this.state.error.Nationality && <p>{this.state.error.Nationality}</p>}
                        </div>
                    </div>


                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Occupation*</p>
                            <input
                                placeholder=""
                              value={this.state.Occupation} 
                                name="Occupation"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Occupation && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Occupation}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Authorized Person Name*</p>
                            <input
                                placeholder=""
                              value={this.state.Employer} 
                                name="Employer"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Employer && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Employer}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>UAE Address*</p>
                            <input
                                placeholder=""
                              value={this.state.UAEAddress} 
                                name="UAEAddress"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.UAEAddress && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.UAEAddress}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Permanent Address*</p>
                            <input
                                placeholder=""
                              value={this.state.PermanentAddress} 
                                name="PermanentAddress"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.PermanentAddress && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.PermanentAddress}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Mobile Number*</p>
                            <input
                                placeholder=""
                              value={this.state.MobileNumber} 
                                name="MobileNumber"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.MobileNumber && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.MobileNumber}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>company Tel*</p>
                            <input
                                placeholder=""
                              value={this.state.HomeTel} 
                                name="HomeTel"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.HomeTel && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.HomeTel}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Office Tel*</p>
                            <input
                                placeholder=""
                              value={this.state.OfficeTel} 
                                name="OfficeTel"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.OfficeTel && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.OfficeTel}</p>}
                        </div>
                    </div>


                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>VAT Registration Number*</p>
                            <input
                                placeholder=""
                              value={this.state.VATRegistration} 
                                name="VATRegistration"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.VATRegistration && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.VATRegistration}</p>}
                        </div>
                    </div>


                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Company Bank Name*</p>
                            <input
                                placeholder=""
                              value={this.state.CompanyBank} 
                                name="CompanyBank"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.CompanyBank && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.CompanyBank}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Fax*</p>
                            <input
                                placeholder=""
                              value={this.state.Fax} 
                                name="Fax"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Fax && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Fax}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Certified Email*</p>
                            <input
                                placeholder=""
                              value={this.state.email} 
                                name="email"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.email}</p>}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Summary Of Matter*

</p>
                         
                         <textarea
                                className="form-control"
                                value={this.state.description}
                                onChange={this.changeHandler} 
                                placeholder="Type your case detail*"
                                name="description" />
                            {this.state.error.description && <p>{this.state.error.description}</p>}
                        </div>
                    </div>
            
                    <div className="col-12" style={{marginBottom:'30px'}}>
                    <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Our professional fees of 1000 + VAT for open a file or verbal legal advise will be deducted from our fees 
for matter of further work is required.</p>
{/* <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize',textAlign:'right'}}>يتقاضى المكتب 1000 درهم + ضريبة القيمة المضافة عن فتح ملف او الاستشارة الشفوية و سيتم خصم
                                                  المبلغ من أتعاب القضية اذا ما تم قبولها او تسجيلها لدى المكتب</p>
 */}                      </div>  

                      <div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Case Type</h3>    
             </div> 
                  

             <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Select the Case Type*</p>
                          
                                    <select
                                        className="form-control"
                                        onChange={this.changeHandler} 
                                        name="case">
                                        {options.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.case && <p>{this.state.error.case}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Select the Lawyer Name*</p>
                          
                                    <select
                                        className="form-control"
                                        onChange={this.changeHandler} 
                                        name="LawyerName">
                                        {optionsLawyer.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.LawyerName && <p>{this.state.error.LawyerName}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}> Case Details</h3>    
             </div> 
                   
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Case Number*</p>
                       
                            <input
                              
                                name="caseNumber"
                                value={this.state.caseNumber}
                                 onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.caseNumber && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.caseNumber}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Other Party*</p>
                       
                            <input
                              
                            name="OtherParty"
                                value={this.state.OtherParty}
                                 onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.OtherParty && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.OtherParty}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Year*</p>
                       
                            <input
                              
                              name="Year"
                                value={this.state.Year}
                                 onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Year && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Year}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>OP’S Lawyer*</p>
                       
                            <input
                              
                              name="OPLawyer"
                                value={this.state.OPLawyer}
                                 onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.OPLawyer && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.OPLawyer}</p>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Court*</p>
                       
                            <input
                              
                              name="Court"
                              value={this.state.Court}
                               onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Court && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Court}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Stage of litgation and case type*</p>
                       
                            <input
                              
                              name="Stagelitgation"
                              value={this.state.Stagelitgation}
                               onChange={this.changeHandler} 
                                 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Stagelitgation && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Stagelitgation}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Accusation*</p>
                       
                            <input
                              
                              name="Accusation"
                              value={this.state.Accusation}
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Accusation && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Accusation}</p>}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Noted*</p>
                      
                            <textarea
                                className="form-control"
                                value={this.state.description1}
                                onChange={this.changeHandler} 
                                name="description1" />
                            {this.state.error.description1 && <p>{this.state.error.description1}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Type of POA</h3>    
             </div> 
             <div className="col-12 col-md-3">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Special</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-sp" type="checkbox" value="valuesp"/>
    <label for="styled-checkbox-sp"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>خاص
</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12 col-md-5">
                    <div className="row">
                    <div className="col-12 col-md-4">
                      </div>
                    <div className="col-12 col-md-8">
                      <div className="client-file-area-left-side">
                      <p>General</p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-ge" type="checkbox" value="valuege"/>
    <label for="styled-checkbox-ge"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>عام 
</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12">
                        <div className="formInput">
                        <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>The advisory opinion  and the date of commencement of work</p>
                      
                            <textarea
                                className="form-control"
                                value={this.state.description2}
                                onChange={this.changeHandler} 
                                placeholder="Type your case detail*"
                                name="description2" />
                            {this.state.error.description2 && <p>{this.state.error.description2}</p>}
                        </div>
                    </div>
<div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Fee Structure</h3>    
             </div>
             <div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Fixed Fee </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-fi" type="checkbox" value="valuesp"/>
    <label for="styled-checkbox-fi"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>رسوم ثابتة
</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Time Calculation </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-ti" type="checkbox" value="valueti"/>
    <label for="styled-checkbox-ti"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-5">
                     <div className="client-file-area-left-side">
                      <p>احساب الوقت
</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Annual Retainer </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-ann" type="checkbox" value="valueann"/>
    <label for="styled-checkbox-ann"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-5">
                     <div className="client-file-area-left-side1">
                      <p>التوكيل السنوي</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Other </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-oth" type="checkbox" value="valueoth"/>
    <label for="styled-checkbox-oth"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>آخری</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Consultation </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-con" type="checkbox" value="valuecon"/>
    <label for="styled-checkbox-con"></label>
                   </div>

 
                    </div>  
                     </div>
                   {/*   <div className="col-12 col-md-5">
                     <div className="client-file-area-left-side">
                      <p>استشارة</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>

<div className="col-12 col-md-4">
                    <div className="row">
                 
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                      <p>Pricing Plan  </p>   
                   <div>


                   <input class="styled-checkbox" id="styled-checkbox-pri" type="checkbox" value="valuepri"/>
    <label for="styled-checkbox-pri"></label>
                   </div>

 
                    </div>  
                     </div>
                    {/*  <div className="col-12 col-md-5">
                     <div className="client-file-area-left-side1">
                      <p>خطة الدفع</p>   
                   

 
                    </div>  
                     </div> */}
                    
                 </div> 
                 
</div>
<div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Litigation</h3>    
             </div> 
             <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Amount of Claim*</p>
                            <input
                                placeholder=""
                              value={this.state.AmountClaim} 
                                name="AmountClaim"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.AmountClaim && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.AmountClaim}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Total Professional Fees*</p>
                            <input
                                placeholder=""
                              value={this.state.ProfessionalFees} 
                                name="ProfessionalFees"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.ProfessionalFees && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.ProfessionalFees}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>In Advance*</p>
                            <input
                                placeholder=""
                              value={this.state.InAdvance} 
                                name="InAdvance"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.InAdvance && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.InAdvance}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>First Instance*</p>
                            <input
                                placeholder=""
                              value={this.state.FirstInstance} 
                                name="FirstInstance"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.FirstInstance && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.FirstInstance}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Appeal*</p>
                            <input
                                placeholder=""
                              value={this.state.Appeal} 
                                name="Appeal"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Appeal && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Appeal}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Court Fee
*</p>
                            <input
                                placeholder=""
                              value={this.state.CourtFee} 
                                name="CourtFee"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.CourtFee && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.CourtFee}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Cassation
*</p>
                            <input
                                placeholder=""
                              value={this.state.Cassation} 
                                name="Cassation"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Cassation && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Cassation}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Execution
*</p>
                            <input
                                placeholder=""
                              value={this.state.Execution} 
                                name="Execution"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Execution && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Execution}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                 <h3 className="customer-top-area-heading" style={{marginBottom:'20px'}}>  Legal Advise</h3>    
             </div> 
             <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Professional Fee
*</p>
                            <input
                                placeholder=""
                              value={this.state.ProfessionalFee1} 
                                name="ProfessionalFee1"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.ProfessionalFee1 && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.ProfessionalFee1}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Max
*</p>
                            <input
                                placeholder=""
                              value={this.state.Max} 
                                name="Max"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Max && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Max}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Official Fee
*</p>
                            <input
                                placeholder=""
                              value={this.state.OfficialFee55} 
                                name="OfficialFee55"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.OfficialFee55 && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.OfficialFee55}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="formInput">
                         <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Administrative fees and expenses
*</p>
                            <input
                                placeholder=""
                              value={this.state.Administrativefees} 
                                name="Administrativefees"
                               onChange={this.changeHandler} 
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.Administrativefees && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.Administrativefees}</p>}
                        </div>
                    </div>




                {/*     <div className="col-12" style={{marginBottom:'30px'}}>
                     <div className="customer-file-signature-area">
                     <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Lawyer Signature: </p>
                      
                        
                      <SignatureCanvas penColor='black'   ref={(ref) => { this.sigPad = ref }} 
      canvasProps={{className: 'sigCanvas'}} />
      <div className="clear-button" onClick={this.clear}>Clear</div>
                     </div>   
               
                      </div> */}  
                      <div className="col-12" style={{marginBottom:'30px'}}>
                     <div className="customer-file-signature-area">
                     <p style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize'}}>Client Signature: </p>
                      
                        
                      <SignatureCanvas penColor='black'   ref={(ref) => { this.sigPad1 = ref }} 
      canvasProps={{className: 'sigCanvas'}} />
      <div className="clear-button" style={{marginLeft:'5px',marginRight:'5px',marginTop:'5px'}} onClick={this.clear1}>Clear</div>
      <div className="clear-button" style={{marginLeft:'5px',marginRight:'5px',marginTop:'5px'}} onClick={this.trim}>Save</div>
                     </div>   
               
                      </div> 
                  <div className="col-12">
                  {this.state.trimmedDataURL
        ?<div className="customer-file-signature-area1"> <p>Your Signatures has been saved You can clear and save again: </p> <center><img 
        src={this.state.trimmedDataURL} /></center> </div>
        : null}
                    </div>  
{/* signatureFlag */}
<div className="col-12" >
{this.state.signatureFlag1 ? this.state.signatureFlag ?"":<p style={{fontWeight:'550',marginTop:'8px',color:'red'}}>You must have your signtute</p> :''}
   
    </div>

       
                     <div className="col-12" style={{marginTop:'0px'}}>
                       
              
                        <div className="checkbow-style-areafile" style={{color:'#666666'}}><span>    <input class="styled-checkbox"  
                                name="termsConditions"
                               onChange={this.handleConditions}  id="styled-checkbox-term" type="checkbox" value="valueterm"/>
    <label for="styled-checkbox-term"></label></span> <p style={{fontSize:'15px',marginLeft:'10px',fontWeight:'550',color:'#282e3f'}}>In the event that the agreed installments and fees are not paid in the contract or the office is not provided with all the required documents, experience fees and all
administrative fees, the office has the right not to attened the lawsuit without the slightest, responsibility, while preserving the office’s right to claim the rest of
the agreed fees. </p>
                    

</div>   
{this.state.termsSubmitFlag ? this.state.termsFlag ?<p style={{fontWeight:'550',marginTop:'8px',color:'red'}}>You must accept the terms & Conditions</p> : "":''}
   
{/* 
<p style={{textAlign:'right',fontWeight:'550',color:'#282e3f'}}>في حال عدم دفع الأقساط المتفق عليها و الرسوم بالعقد أو عدم تزويد المكتب بكافة المستندات المطلوبة و رسوم الخبرة وكافة الرسوم الإدارية يحق للمكتب عدم الحضور بالدعوى دون أد¦ مسؤولية مع حفظ
حق المكتب في المطالبة بباقي الأتعاب المتفق عليها</p>        
              
                      
            */} 
                       
                    </div>
                    
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area1">
                        <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                <button disabled={this.state.buttonFlag}  type="submit">Submit</button>
                    </div>
                    </div>
                </div>
        
            </form>
    }
           </div>
        )
    }
}
export default CustomerFileForm