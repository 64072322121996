import React, { useState,Component } from 'react'
import { NavLink, Link } from 'react-router-dom'


import ReCAPTCHA from "react-google-recaptcha";
import { Components } from 'antd/lib/date-picker/generatePicker';
import Invoiceview from './invoiceview'
import {
    Dropdown,
    Input,
    Label,
    Icon,
    Checkbox,
    TextArea,
    Button,
    Header,
    Image,
    Divider,
  } from "semantic-ui-react";
  import ReactToPrint from "react-to-print";

class InvoiceView  extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
        this.ref = React.createRef();
      }
 render(){
    return (
        <div className="customer-file">
            <div className="container">
              <center>  <Button
                                    className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print Your Invoice</div>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  </Button></center>
                                  <div style={{ overflow: "auto" }}>    
                <Invoiceview ref={(el) => (this.componentRef = el)} />
               </div>
            </div>
        </div>
    )
}
}
export default InvoiceView