import React from "react";
import FormCorporateSignUp from "./corporateSignUpForm";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import "./style.css";
const CorporateSignUpArea = ({ className }) => {
  return (
    <div className="SignUpCopArea">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
             */}{" "}
            <h2 style={{ color: "white", marginBottom: "30px" }}>
              <img
                style={{ width: "40px", marginRight: "15px" }}
                src={require("../../images/signIn/02-01.png")}
              />
              Corporate Sign Up
            </h2>
            <FormCorporateSignUp />
          </div>
          <div className="col-12 col-lg-4"></div>
        </div>
      </div>
    </div>
  );
};
export default CorporateSignUpArea;
