import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Form/style.scss";
import "./style.scss";

import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import "./switch.css";
class FormCounsellingPaid extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      password: "",
      city: "",
      case: "",
      IDNumber: "",
      subject: "",
      description: "",
      cv15: "",
      date: "",
      cardType: "",
      cardDate: "",
      cardNumber: "",
      CVC: "",
      holderName: "",
      paymentOption: "",
      nationality: "",
      phone: "",
      error: {},
      type: "password",
      passFlag: false,
      date88: "text",
      date99: "text",
      show: false,
      termsFlag: true,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "First Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
    lname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Last Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
    phone: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string":
              err.message = "";
            default:
              err.message = "Phone Number can not be Empty";
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be Empty";
              break;
          }
        });
        return errors;
      }),
    nationality: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "nationality must be select ";
              break;
          }
        });
        return errors;
      }),
    city: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "City can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    subject: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "subject can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    IDNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "ID Card Number can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    case: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case Type must be select ";
              break;
          }
        });
        return errors;
      }),
    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "description can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    cv15: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "please select the file first ";
              break;
          }
        });
        return errors;
      }),
    date: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "please select date first ";
              break;
          }
        });
        return errors;
      }),
    cardType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "select the card type first";
              break;
          }
        });
        return errors;
      }),
    cardNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Enter the card number first";
              break;
          }
        });
        return errors;
      }),
    cardDate: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must select the card expiry date";
              break;
          }
        });
        return errors;
      }),
    holderName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "card holder name can not be empty";
              break;
          }
        });
        return errors;
      }),
    paymentOption: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must select the payment option";
              break;
          }
        });
        return errors;
      }),
    CVC: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "CVC number can not be empty";
              break;
          }
        });
        return errors;
      }),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      lname: this.state.lname,
      phone: this.state.phone,
      email: this.state.email,
      city: this.state.city,
      nationality: this.state.nationality,
      IDNumber: this.state.IDNumber,
      case: this.state.case,
      subject: this.state.subject,
      description: this.state.description,
      cv15: this.state.cv15,
      cardType: this.state.cardType,
      date: this.state.date,
      cardDate: this.state.cardDate,
      cardNumber: this.state.cardNumber,
      CVC: this.state.CVC,
      holderName: this.state.holderName,
      paymentOption: this.state.paymentOption,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      this.setState({
        show: true,
        fname: "",
        lname: "",
        email: "",
        password: "",
        city: "",
        case: "",
        IDNumber: "",
        subject: "",
        description: "",
        cv15: "",
        date: "",
        cardType: "",
        cardDate: "",
        cardNumber: "",
        CVC: "",
        holderName: "",
        paymentOption: "",
        nationality: "",
        phone: "",
      });
    }
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  handleSent = () => {
    this.setState({ show: false });
  };
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleCVChange = (e) => {
    this.changeHandler(e);
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name99").innerHTML = e.target.files[0].name;
  };
  handleDateChange = (e) => {
    /*   this.changeHandler(e) */
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name88").innerHTML = e.target.files[0].name;
  };
  handleConditions = () => {
    if (this.state.termsFlag) {
      this.setState({ termsFlag: false });
    } else {
      this.setState({ termsFlag: true });
    }
  };
  render() {
    const options1 = [
      { level: "Select a Card* ", value: "" },
      { level: "VISA Card", value: "VISA Card" },
      { level: "Master Card", value: "Master Card" },
    ];
    const options2 = [
      { level: "Select Our Pricing Plan* ", value: "" },
      { level: "Basic Plan", value: "Basic Plan" },
      { level: "Silver", value: "Silver" },
      { level: "Gold", value: "Gold" },
    ];

    const options = [
      { level: "Select a Case* ", value: "Case Type" },
      { level: "Criminal Cases", value: "Criminal Cases" },
      { level: "Corporate Law", value: "Corporate Law" },
      { level: "Arbitration", value: "Arbitration" },
      {
        level: "International/ Interpol Cases",
        value: "International/ Interpol Cases",
      },
      { level: "Financial Crime", value: "Financial Crime" },
      { level: "Family business", value: "Family business" },
      { level: "Commercial", value: "Commercial" },
      { level: "Banking", value: "Banking" },
      {
        level: "Construction & Infrastructure",
        value: "Construction & Infrastructure",
      },
      { level: "Competition", value: "Competition" },
      {
        level: "Data Privacy & Cybersecurity",
        value: "Data Privacy & Cybersecurity",
      },
      { level: "Intellectual Property", value: "Intellectual Property" },
      { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
      { level: "Private Notary", value: "Private Notary" },
      { level: "Insurance", value: "Insurance" },
      { level: "Capital Market", value: "Capital Market" },
      { level: "Private Client Services", value: "Private Client Services" },
      { level: "Family Law", value: "Family Law" },
      { level: "Business Law", value: "Business Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Injury Law", value: "Injury Law" },
      { level: "Educational Law", value: "Educational Law" },
      { level: "VAT Law", value: "VAT Law" },
      { level: "Tax Law Advisory", value: "Tax Law Advisory" },
      { level: "International Tax Law", value: "International Tax Law" },
      { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
      { level: "Chemical", value: "Chemical" },
      { level: "Aviation", value: "Aviation" },
      { level: "Consumer Product", value: "Consumer Product" },
      { level: "Financial institution", value: "Financial institution" },
      {
        level: "Manufacture and Industrial",
        value: "Manufacture and Industrial",
      },
      { level: "Maritime & Shipping", value: "Maritime & Shipping" },
      { level: "Media", value: "Media" },
      { level: "Mining and Metals", value: "Mining and Metals" },
      { level: "Oil and Gas", value: "Oil and Gas" },
      {
        level: "Pharmaceuticals and Healthcare",
        value: "Pharmaceuticals and Healthcare",
      },
      { level: "Power", value: "Power" },
      { level: "Private Equity", value: "Private Equity" },
      { level: "Sovereigns", value: "Sovereigns" },
      { level: "Telecommunication", value: "Telecommunication" },
      { level: "Space Laws", value: "Space Laws" },
      { level: "Establishing Business", value: "Establishing Business" },
      { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
      { level: "Drug Law", value: "Drug Law" },
      { level: "Multinational Disputes", value: "Multinational Disputes" },
      { level: "Labor Law", value: "Labor Law" },
    ];

    return (
      <form onSubmit={this.submitHandler} className="counsellingForm">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="formInput">
              <input
                placeholder="First Name*"
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                className="form-control"
                type="text"
              />

              {this.state.error.fname && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.fname}
                </p>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="formInput">
              <input
                placeholder="Last Name*"
                name="lname"
                className="form-control"
                onChange={this.changeHandler}
                type="text"
              />
              {this.state.error.lname && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.lname}
                </p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput1">
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                onlyCountries={[
                  "ae",
                  "pk",
                  "us",
                  "gb",
                  "bd",
                  "in",
                  "eg",
                  "kz",
                  "ir",
                  "sa",
                  "ph",
                  "om",
                  "kw",
                ]}
                masks={{
                  ae: ".. ... ....",
                  at: "... .......",
                  us: "... ... ...",
                  gb: ".... ... ..",
                  bd: "... .... ...",
                  in: "... .... ...",
                  eg: "... .... ...",
                  kz: "... .... ...",
                  ir: "... .... ...",
                  sa: "... .... ..",
                  ph: "... .... ...",
                  om: "... .... .",
                  kw: "... .... ...",
                }}
                country={"ae"}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone: phone })}
              />

              {this.state.phone === "" ? (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.phone}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <input
                placeholder="Your Email Address"
                name="email"
                onChange={this.changeHandler}
                className="form-control"
                type="email"
              />

              {this.state.error.email && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.email}
                </p>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="formInput">
              <select
                value={this.state.nationality}
                className="form-control"
                onChange={this.changeHandler}
                name="nationality"
              >
                <option selected disabled>
                  Nationality
                </option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">
                  Congo, The Democratic Republic of The
                </option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">
                  French Southern Territories
                </option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </option>
                <option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">
                  Macedonia, The Former Yugoslav Republic of
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </option>
                <option value="Moldova, Republic of">
                  Moldova, Republic of
                </option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">
                  Netherlands Antilles
                </option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </option>
                <option value="Saint Vincent and The Grenadines">
                  Saint Vincent and The Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">
                  South Georgia and The South Sandwich Islands
                </option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </option>
                <option value="Taiwan, Province of China">
                  Taiwan, Province of China
                </option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">
                  Virgin Islands, British
                </option>
                <option value="Virgin Islands, U.S.">
                  Virgin Islands, U.S.
                </option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              {this.state.error.nationality && (
                <p>{this.state.error.nationality}</p>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="formInput">
              <input
                placeholder="City"
                name="city"
                onChange={this.changeHandler}
                value={this.state.city}
                className="form-control"
                type="text"
              />

              {this.state.error.city && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.city}
                </p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput form-control field-design99">
              <div style={{ marginTop: "2px" }}>
                Have you visited our Office?
              </div>
              <label className="switch99">
                <input type="checkbox" id="togBtn" />
                <div className="slider99 round99">
                  <span className="on99">Yes</span>
                  <span className="off99">No</span>
                </div>
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <input
                placeholder="Enter Your National ID Card Number"
                name="IDNumber"
                onChange={this.changeHandler}
                value={this.state.IDNumber}
                className="form-control"
                type="text"
              />

              {this.state.error.IDNumber && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.IDNumber}
                </p>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="counselling-right-form-content-personal">
              <h2>Your Case Details</h2>
            </div>
          </div>

          <div className="col-12">
            <div className="formInput">
              <select
                className="form-control"
                onChange={this.changeHandler}
                name="case"
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.level}
                  </option>
                ))}
              </select>
              {this.state.error.case && <p>{this.state.error.case}</p>}
            </div>
          </div>

          <div className="col-12">
            <div className="formInput">
              <input
                placeholder="Subject*"
                name="subject"
                value={this.state.subject}
                onChange={this.changeHandler}
                className="form-control"
                type="text"
              />

              {this.state.error.subject && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.subject}
                </p>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="formInput">
              <textarea
                className="form-control"
                value={this.state.description}
                onChange={this.changeHandler}
                placeholder="Type your case detail*"
                name="description"
              />
              {this.state.error.description && (
                <p>{this.state.error.description}</p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <div class="inputfile-box18">
                <input
                  type="file"
                  id="file99"
                  name="cv15"
                  class="inputfile18"
                  accept=".png,.jpg,.jpeg,.pdf,.docx"
                  onChange={this.handleCVChange}
                />
                <label for="file99">
                  <span id="file-name99" class="file-box">
                    {" "}
                    Upload your file here
                  </span>
                  <span class="file-button18">
                    <img
                      style={{ width: "25px" }}
                      src={require("../../images/counselling/file.png")}
                    />
                  </span>
                </label>
              </div>
              {this.state.error.cv15 && (
                <p
                  style={{
                    color: "red",
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  {this.state.error.cv15}
                </p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <input
                placeholder="Select date and time"
                name="date"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className="form-control"
                onFocus={() => this.setState({ date88: "date" })}
                type={this.state.date88}
              />
              {/*  <div class="inputfile-box18">
                    <input type="date" id="file88" name="file15" class="inputfile18"  onChange={this.handleDateChange} />
                    <label for="file88">
                        <span id="file-name88" class="file-box"> Select date and time</span>
                        <span class="file-button18">
                       
                       Choose a file
                        </span>
                    </label>
                    </div> */}
              {this.state.error.date && (
                <p
                  style={{
                    color: "red",
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  {this.state.error.date}
                </p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="counselling-right-form-content-personal">
              <h2>Payment and Confirmation</h2>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <select
                value={this.state.cardType}
                className="form-control"
                onChange={this.changeHandler}
                name="cardType"
              >
                {options1.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.level}
                  </option>
                ))}
              </select>
              {this.state.error.cardType && <p>{this.state.error.cardType}</p>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <input
                placeholder="Card Number*"
                value={this.state.cardNumber}
                name="cardNumber"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className="form-control"
                type="text"
              />

              {this.state.error.cardNumber && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.cardNumber}
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <input
                placeholder="Card Expiry date"
                name="cardDate"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className="form-control"
                onFocus={() => this.setState({ date99: "date" })}
                type={this.state.date99}
              />

              {this.state.error.cardDate && (
                <p
                  style={{
                    color: "red",
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  {this.state.error.cardDate}
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <input
                placeholder="CVC Number"
                name="CVC"
                value={this.state.CVC}
                onChange={this.changeHandler}
                /*  id="emailField" */
                className="form-control"
                type="text"
              />

              {this.state.error.CVC && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.CVC}
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <input
                placeholder="Card Holder Name*"
                name="holderName"
                value={this.state.holderName}
                onChange={this.changeHandler}
                /*  id="emailField" */
                className="form-control"
                type="text"
              />

              {this.state.error.holderName && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.holderName}
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="formInput">
              <select
                /*   value={this.state.case} */
                className="form-control"
                onChange={this.changeHandler}
                name="paymentOption"
              >
                {options2.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.level}
                  </option>
                ))}
              </select>
              {this.state.error.paymentOption && (
                <p>{this.state.error.paymentOption}</p>
              )}
            </div>
          </div>
          <div className="col-12" style={{ marginTop: "0px" }}>
            <div className="signon-bottom-area">
              <div>
                <div
                  className="checkbow-style-area12"
                  style={{ color: "#666666" }}
                >
                  <span>
                    <input
                      onClick={this.handleConditions}
                      className="checkbow-style12"
                      type="checkbox"
                    />
                  </span>{" "}
                  <span style={{ fontSize: "15px", marginLeft: "10px" }}>
                    By creating an account you agree to our
                  </span>
                </div>
                <p
                  style={{
                    color: "#666666",
                    textDecoration: "underline",
                    marginLeft: "30px",
                  }}
                >
                  <NavLink
                    style={{ color: "#666666", textDecoration: "underline" }}
                    exact
                    to="/terms-and-conditions"
                  >
                    {" "}
                    Terms & Conditions
                  </NavLink>
                </p>
              </div>
              <button disabled={this.state.termsFlag} type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="heading-modal">
              <img
                style={{ width: "30px" }}
                src={require("../../images/about/man-01.png")}
              />{" "}
              Counselling Paid Writing
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sent-message-area">
              <center>
                <p>
                  <i class="fa fa fa-check-circle-o"></i>
                </p>
                <h2>thank you!</h2>
                <p>Your submission has been received.</p>
                <button className="sent-button-des" onClick={this.handleSent}>
                  Ok
                </button>
              </center>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    );
  }
}
export default FormCounsellingPaid;
