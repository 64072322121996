import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const TaxContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Tax laws </h3>
        <h5>• VAT Law </h5>
        <p>
          We provide services of VAT law as our team of lawyers is specialized
          in auditing and accounting and can deal with VAT/Tax regulations as
          per your need. Since UAE has imposed Value Added Tax in the form of
          law, the role of VAT consultants has surged. Our services include VAT
          consultation and advisory, VAT registration, VAT returns, VAT
          implementation, and VAT recovery.
        </p>
        <h5>• Tax Law Advisory </h5>
        <p>
          Tax laws and regulations are complex areas for any business but no
          worries we provide one of the best tax consultancy services in UAE and
          can help you deal with any kind of Tax issues including Income Tax,
          Sales Tax, Tax Refunds, International Taxes, Withholding tax and any
          kind of Tax registration, filling and documentation.{" "}
        </p>
        <h5>• International Tax Law </h5>
        <p>
          Global business means international complexity. A judicious and
          integrated tax solution is always needed in dealing internationally.
          From simple tax advice to an international tax solution for
          multinational clients, our team can cover a wide range of resolving
          tax issues. Services regarding international tax include overseas tax
          obligations, new or changing overseas business operations,
          cross-border tax advice, and foreign tax compliance.{" "}
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default TaxContent;
