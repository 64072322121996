import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const VERIFY_CUTOMER_EMAIL = "VERIFY_CUTOMER_EMAIL";
export const VERIFY_CUTOMER_EMAIL_LOADING =
"VERIFY_CUTOMER_EMAIL_LOADING";
export const VERIFY_CUTOMER_EMAIL_LOADING_FAILED =
"VERIFY_CUTOMER_EMAIL_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const verifyCutomerTokenLoading = ms => ({
    type: VERIFY_CUTOMER_EMAIL_LOADING,
    payload: ms
    });
    
    export const verifyCutomerTokenLoadingFailed = ms => ({
    type: VERIFY_CUTOMER_EMAIL_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function verifyCutomerToken(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/users/auth/verfiyResetPasswordJWT`,obj);
    return dispatch => {

    dispatch(verifyCutomerTokenLoading());
   console.log(request,"request")
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response verifyCutomerToken")
    dispatch({
    type: VERIFY_CUTOMER_EMAIL,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
        if (error.response.status === 400 || error.response.status === 404 || error.response.status === 402 || error.response.status === 403) {
            console.log(error.response)
    dispatch(
        verifyCutomerTokenLoadingFailed(`The Link Has Expired`)
    );
    } else {
    dispatch(verifyCutomerTokenLoadingFailed('Opps Something Went Wrong!'));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(verifyCutomerTokenLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(verifyCutomerTokenLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


