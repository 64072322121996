import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import SearchSidebar from "../../../components/SearchSidebar";
import CetagorySidebar from "../../../components/CetagorySidebar";
import RecentPosts from "../../../components/RecentPosts";
import BlogPost from "../../../components/BlogPost";
import Tags from "../../../components/Tags";
import Instagram from "../../../components/Instagram";
import Twitter from "../../../components/twitter";
// images
import breadcumb from "../../../images/breadcumb/news-2.jpg";
import BreakingNews from "../../../components/breaking";
import blog1 from "../../../images/blog-details/blogs-1.jpg";
import blog2 from "../../../images/blog-details/blogs-2.jpg";
import blog3 from "../../../images/blog-details/blogs-3.jpg";
import blog4 from "../../../images/blog-details/blogs-4.jpg";
import blog5 from "../../../images/blog-details/blogs-5.jpg";
import blog6 from "../../../images/blog-details/Assigned-in-UAE-2.jpg";
import blog7 from "../../../images/blog-details/Role-of-the-Arbitration-2.jpg";
import blog8 from "../../../images/blog-details/married-in-Dubai-2.jpg";
import {Helmet} from "react-helmet";
import "./style.scss";
const blogPost = [
  {
    image: blog6,
    title: " How Intellectual Property Rights are Assigned in UAE",
    text: "This is a legal document, declaration of transferring the intellectual property rights from any individual or corporation onto another individual or corporation. ",
    Published: "25/09/2020",
    read: "/blogs/intellectual-property",
  },
  {
    image: blog7,
    title: "Role of the Arbitration Lawyers",
    text: "No matter which type of lawyer you are, you must have the ability to perform the process of arbitration. Usually, people seek help from the arbitrators to resolve any of their disputes between them.",
    Published: "25/09/2020",
    read: "/blogs/arbitration-lawyers",
  },
  {
    image: blog8,
    title: "How to get married in Dubai? ",
    text: "The law practiced in the United Arab Emirates is based upon Shariah. Marriage is the only legal way to live together for two people persons of the opposite gender unless they are relatives by blood or family members. ",
    Published: "25/09/2020",
    read: "/blogs/dubai-marriage",
  },
  {
    image: blog1,
    title: " Coronavirus: The Future won’t be the same, we hope a bright one. ",
    text: "What will be the future of the world? What will be the future for our loved ones? What will happen to international business markets?  ",
    Published: "18/05/2020",
    read: "/blogs/coronavirus",
  },
  {
    image: blog2,
    title: "The good news of Coronavirus",
    text: "Every day since the outbreak of coronavirus the situation is getting worse and worse around the world. There is nothing but fear, worry, and uncertainty.The concern about it is justified but, whether it’s a natural disaster, a war or a great public health threat ",
    Published: "18/05/2020",
    read: "/blogs/coronavirus-news",
  },
  {
    image: blog4,
    title: "UAE Ministry regulates employer-employee relation ",
    text: "As the world is badly hit by the Coronavirus pandemic, International markets and societies are facing extraordinary challenges, the whole world is in turmoil. ",
    Published: "18/05/2020",
    read: "/blogs/UAE-Ministry",
  },
  {
    image: blog3,
    title: "Seek advice from experts, not politicians.",
    text: "In the time like this when the whole world is facing a global problem that has never occurred before hundreds of doctors, scientists, politicians, and even teachers. ",
    Published: "18/05/2020",
    read: "/blogs/seek-advice",
  },
  {
    image: blog5,
    title: " Dubai Infrastructure is Good for your Business",
    text: "Dubai government is currently creating massive ways and opportunities for new and ongoing businesses, entrepreneurialism and providing them a perfect environment to enhance the business in every field.  ",
    Published: "19/05/2020",
    read: "/blogs/dubai-infrastructure",
  },
];
const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Blogs" }];

const BlogRightPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Legal Emirates  </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/blogs "
        />
        <meta
          name="description"
          content="Learn more about law terms and find about Legal Emirates attorneys, law teams and many more through our blogs. "
        />
      </Helmet>

      <header className="headerArea">
        {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Blogs"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <div className="blogPostArea blogPostLeftArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {blogPost.map((blog, i) => (
                  <div className="col-lg-6 col-12">
                    <div key={i} className="blogPostWrapper1">
                      <div className="blogPostImg">
                        <img src={blog.image} alt="" />
                      </div>
                      <div className="blogPostContent">
                        <ul className="blogPostMeta">
                          {/*  {blog.meta.map((meta, i) => (
                                <Fragment key={i}>
                                    <li><img src={meta.avatar} alt=""/></li>
                                    <li>{meta.name}</li>
                                    <li>{meta.level}</li>
                                    <li>{meta.date}</li>
                                </Fragment>
                            ))} */}
                        </ul>
                        <h3>
                          {" "}
                          <Link
                            exact
                            onClick={() => window.scrollTo(0, 0)}
                            to={blog.read}
                          >
                            {blog.title}{" "}
                          </Link>
                        </h3>
                        <p>{blog.text}</p>
                        <p>Published: {blog.Published}</p>
                        <h4>
                          <Link
                            exact
                            onClick={() => window.scrollTo(0, 0)}
                            to={blog.read}
                          >
                            Read More...
                          </Link>
                        </h4>
                        {/* <Link className="readmore" to='blog-details'>Read more..</Link> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <aside>
                {/* <SearchSidebar className="searchSidebarWrapper" /> 
                                <CetagorySidebar
                                    title="Cetagory"
                                    className="cetagoryWrap" />*/}
                <Tags />
                <RecentPosts className="recentPostWrapper" />
                <Twitter />
                <Instagram />
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default BlogRightPage;
