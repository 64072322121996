import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import BreakingNews from '../../../components/breaking';
import BlogPost from '../../../components/BlogPost'
import blog1 from '../../../images/blog-page/1.jpg'
import blog2 from '../../../images/blog-page/2.jpg'
import blog3 from '../../../images/blog-page/3.jpg'
import blog4 from '../../../images/blog-page/4.jpg'
import blog5 from '../../../images/blog-page/5.jpg'
import { Button,Modal,FormGroup,FormControl,Col,Row,Form,InputGroup,Media,Container} from 'react-bootstrap';
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/about.png'
import {Helmet} from "react-helmet";

import './style.scss'

const aboutText = [
    { text: "The world is going online, so as legal firms. Providing information to opening a file, having live consultations, and receiving expert advice, everything is now done through online websites. After 27 years of working in UAE and honored to litigate and represent in some of the high standard cases both local and worldwide, BADER HAMAD AL ZAABI Advocate & Legal Consultations are now providing online notary services through its website legalemirates.com. Our vision is to provide leading legal services according to International Laws and Standards while maintaining the ethics of law in order to defend our clients’ objectives. Our lawyers have built instinctive professionalism within themselves that you won't find anywhere else and still they try to solve every legal issue with full devotion and special care." },
    { text: " " },
]
const services = [
    
    {
        icon: 'flaticon-prison',
        title: 'Criminal Cases',
        route:'speciality-criminal',
        content: 'Dealing with criminal cases is always critical. We have decades of experience representing clients who are facing criminal charges.   '
    },
    {
        icon: 'flaticon-bank-1',
        title: 'Corporate Law',
        route:'speciality-business',
        content: 'We have specialist Corporate Lawyers who scrutinize clients’ corporate and commercial objectives. '
    },
    {
        icon: 'flaticon-law-5',
        title: 'Arbitration',
        route:'speciality-arbitration',
        content: 'We are offering services in arbitration law in case of disputes which are more effectual than other processes of custom law.  '
    },
    {
        icon: 'flaticon-global',
        title: 'International Interpol Cases ',
        route:'speciality-interpol',
        content: 'As we provide services in dealing with criminal law cases, sometimes it becomes necessary to collaborate with penal authorities like Interpol. '
    },
    {
        icon: 'flaticon-robber',
        title: 'Financial Crime ',
        route:'speciality-financial',
        content: 'Our Financial Crime team is one of the leading teams in solving complex financial crime and related matters.  '
    },{
        icon: 'flaticon-family',
        title: 'Family Law  ',
        route:'speciality-family',
        content: 'Family matters are always complex due to their emotional attachment. Our family law team is an expert in dealing with all kinds of matters involving families and their businesses.  '
    }
]
const services1 = [
    
    {
        icon: 'flaticon-prison',
        title: 'Criminal Cases',
        route:'speciality-criminal',
        content: 'Dealing with criminal cases is always critical. We have decades of experience representing clients who are facing criminal charges. It doesn’t matter what kind of charges you are facing we will collaborate and make a strong defense strategy. We will make sure to minimize the consequences to your life.   '
    },
    {
        icon: 'flaticon-bank-1',
        title: 'Corporate Law',
        route:'speciality-business',
        content: 'We have specialist Corporate Lawyers who scrutinize clients’ corporate and commercial objectives so that they can provide feasible and practical advice for their businesses including banking, financing, corporate transactions, commercial contracts, etc.  '
    },
    {
        icon: 'flaticon-law-5',
        title: 'Arbitration',
        route:'speciality-arbitration',
        content: 'We are offering services in arbitration law in case of disputes which are more effectual than other processes of custom law. Our expert arbitration lawyers will help clients in the dispute resolution process, including the drafting of arbitration clauses, pre-action issues, and meditation.   '
    },
    {
        icon: 'flaticon-global',
        title: 'International/ Interpol Cases  ',
        route:'speciality-interpol',
        content: 'As we provide services in dealing with criminal law cases, sometimes it becomes necessary to collaborate with penal authorities like Interpol. We have experience and already dealt with these kinds of situations. So, if your requirement is something that needs cooperation with crime control agencies you are at the right place.  '
    },
    {
        icon: 'flaticon-robber',
        title: 'Financial Crime ',
        route:'speciality-financial',
        content: 'Our Financial Crime team is one of the leading teams in solving complex financial crime and related matters. You can seek our help if you are facing crime issues related to corporate fraud, forgery, breaching of trust and bribery, etc.  '
    },{
        icon: 'flaticon-family',
        title: 'Family business  ',
        route:'speciality-family-business',
        content: 'Family matters are always complex due to their emotional attachment. Our family law team is an expert in dealing with all kinds of matters involving families and their businesses. Without damaging the family fabric, they give advice and provide legal solutions to families facing these issues. '
    },
    {
        icon: 'flaticon-market',
        title: 'Commercial',
        route:'speciality-commercial',
        content: 'Commercial contracts, joint ventures, commercial agencies, agreements, franchises, and business law are included in our Commercial law services. Our team has expertise in this sector and can deal with any kind of legal challenge and can make a gamut of commercial agreements required by businesses. '
    },
    {
        icon: 'flaticon-bank',
        title: 'Banking ',
        route:'',
        content: 'Our law firm has an experienced team of bank lawyers that take care of the legal issues in the Banking sector. We provide help handling the complex multijurisdictional transactions due to our vast knowledge in handling transactions globally. '
    },
    {
        icon: 'flaticon-worker-2',
        title: 'Construction & Infrastructure',
        route:'speciality-construction',
        content: 'Our construction and infrastructure team help clients facing legal issues in the construction and infrastructure industry. Our services include project documentation, contract advice, strategic project planning, risk management and dealing with dispute issues while doing business '
    },
    {
        icon: 'flaticon-management',
        title: 'Competition',
        route:'',
        content: 'We provide services to handle legal issues in Competition Law. We have expert attorneys who can deal with all the areas of competition law across UAE. '
    },
    {
        icon: 'flaticon-hacker',
        title: 'Data Privacy & Cybersecurity',
        route:'',
        content: 'Privacy & Cybersecurity terms and conditions are evolving with time so as the legal issues regarding them. If you are facing challenges in data protection, privacy, cybersecurity, business strategy issues or cyber incidents you can take our help, our team is adept at privacy and cybersecurity matters.  '
    },
    {
        icon: 'flaticon-intellectual-1',
        title: 'Intellectual Property ',
        route:'speciality-poverty',
        content: 'Our property lawyers provide legal assistance to clients in protecting their rights and interests. We advise clients in the legal, regulatory and commercial aspects of intellectual property.  '
    },
    {
        icon: 'flaticon-confidential-folder',
        title: 'Merger & Acquisitions ',
        route:'',
        content: 'Our team has been handling the most significant and high-profile Mergers and Acquisitions deals for a long time. Our services include preparing acquisition agreements and shareholder agreement, advising deal structures and mergers filings with relevant authorities, etc. '
    },
    {
        icon: 'flaticon-certificate',
        title: 'Private Notary  ',
        route:'',
        content: 'Our team provides Private Notary services to our clients. '
    },
    {
        icon: 'flaticon-life-insurance',
        title: 'Insurance',
        route:'',
        content: 'Our law firm operates a specialist Insurance Practice to meet all the needs of the insurance industry.  '
    },{
        icon: 'flaticon-financial-2',
        title: 'Capital Market ',
        route:'speciality-market',
        content: 'We are one of the leading law firms providing services in Capital Markets laws and regulations. We provide services for Financial Market legal issues.  '
    },
    {
        icon: 'flaticon-premium',
        title: 'Private Client Services ',
        route:'speciality-notary',
        content: 'Our law firm has an Internationally experienced team of multi-jurisdictional, multi-lingual lawyers. Our team will provide creative and bespoke solutions to clients’ legal issues with premium service. Our clients are multinational corporates, regional corporates, entrepreneurs, and VIPs, etc.   '
    },
    {
        icon: 'flaticon-insurance-3',
        title: 'Family Law ',
        route:'speciality-family',
        content: 'We deal with family law cases with extra care because of their emotional nature. We offer to resolve paternity disputes, division of property, termination of a relationship, child custody, and protection of the assets.    '
    },
    {
        icon: 'flaticon-tax',
        title: 'Business Law ',
        route:'',
        content: 'Focusing on little details, we will provide a solution to the problems that can hurt your business or cause legal disputes. And we will help you in business formation, making company policy, sales and consumer goods, intellectual property, taxes and even file for bankruptcy because lawyers do also help in bad times. We can also provide our services for immigration law because it is an important factor when it comes to expanding businesses and needing foreign workers.   '
    },
    {
        icon: 'flaticon-house-1',
        title: 'Real Estate Law ',
        route:'speciality-real-state',
        content: 'We will provide services to protect and defend your properties. Our skilled lawyers will help you with a wide range of legal matters, including buying and selling real estate, planning of real estate, bankruptcy proceedings, foreclosures and many more. '
    },
    {
        icon: 'flaticon-injury',
        title: 'Injury Law ',
        route:'',
        content: 'We provide legal help when you or a member of your family has been injured by the actions or negligence of another party and we understand the legal issues involved in these situations. We are prepared to help you determine who is accountable for the loss and ready for tenacious and aggressive approach if needed. '
    },
    {
        icon: 'flaticon-graduation-cap',
        title: 'Educational Law  ',
        route:'',
        content: 'We also provide services in educational law and handle legal matters related to education. We will elucidate and render advice on laws and regulations that concern schools, students, parents, teachers, supervisors, school boards and school districts.  '
    },
    {
        icon: 'flaticon-tax-2',
        title: 'Tax Law Advisory  ',
        route:'speciality-tax',
        content: 'Tax laws and regulations are complex areas for any business but no worries we provide one of the best tax consultancy services in the UAE and can help you deal with any kind of Tax issues including Income Tax, Sales Tax, Tax Refunds, International Taxes, Withholding tax and any kind of Tax registration, filling and documentation.   '
    },
    {
        icon: 'flaticon-taxes',
        title: 'International Tax Law  ',
        route:'',
        content: 'Global business means international complexity. A judicious and integrated tax solution is always needed in dealing internationally. From simple tax advice to an international tax solution for multinational clients, our team can cover a wide range of resolving tax issues. Services regarding International tax include overseas tax obligations, new or changing overseas business operations, cross-border tax advice, foreign tax compliance,  '
    },
    {
        icon: 'flaticon-band-1',
        title: 'Technology/Cyber Law ',
        route:'',
        content: 'As technology is rising day by day and the world is moving towards Artificial Intelligence resulting in the making of more and more laws and ethics to use this machinery. Services related Cyberlaw are indeed the need of the time and our team is ready to help you out. There is always a need to check how much of your business is affecting by information technology (especially when it is online). That is when our services are beneficial to you.   '
    },{
        icon: 'flaticon-scientist',
        title: 'Chemical',
        route:'',
        content: 'Every organization in the chemical sector want their advisors to have substantial experience in the business. We have the expertise to facilitate our clients with their challenges in the chemical sector.  '
    },
    {
        icon: 'flaticon-around',
        title: 'Aviation',
        route:'',
        content: 'We have been giving services to commercial aviation companies for a long time now and we got considerable experience in this sector. Using our resources, we help clients with all their needs, whether assisting in purchasing new assets or take any advantage of financing. '
    },
    {
        icon: 'flaticon-marketing',
        title: 'Consumer Product ',
        route:'',
        content: 'We will hear any issue regarding consumer product whether its varying customer trust, pricing or safety issues or rising digital influences. We provide consumer product companies the guidance needed to remain in the race of challenging international markets.   '
    },
    {
        icon: 'flaticon-money',
        title: 'Financial institution   ',
        route:'',
        content: 'We provide legal services to support all kinds of matters affecting financial institution including investment advice, transactions, regulatory advice, and other legal assistance.  '
    },
    {
        icon: 'flaticon-optimization',
        title: 'Manufacture and Industrial  ',
        route:'',
        content: 'Manufacturing and industrial companies always attract challenges while pursuing growth and business goals. Many business companies have pursued us to take legal guidance. We provide assistance and legal advice in establishing new business institution or expanding an already running organization.   '
    },{
        icon: 'flaticon-capitalization',
        title: 'Maritime & Shipping  ',
        route:'',
        content: 'Significant challenges exist in the shipping and maritime industry. Our law team that deals with the shipping and maritime sector provides expert advice and assistance to their clients in the field of international trade, transport, and planning.  '
    },
    {
        icon: 'flaticon-bullhorn',
        title: 'Media ',
        route:'',
        content: 'Media is evolving and increasing rapidly resulting in more complex challenges for new media companies. Our media law team is expert in providing legal solutions to your issues. Our media law services include media law compliance, media litigation, agency contracts, trademarks and many more.  '
    },
    {
        icon: 'flaticon-project-management',
        title: 'Mining and Metals ',
        route:'',
        content: 'We have an experienced team of lawyers who are experts in dealing with all the aspects of transactions involving the mining sector. Clients can take advice on any matter whether it is just initial exploration or complete business expansion strategies. '
    },
    {
        icon: 'flaticon-petroleum',
        title: 'Oil and Gas',
        route:'',
        content: 'We provide services to the oil, gas and petrochemical industry both private and public sectors around the world. Our team provides legal support to every complex petrochemical transaction. '
    },
    {
        icon: 'flaticon-insurance-4',
        title: 'Pharmaceuticals and Healthcare ',
        route:'',
        content: 'Our law team has a vast experience in this sector. They understand the business and legal challenges that pharmaceutical and healthcare companies usually face. '
    },
    {
        icon: 'flaticon-worker',
        title: 'Power',
        route:'',
        content: 'We provide services to power industries. Our lawyers understand the issues regarding the power sector and help our clients to resolve countless legal problems involving market conditions, governmental investigations and continuous advancement in energy technologies.   '
    },
    {
        icon: 'flaticon-secret-message',
        title: 'Private Equity ',
        content: 'Purchasing a controlling stake in a business, selling a company, fast complex transactions or a tax disclosure our private equity lawyers deal with all these terms with speed and innovation thanks to their private business experience.   '
    },
    {
        icon: 'flaticon-asset',
        title: 'Sovereigns ',
        route:'',
        content: 'Increasing global economic instability is resulting in new and complex sovereignty challenges for sovereign clients. Our attorneys are dedicated to helping with different areas that matter the most including banking and capital marketing, international trading, private-public partnerships, international arbitration, and sovereign wealth funds, etc.  '
    },
    {
        icon: 'flaticon-affiliate',
        title: 'Telecommunication ',
        route:'',
        content: 'Telecommunication technology is connecting our word, delivering tons of information, entertaining the world and so much more. Our media specialist team understands this evolving industry and ready to cope up with any legal challenge. We provide a vast range of services dealing with regulatory work, licensing, advising on rights and obligations and many more. '
    },
    {
        icon: 'flaticon-startup',
        title: 'Space Laws',
        route:'',
        content: 'Our expertise is not limited to some of the most basic law fields but we also have lawyers that can deal with the Space Science Laws and Treaties. Space law is the body of law governing space-related activities involving international agreements.    '
    },
    {
        icon: 'flaticon-business',
        title: 'Establishing Business',
        route:'',
        content: 'We have a group of corporate lawyers who have long periods of involvement with controlling the business people setting up organizations on the UAE soil. Our group will assist you with fulfilling the prerequisites for getting an alternate permit to operate, business permit for a wide range of exchanging exercises, proficient permit covering administrations and callings, or modern permit to build up or fabricate an industry. '
    },
    {
        icon: 'flaticon-money-1',
        title: 'Anti-Money Laundering',
        route:'',
        content: 'Our Law firm Anti Money Laundering practice covers a wide range of businesses and related legal issues. Our AML team is specialized in this very field and advises local and international banking institutions, credit unions, and money services businesses regarding their concerns linked to anti-money laundering. '
    },
    {
        icon: 'flaticon-chemistry',
        title: 'Drug Law',
        route:'',
        content: 'Our law firm has a long encounter and history in these sorts of cases and our group is prepared to help on the off chance that you or your family members are confronting these sorts of extreme changes in the occasions of medication dealing or shipping and so on. '
    },
    {
        icon: 'flaticon-globalization',
        title: 'Multinational Disputes',
        route:'',
        content: "Our law office is one of the area's driving law offices and has a notable involvement with managing the questions among national and worldwide organizations in the UAE. We routinely direct intervention matters under the light of the UAE laws. Colleagues manage universal and nearby organizations. authorities on the best way to unravel a contest between at least two organizations."
    },
    {
        icon: 'flaticon-laborers',
        title: 'Labor Law',
        route:'',
        content: "Labor lawful issues here are extraordinary and require additional consideration and uncommon consideration. We will give assistance in settling your legitimate issues in regards to work and business while remembering your workers' prosperity because the UAE work laws give offices and security of work rights and each firm should maintain those laws."
    },
    {
        icon: 'flaticon-planet-earth',
        title: 'Environmental',
        route:'',
        content: "UAE has enacted laws to preserve the environment. Disobeying these laws intentionally or unintentionally can cause you or your business a big problem. Legal Emirates’ lawyers are specialists and understand the environmental laws in the UAE. They will not let you break these laws which include banning dangerous animals, preserving the species of plants, sea dumping, and pollution, access use of plastic, preserving nature reserves, environmental protection, awareness, etc.  "
    },
    {
        icon: 'flaticon-policeman',
        title: 'Open Police complaint',
        route:'',
        content: " If you are a victim of any kind of crime, for instance, blackmailing, hacking, harassment, illegal money transfer, or anything else, our attorneys can guide you on lodging a complaint in the police department in the best ways possible to make your complaint valid and reasonable. "
    },
    {
        icon: 'flaticon-investment',
        title: 'Investment',
        route:'',
        content: "This law firm is one of the few law firms in the UAE that have decades of business experience gained through providing legal services to clients like local agencies, governmental agencies, and organizations including high-profile international corporations.  "
    },
    {
        icon: 'flaticon-auction-2',
        title: 'Certifications',
        route:'',
        content: " Our team of consultants is experienced and offers world-class services of certifications such as ISO Training, ISO Certification, and Consulting, ISO Implementation, ISO auditing in the Middle East, etc. "
    },
    {
        icon: 'flaticon-public-relation',
        title: 'Relations',
        route:'',
        content: " Our law team can help you build any legal relationship with other companies, corporations, individuals, or businesses, etc. Going deeper you can seek our advice in building some interrelationships in business, for instance, the employee/employer relationship, the agency relationship, and the independent contractor relationship, etc.  "
    },
    {
        icon: 'flaticon-dollar',
        title: 'Debt collection',
        route:'',
        content: "Collecting a debt in the Middle East is sometimes too difficult. Language barriers, cultural barriers, foreign laws and customs, and many other things can impose serious challenges while collecting a debt. The process becomes simpler when you take services from a law firm as a debt collection agency who understand the collection practice and can go through all the process. We provide services in this regard our attorneys understand the debt collection and can work to support the full recovery of your funds.  "
    },
    {
        icon: 'flaticon-paper',
        title: 'Opening of bank accounts',
        route:'',
        content: " Our team will guide you about the terms and conditions required while opening any bank account. We will study the main points of your case, for instance, why do you want to open an account and what is your main purpose? Then we will guide you accordingly with the best options available. "
    },
    {
        icon: 'flaticon-tax',
        title: 'Financial reports',
        route:'',
        content: " Our finance and audit team has been offering wide-range Accounting and Financial Reporting Services in the UAE. We help our clients to present their company’s financial status to investors. Some of the areas we cover in financial reports are, setting up the ledger, creating accounts, designing the financial statements, cash flow management, financial analysis, and preparation of financial statements for audit purposes, etc. "
    },
    {
        icon: 'flaticon-auction-1',
        title: 'Tips and advice',
        route:'',
        content: "In short, our law firm can help you with any kind of matter you want. There are hundreds of sub-categories of Law, and we are here at your service with all the experience we got over the decades.   "
    },
    {
        icon: 'flaticon-globe-1',
        title: 'Information and Services ',
        route:'',
        content: " The UAE has more than 4,000 federal and local e-Services on the portal, including federal e-services, transactional e-services, informational, commercial and social, etc. There are certain accounts that you must have in order to use these services, our team is expert and understands these e-services. If you are an individual or you have a company having a number of employees and you want to learn the usage of these e-services we can help you with that. "
    },
    {
        icon: 'flaticon-funds',
        title: 'Financial Services ',
        route:'',
        content: "Financial services are the backbone of any country as these determine economic growth. Different financial institutions take part in these services to keep the economy going. Financial services are also beneficial for people to maximize their businesses. We provide these financial services to our clients and we also advise about the risk management included in these affairs.  "
    },
    {
        icon: 'flaticon-grant',
        title: 'Financial guarantees',
        route:'',
        content: "Company owners often provide guarantees to the banks to support their borrowing. It usually happens when the company has a high level of borrowing or while establishing new businesses. Financial guarantees or bank guarantees are complex documents and require a great deal of focus and errorless work to maintain smooth dealing with the banks. With years of financial experience, we provide swift and authoritative services to resolve any legal matter or dispute with the banks relating to financial guarantees. "
    },
    {
        icon: 'flaticon-online-shopping-2',
        title: 'Online transactions',
        route:'',
        content: " The Middle East region has seen a great increase in online transactions during the past few years. As the number of these transactions is increasing, the complexity and legal issues related to the online transactions are also increasing. We have experienced business attorneys who understand the process of huge online transactions, and they are ready to provide services in this regard. "
    },
    {
        icon: 'flaticon-professional',
        title: 'Professional Services ',
        route:'',
        content: "If you are facing any kind of legal issue in your profession you can seek our services. It doesn’t matter what kind of profession currently you have, our consultants have a wide range of experience and they understand the issues of almost every particular profession, so feel free to share your legal matters with us.  "
    }
   
   

]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Find more about us - Legal Emirates </title>
                <link rel="canonical" href="https://legalemirates.com/about-us" />
                <meta name="description" content="Find who we are and explore more about our law firm." />
            </Helmet>

            <header className="headerArea">
                {/* <BreakingNews />
                 <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
           {/*  <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            /> */}
              <div class="wallet-page-top-container" >
               
               <div class="wallet-page-top-content">
               <div className="container">
                <h2 style={{textAlign:'left',fontWeight:'600'}}>About Us</h2>
             <p style={{}}>With more than two decades of experience, BADER HAMAD AL ZAABI Advocate & Legal Consultations is a UAE based advocate and legal consultant firm which is highly focused on providing pre-eminent legal services to its clients and to do so there must be a group of enduring legal advisors having years of experience. And as a Law firm, we are so glad to have lawyers that can deal with cases of any kind and law consultants that can share their years of experience with you in any desired branch of Law.
            <br />
          
             </p>
                </div>
                </div>
            
            </div>
           {/*  <Service className="serviceArea mt-0" /> */}
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="INTRODUCTION"
                subTitle=""
                images={about}
                orderLast="order-last"
                videoId="QD8p0W355-8"
                pragraphs={aboutText}
            />
              <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
          
         {/*    <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            /> */}
         {/*    <NewsLetter
                className="newsLetterArea"
            /> */}
             <div className="blogPostArea blogFullWidth">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <BlogPost />
                            <div  className="blogPostWrapper">
                            <div className="blogPostImg">
                        <img src={blog1} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                        <h3>
                        Why is a lawyer or consulting offices appointed?
</h3>
                        <p>The legal profession is known from ancient times, it is based on logical origins and scientific foundations, and all can't be familiar with its complex and intertwined sciences except through his experiences, accumulations and direct contact with practical and theoretical life, and whose talent and information have been scaled up for use in the right place and time, so whatever the scientific certificates of a person or his experience in life he must seek the assistance of the people of experience and specialization in judicial and legal matters, however you know, he still needs someone to discuss with him and consult with him.</p>
                        <p>Because whatever the right is clear in the case, and the judge, no matter how fair, he is subject to legal procedures and controls that must be adhered to, and it is simply possible to lose the case due to a mistake in the procedures or a delay in the dates.</p>
                        <p>The judge rules in the case according to the law, so it is not his task to defend you if you neglected this, and the judge has a valuable time, so he does not hear from the litigants except what is produced in the lawsuit, so no words are said, which may cause the judge to lose the ability. We have to talk about the case and determine what is the productive payment in it, and therefore the case is unfounded, but we often find the litigant deciding statements against his interest without knowing this, even though he has a right.</p>

                       <p>And the importance of seeking the assistance of the lawyer is confirmed by the fact that government agencies and legislators that legislate the law and although they have legal advisors, they also seek the help of lawyers, as well as companies, merchants and experts with the help of lawyers to communicate their case in the correct legal form, so everyone uses the lawyer if faced any legal problem.</p>
                       <p>A final word remained, namely that in criminal matters, the use of a lawyer is compulsory, even if the accused refuses to do so, then the judge assigns a lawyer to defend him, otherwise the entire trial will be nullified.</p>

                    </div>
                    </div>
                    <div  className="blogPostWrapper">
                            <div className="blogPostImg">
                        <img src={blog4} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                        <h3>
                        How do you choose the right lawyer?
                    </h3>
                    <p>Important rules to help you choose the right lawyer:</p>
                    <ul style={{color:'#666666'}}>
                        <li>• The selection must be based on an accredited law firm.</li>
                        <li>•	You must meet the owner of the office personally and explain him your case and not the office workers, so it is preferred.</li>
                        <li>•	Contact the office to make an appointment with the lawyer to meet him.</li>
                        <li>•	Explain the problem truthfully and honestly, and submit all your documents.</li>
                        <li>•	Listen to the legal opinion without exhausting the lawyer with controversial questions and hypotheses that do not exist.</li>
                        <li>•	Do not involve the lawyer with your emotional and social problems, as this is not his prerogative.</li>
                        <li>•	There is no objection to consulting more than one lawyer for comparison and weighting.</li>
                        <li>•	The lawyer discussed the opinions and other consultations with transparency, clarity and without argument.</li>
                        <li>•	Tell your lawyer if you have another lawyer who follows up on the case until coordination takes place.</li>
                        <li>•	Choose the lawyer you are comfortable with and trust his work and experience.</li>
                        <li>•	After agreeing with the lawyer, cooperate with the officers of the office, as each of them has their jurisdiction to serve your case.</li>
                    </ul>
                    </div>
                    </div>
                    <div  className="blogPostWrapper">
                            <div className="blogPostImg">
                        <img src={blog5} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                        <h3>
                        Important points you must remember:
                    </h3>
                    <ul style={{color:'#666666'}}>
                      <li>•	Be sure to sign a law contract and receive a copy of your own, and get a bond stating receipt of fees</li>
                      <li>•	Make sure to pay the fees on time so that your lawyer is not exposed to risks</li>
                      <li>•	Tell your lawyer directly if you notice any deficiencies from him or his staff.</li>
                      <li>•	Good guess in your lawyer, because winning the case is in your best interest and interest by adding it to the balance of his accomplishments.</li>
                      <li>•	Do not take any step during the consideration of the case without consulting your lawyer, even if that step is in your favor, such as conciliation, waiver, discharge, or any other agreement with the opponent, as some of these procedures may reflect on your legal position later, so keep your lawyer fully informed of any  Important developments. </li>
                      <li>Be careful when the lawyer is precious, do not consume his time so that he does not bother you and then deliberately abbreviate his legal answer. Beware that you will be smoked and you will personally initiate the case based on the information that you collected from the lawyers because there are legal complications that the lawyer will not discuss with you. After all, it is within his jurisdiction with the judiciary and not even You are in trouble you cannot get rid of it.</li>  
                        </ul>
                        <br/>
                        <br />
                        <p style={{fontWeight:'550',fontSize:'16px',textAlign:'justify'}}>Having necessary legal expertise, possessing the good moral qualities, and the perseverance that customers value are considered important factors in the success of any lawyer and these are the very ingredients to win a customer, as we seek and use the highest professional and ethical standards when choosing our employees to ensure that our clients obtain services of premium legal quality without compromising our ethical values.</p>
                      </div>  
                </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={readMore? services :services1}
                readMore={readMore}
                handleReadMore={()=>setReadMore(false)}
                handleLessService={()=>{setReadMore(true)
                    window.scrollTo(0, 7800);
                }}
            />
         {/*    <Testmonial
                className="testmonialArea pt100"
            /> */}
          
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage


/* 

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]
 */


/* key:AIzaSyBskZebOSqEXS279X2m_fH5dkbAOqY2p5U */