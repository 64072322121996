import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const PovertyContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Intellectual Property </h3>
        <p>
          Intellectual Property Rights (IPR) is a right to claim benefits when
          other brands use one’s property such as patents, trademarks, and
          designs of authors, artists, designers, and other creators.{" "}
        </p>
        <p>
          The Law Firm's intellectual property practice comprises specialist
          lawyers and experienced trademark and copyright professionals. Our
          team has been handling many of the most significant intellectual
          property cases for 27 years across the Middle East.{" "}
        </p>
        <p>
          Due to the continuous involvement with international trademark
          association (INTA) and other international events our team remains
          fully up to date with the international developments regarding IP law.{" "}
        </p>
        <p>This service covers the following:</p>
        <ul style={{ color: "#666666" }}>
          <li>• Trademark Prosecution </li>
          <li>• Enforcement & Litigation</li>
          <li>• Consumer Protection</li>
          <li>• Copyright </li>
          <li>• Patents and Designs </li>
          <li>• Competition</li>
        </ul>
        <h5>Client’s review after taking services from us </h5>
        <p>
          “Law Firm was very lucky for me. They helped me with all my property
          and custody issues. I couldn’t have asked for a better service, thanks
          again. I will come again if I feel a need in the future”
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default PovertyContent;
