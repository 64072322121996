const initialState = {
    isAuthenticated: false,
    user: [],
   info:false
    
}

 export const AdminAuthRiderReducer =(state = initialState, action )=> {
    switch(action.type) {
        case "SET_CURRENT_ADMIN":
            return {
                ...state,
                isAuthenticated: action.payload,
                user: action.payload,
                info:action.payload
            }
        default: 
            return state;
    }
}
