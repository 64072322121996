import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col,Button
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";

import { Icon,Table } from "semantic-ui-react";
class UserInvoices extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      class:"my-first-class-name",
      invoicesData:[],
      invoiceFlag:false,
      limit:10,
      pageNo:1,
      totalPages:1,
      invoiceError:'',
      pageNumber:1
    };
  }
  handleLimit=(e)=>{
 
    this.setState({limit:e.target.value,invoicesData:[],pageNumber:1})
    let obj={
      limit:e.target.value,
      pageNo:1
    }
    this.props.dispatch(Actions.getInvoicesCustomer(obj))
  
  } 
  handleJumpPage=(e)=>{
    this.setState({pageNumber:e.target.value,invoicesData:[]})
    let obj={
      limit:this.state.limit,
      pageNo:e.target.value
    }
    this.props.dispatch(Actions.getInvoicesCustomer(obj))
   
  }
  handlePageInc=()=>{
    if(this.state.totalPages>this.state.pageNumber){
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber + 1
  }));
  this.setState({invoicesData:[]})
  let obj={
    limit:this.state.limit,
    pageNo:this.state.pageNumber+1
  }
  this.props.dispatch(Actions.getInvoicesCustomer(obj))
  }
  }
  handlePageDec=()=>{
    if(this.state.pageNumber>1){
      this.setState({invoicesData:[]})
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber - 1
    }));
    let obj={
      limit:this.state.limit,
      pageNo:this.state.pageNumber-1
    }
    this.props.dispatch(Actions.getInvoicesCustomer(obj))
    }
  
  }
  componentWillMount(){
    let obj={
        limit:this.state.limit,
        pageNo:this.state.pageNumber
      }
      this.props.dispatch(Actions.getInvoicesCustomer(obj))
  }
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.getInvoiceReducer;
    if (!resNumber1.isLoading) {
        if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
            console.log(resNumber1.data,"fffffffffff")
            if(resNumber1.data.invoices.docs.length>0){
                this.setState({invoiceFlag:true,invoicesData:resNumber1.data.invoices.docs,totalPages:resNumber1.data.invoices.totalPages})
              }else{
                this.setState({invoicesData:["nothing"],invoiceFlag:false})
              }
        } else {
            this.setState({loaderFlag:false,errFlag:true,invoiceError:resNumber1.errMsg,invoiceFlag:false})
            }
    } 
}
  componentDidMount(){

  }
  handleColor=()=>{
    this.setState({class:"my-first-class-name"})
  }
  handleViewInvoice=(item)=>{
      localStorage.setItem("invoiceIdUserPanel",item._id)
      this.props.history.push('/invoiceView')
      
  }
  /* invoiceView */
  render() {
    return (
      <div >
                          <Table celled fixed padded  selectable  style={{backgroundColor:'transparent'}}
>
    <Table.Header style={{fontSize:'12px',borderBottom:'1px solid white'}}>
    {this.state.invoicesData.length>0 ?
                  this.state.invoicesData[0]!="nothing"?
      <Table.Row>
        <Table.HeaderCell 
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
        <Table.HeaderCell
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Invoice No</Table.HeaderCell>
        <Table.HeaderCell
          style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>File No</Table.HeaderCell>
       
        <Table.HeaderCell 
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Pending Amount</Table.HeaderCell>
         <Table.HeaderCell 
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Total Amount</Table.HeaderCell>

        <Table.HeaderCell
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Status</Table.HeaderCell>
                <Table.HeaderCell 
       style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
        singleLine textAlign='center'>Info</Table.HeaderCell>
     
      </Table.Row>
      :'':''}

      {this.state.invoicesData.length>0 ?
                  this.state.invoicesData[0]!="nothing"?
                this.state.invoicesData.map((item)=>{
                return <Table.Row>
      <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
           {item.userProfile && item.userProfile.firstName +" "+item.userProfile.lastName}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
              BZ/  {item.invoiceNo}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
              BZ/  {item.fileNo}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       AED {item.pendingAmount}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
      AED {item.amountToBePaid}
       </Table.Cell>
     
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       {item.status}
       </Table.Cell>
       <Table.Cell textAlign='center' style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}>
       <div onClick={()=>this.handleViewInvoice(item)} style={{textDecoration:'underline',cursor:'pointer'}}>View </div>
       </Table.Cell>

      </Table.Row>  
      
      
    })
    :<Table.Row>
         <Table.Cell
         style={{backgroundColor:'transparent',color:'white',borderRight:'1px solid white'}}
         colSpan='7'
textAlign='center'>
        <center>
      <div className="loader-for-tabel-invoce" style={{fontWeight:'550',color:'white',fontSize:'28px'}}>
   
      <div className="not-exist-area">
                <div>
                <i className="fi flaticon-search"></i>
{this.state.invoiceError.length>0? <p style={{fontWeight:'550',color:'white',fontSize:'28px'}}>{this.state.invoiceError}</p>: <p style={{fontWeight:'550',color:'white',fontSize:'28px'}}>No Invoice Exist Yet!</p>}
               
                <br />
              
                </div>
              </div>
      </div>
      </center>    
    </Table.Cell>
    </Table.Row>:<Table.Row>
         <Table.Cell 
         style={{backgroundColor:'transparent',color:'white',borderBottom:'1px solid white',borderRight:'1px solid white'}}
         textAlign='center' colSpan='6'  
textAlign='center'>
         <center>
   <div className="loader-for-tabel-invoce">
   <div className="loader1188">
    </div> 
  
   </div>

   </center>  
    </Table.Cell>
    </Table.Row>}
   
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
        </Table.Body>
        </Table>
        {this.state.invoiceFlag ?
        <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select style={{color:'#353535'}}  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select style={{color:'#353535'}} onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>:''}
      </div>
    );
  }
}
const mapStateToProps=(state)=> {
return {
    getInvoiceReducer:state.UserInvoices1888.getInvoiceReducer
};
} 
export default compose(
withReducer("UserInvoices1888", reducer),
connect(mapStateToProps),withRouter
)(UserInvoices);
