import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import RecentPosts from '../../../components/RecentPosts'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
import Twitter from '../../../components/twitter'
// images
import breadcumb from '../../../images/breadcumb/news-2.jpg'
import blog3 from '../../../images/blog-details/blog-4.jpg'
import {Helmet} from "react-helmet";

import './style.scss'



const UAEMinistry = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs - Legal Emirates  </title>
                <link rel="canonical" href="https://legalemirates.com/blogs/uae-ministry " />
                <meta name="description" content="Learn more about law terms and find about Legal Emirates attorneys, law teams and many more through our blogs. " />
            </Helmet>

            <header className="headerArea">
 {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
            <div className="blogPostArea blogPostLeftArea" style={{paddingTop:'45px'}}>
                <center>
                   <h1 style={{fontWeight:'550'}}>  UAE Ministry</h1> 
                   <h2 style={{fontWeight:'550'}}>  UAE Ministry regulates employer-employee relation </h2>
                   <p style={{fontWeight:'550'}}>16 May 2020/Dubai</p>
                </center>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                     
                <div  className="blogPostWrapper1">
                    <div className="blogPostImg">
                        <img src={blog3} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                    <p style={{fontWeight:'550'}}>As the world is badly hit by the Coronavirus pandemic, International markets and societies are facing extraordinary challenges, the whole world is in turmoil. In this challenging environment, the safety of people, their life progress and businesses, are all at risk. Many governments are restructuring their business terms to benefit both the employer and employee. The government of the UAE also has taken some measures in this regard. </p>
                    <p style={{fontWeight:'550'}}>Abu Dhabi: The Ministry of Human Resources and Emiratisation has asked the private sector industry to redesign the contractual relationship with their employees due to the effects of Coronavirus. New contracts and policies are to be made in mutual accord through gradual implementations. </p>
                    <p style={{fontWeight:'550'}}>Nasser Thani Al Hamli, Minister of Human Resources and Emiratisation approved the decision that the businesses affected by the measure taken to prevent the spread of coronavirus and seeking to reshuffle their organization must adopt the following actions during the agreement with their workers. </p>
                     <ul style={{marginBottom:'20px'}}>
                         <li>•	Introducing a system through which employees can work remotely, working from their residence. </li>
                         <li>•	Private businesses must make an agreement that will allow the workers to get paid leaves. But there are also unpaid leaves.</li>
                         <li>•	The private sector can temporarily reduce the salaries during the aforementioned period. But they also can permanently reduce salaries. </li>
                     </ul>
                     <h4 style={{fontWeight:'550'}}> Extra Staff </h4>
                    <p style={{fontWeight:'550'}}>The decision made by the Ministry of Human Resources and Emiratisation also states that the affected organizations that have more workers than they need shall register the extra staff on the virtual job market so that they can be hired by other businesses according to their backgrounds. </p>
                    <p style={{fontWeight:'550'}}>Meanwhile, the workers will remain to get accommodation and other charges, except for the salary until they are hired by other firms. </p>
                    <h4 style={{fontWeight:'550'}}> New staff</h4>
                    <ul style={{marginBottom:'20px'}}>
                        <li>•	They have to post their job vacancies on the virtual market, search the data available and hire a person that meets their requirements. </li>
                        <li>•	Apply for a work permit from ministry, through online systems, and hire the selected person. </li>
                        <li>•	Businesses can choose between transfer work permit or new employee and temporary work permit or a part-time work permit. </li>
                    </ul>
                    <h4 style={{fontWeight:'550'}}> Temporary reduction in salary</h4>
                    <p style={{fontWeight:'550'}}>Article 5 of the decision states that businesses seeking to temporarily reduce the salary of a worker during a given period shall sign an additional annexure. But the employee must abide by all terms and conditions provided by that business. </p>
                    <p style={{fontWeight:'550'}}>The annexure can be renewed in the consensus of both parties. It shall be issued in two copies, with both parties having each copy. </p>
                    <p style={{fontWeight:'550'}}>The employer can be asked anytime by the ministry to present the agreement. So it is important to have it ready all the time.  </p>
                    <h4 style={{fontWeight:'550'}}> Permanent reduction of salary </h4>
                    <p style={{fontWeight:'550'}}>As per Article 6, the ministry’s approval is needed if any business wants to permanently reduce the salary of an employee. </p>
                    <h4 style={{fontWeight:'550'}}> Job seekers </h4>
                    <p style={{fontWeight:'550'}}>Under Article 7, employees who want to get new jobs in the country must register with the virtual job market and apply there, the available jobs there are registered by almost every type of organization. The employee can decide where to apply according to his qualifications and experience. 

</p>
               </div>
                </div>
           
                  
                        </div>

                    </div>
                </div>
            </div>
           {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default UAEMinistry