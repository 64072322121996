import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import PricingTable from "../../../components/PricingTable";
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea';
import BreakingNews from '../../../components/breaking';
import HeroSlider from '../../../components/HeroSlider'
// images
import breadcumb from '../../../images/breadcumb/ind.jpg'
import {Helmet} from "react-helmet";

import './style.scss'


const heroSliders = [
    {
        images: 'slideWrapperOneindu',
        title: 'Manufacture and Industrial ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu1',
        title: 'Financial institution  ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu2',
        title: 'Aviation ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu3',
        title: 'Chemical',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu4',
        title: 'Maritime & Shipping ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu5',
        title: 'Media  ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu6',
        title: 'Mining and Metals  ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu7',
        title: 'Oil and Gas ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu8',
        title: 'Pharmaceuticals and Healthcare  ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu9',
        title: 'Power ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu10',
        title: 'Private Equity ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu11',
        title: 'Sovereigns ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu12',
        title: 'Telecommunication ',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    },
    {
        images: 'slideWrapperOneindu13',
        title: 'Space Laws',
        subTitle: '',
        text: 'Corporate Specialist',
        button: 'Read More',
        rout:"industries"
    }
]


const aboutText = [
    { text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,' },
    { text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum' },
]

const services = [
    
    {
        icon: 'flaticon-scientist',
        title: 'Chemical',
        content: 'Every organization in the chemical sector want their advisors to have substantial experience in the business. We have the expertise to facilitate our clients with their challenges in the chemical sector.  '
    },
    {
        icon: 'flaticon-around',
        title: 'Aviation',
        content: 'We have been giving services to commercial aviation companies for a long time now and we got considerable experience in this sector. Using our resources, we help clients with all their needs, whether assisting in purchasing new assets or take any advantage of financing. '
    },
    {
        icon: 'flaticon-marketing',
        title: 'Consumer Product ',
        content: 'We will hear any issue regarding consumer product whether its varying customer trust, pricing or safety issues or rising digital influences. We provide consumer product companies the guidance needed to remain in the race of challenging international markets.   '
    },
    {
        icon: 'flaticon-money',
        title: 'Financial institution   ',
        content: 'We provide legal services to support all kinds of matters affecting financial institution including investment advice, transactions, regulatory advice, and other legal assistance.  '
    },
    {
        icon: 'flaticon-optimization',
        title: 'Manufacture and Industrial  ',
        content: 'Manufacturing and industrial companies always attract challenges while pursuing growth and business goals. Many business companies have pursued us to take legal guidance. We provide assistance and legal advice in establishing new business institution or expanding an already running organization.   '
    },{
        icon: 'flaticon-capitalization',
        title: 'Maritime & Shipping  ',
        content: 'Significant challenges exist in the shipping and maritime industry. Our law team that deals with the shipping and maritime sector provides expert advice and assistance to their clients in the field of international trade, transport, and planning.  '
    }
]

const services1 = [
    
    {
        icon: 'flaticon-scientist',
        title: 'Chemical',
        content: 'Every organization in the chemical sector want their advisors to have substantial experience in the business. We have the expertise to facilitate our clients with their challenges in the chemical sector.  '
    },
    {
        icon: 'flaticon-around',
        title: 'Aviation',
        content: 'We have been giving services to commercial aviation companies for a long time now and we got considerable experience in this sector. Using our resources, we help clients with all their needs, whether assisting in purchasing new assets or take any advantage of financing. '
    },
    {
        icon: 'flaticon-marketing',
        title: 'Consumer Product ',
        content: 'We will hear any issue regarding consumer product whether its varying customer trust, pricing or safety issues or rising digital influences. We provide consumer product companies the guidance needed to remain in the race of challenging international markets.   '
    },
    {
        icon: 'flaticon-money',
        title: 'Financial institution   ',
        content: 'We provide legal services to support all kinds of matters affecting financial institution including investment advice, transactions, regulatory advice, and other legal assistance.  '
    },
    {
        icon: 'flaticon-optimization',
        title: 'Manufacture and Industrial  ',
        content: 'Manufacturing and industrial companies always attract challenges while pursuing growth and business goals. Many business companies have pursued us to take legal guidance. We provide assistance and legal advice in establishing new business institution or expanding an already running organization.   '
    },{
        icon: 'flaticon-capitalization',
        title: 'Maritime & Shipping  ',
        content: 'Significant challenges exist in the shipping and maritime industry. Our law team that deals with the shipping and maritime sector provides expert advice and assistance to their clients in the field of international trade, transport, and planning.  '
    },
    {
        icon: 'flaticon-bullhorn',
        title: 'Media ',
        content: 'Media is evolving and increasing rapidly resulting in more complex challenges for new media companies. Our media law team is expert in providing legal solutions to your issues. Our media law services include media law compliance, media litigation, agency contracts, trademarks and many more.  '
    },
    {
        icon: 'flaticon-project-management',
        title: 'Mining and Metals ',
        content: 'We have an experienced team of lawyers who are experts in dealing with all the aspects of transactions involving the mining sector. Clients can take advice on any matter whether it is just initial exploration or complete business expansion strategies. '
    },
    {
        icon: 'flaticon-petroleum',
        title: 'Oil and Gas',
        content: 'We provide services to the oil, gas and petrochemical industry both private and public sectors around the world. Our team provides legal support to every complex petrochemical transaction. '
    },
    {
        icon: 'flaticon-insurance-4',
        title: 'Pharmaceuticals and Healthcare ',
        content: 'Our law team has a vast experience in this sector. They understand the business and legal challenges that pharmaceutical and healthcare companies usually face. '
    },
    {
        icon: 'flaticon-worker',
        title: 'Power',
        content: 'We provide services to power industries. Our lawyers understand the issues regarding the power sector and help our clients to resolve countless legal problems involving market conditions, governmental investigations and continuous advancement in energy technologies.   '
    },
    {
        icon: 'flaticon-secret-message',
        title: 'Private Equity ',
        content: 'Purchasing a controlling stake in a business, selling a company, fast complex transactions or a tax disclosure our private equity lawyers deal with all these terms with speed and innovation thanks to their private business experience.   '
    },
    {
        icon: 'flaticon-asset',
        title: 'Sovereigns ',
        content: 'Increasing global economic instability is resulting in new and complex sovereignty challenges for sovereign clients. Our attorneys are dedicated to helping with different areas that matter the most including banking and capital marketing, international trading, private-public partnerships, international arbitration, and sovereign wealth funds, etc.  '
    },
    {
        icon: 'flaticon-affiliate',
        title: 'Telecommunication ',
        content: 'Telecommunication technology is connecting our word, delivering tons of information, entertaining the world and so much more. Our media specialist team understands this evolving industry and ready to cope up with any legal challenge. We provide a vast range of services dealing with regulatory work, licensing, advising on rights and obligations and many more. '
    },
    {
        icon: 'flaticon-planet',
        title: 'Space Laws',
        content: 'Our expertise is not limited to some of the most basic law fields but we also have lawyers that can deal with the Space Science Laws and Treaties. Space law is the body of law governing space-related activities involving international agreements.    '
    },
    {
        icon: 'flaticon-startup',
        title: 'Establishing Business',
        content: 'We have a group of corporate lawyers who have long periods of involvement with controlling the business people setting up organizations on the UAE soil. Our group will assist you with fulfilling the prerequisites for getting an alternate permit to operate, business permit for a wide range of exchanging exercises, proficient permit covering administrations and callings, or modern permit to build up or fabricate an industry. '
    },
    {
        icon: 'flaticon-business',
        title: 'Anti-Money Laundering',
        content: 'Our Law firm Anti Money Laundering practice covers a wide range of businesses and related legal issues. Our AML team is specialized in this very field and advises local and international banking institutions, credit unions, and money services businesses regarding their concerns linked to anti-money laundering. '
    },
    {
        icon: 'flaticon-chemistry',
        title: 'Drug Law',
        content: 'Our law firm has a long encounter and history in these sorts of cases and our group is prepared to help on the off chance that you or your family members are confronting these sorts of extreme changes in the occasions of medication dealing or shipping and so on. '
    },
    {
        icon: 'flaticon-globalization',
        title: 'Multinational Disputes',
        content: "Our law office  is one of the area's driving law offices and has a notable involvement with managing the questions among national and worldwide organizations in the UAE. We routinely direct intervention matters under the light of the UAE laws. Colleagues manage universal and nearby organizations. authorities on the best way to unravel a contest between at least two organizations."
    },
    {
        icon: 'flaticon-laborers',
        title: 'Labor Law',
        content: "Labor lawful issues here are extraordinary and require additional consideration and uncommon consideration. We will give assistance in settling your legitimate issues in regards to work and business while remembering your workers' prosperity because the UAE work laws give offices and security of work rights and each firm should maintain those laws."
    }
   

]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Corporate Services'}
]

const IndustriesPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Industries - Legal Emirates </title>
                <link rel="canonical" href="https://legalemirates.com/industries " />
                <meta name="description" content="Discover how Legal Emirates attorneys apply their expertise to help clients in different commercial and corporate sectors with different challenges.  " />
            </Helmet>

            <header className="headerArea">
       {/*      <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
          {/*   <Breadcumb 
                className="breadcumbArea"
                title="Specialist Corporate"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            /> */}
       {/*      <Service className="serviceArea mt-0" /> */}
       {/* ourServiceAreaStyleFour  ourServiceAreaStyleThree  bgFFF */}
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={readMore? services :services1}
                readMore={readMore}
                handleReadMore={()=>setReadMore(false)}
                handleLessService={()=>setReadMore(true)}
            />
            <PricingTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            />
         {/*    <Testmonial
                className="testmonialArea pt100"
            /> */}
           {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default IndustriesPage


/* 
const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]
*/