import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const LaborContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Labor Law</h3>
        <p>
          One of the leading legal platforms based in the UAE and having 27
          years of experience separate us from others and make us unique in
          every particular field of law.{" "}
        </p>
        <p>
          Every business faces labor and employment issues and especially in the
          Middle East where almost all the laborers and employees are from other
          countries. The labor legal issues here are unique and require extra
          attention and special care. We will provide help in resolving your
          legal issues regarding labor and employment while keeping your
          laborers’ well-being in mind because the UAE labor laws provide
          facilities and protection of labor rights and every firm must abide by
          those laws.{" "}
        </p>
        <p>We advise and offer services for various type of issues:</p>
        <ul style={{ color: "#666666" }}>
          <li>• Immigration Visa </li>
          <li>• Visa process (Cancelation, Issuance, Renewal)</li>
          <li>• End of Service Benefits and Compensations </li>
          <li>• Employment Policies and Termination Policies </li>
          <li>• Employment Contracts </li>
          <li>• Human Rights and regarding issues </li>
          <li>• And many more </li>
        </ul>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default LaborContent;
