import React from 'react'
import FormResetPassword from './FormResetPass'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
const FResetPasswordArea = ({ className }) => {
    return (
        <div className="ForgotPasswordArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-12">
                    
                    </div>
                    <div className="col-12 col-lg-6">
                  {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
 */}                   <FormResetPassword /> 
                    </div>
                    <div className="col-lg-3 col-12">
                    
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default FResetPasswordArea