import React, { useState } from 'react'
import PaymentFormInvoice from './paymentForm'
import { NavLink, Link } from 'react-router-dom'
import SearchSidebar from '../../SearchSidebar'
import './style.scss';
import './style.css';
const PaymentAreaInvoice = ({ className }) => {
    const [search, setSearch] = useState()
    const submitHandler = e => {
        e.preventDefault()
        console.log(search)
    }
    return (
        <div className="CounsellingPaid" style={{marginTop:'-50px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                    <div className="counselling-images-area">
                    <img src={require('../../../images/logo/logo2.png')} />
                    
                    </div>
                    <hr class="line-Bar-style"></hr>
                    <div className="counselling-left-content-area">
                    <h2>We are doing Our best to appointment</h2>
                    </div>

                
                    </div>
                    <div className="col-12 col-lg-7">
                     <div className="counselling-right-content-area">
                      <div className="counselling-right-content-area-header">
                         <h2><img style={{width:'28px'}} src={require('../../../images/topHeader/48v-01.png')} /> Payment Invoice</h2>
                          </div>   
                          <div className="counselling-right-content-area-form-content" >
                              <h2 style={{fontSize:'15px'}}>Please Provide the billing & payment information bellow</h2>
                         </div> 
                     <PaymentFormInvoice /> 
                    </div>   
                 
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default PaymentAreaInvoice