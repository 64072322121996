import React, { Component } from "react";
import Joi from "joi-browser";
import { NavLink, Link, withRouter } from "react-router-dom";
import "../Form/style.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";

class FormSignIn extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
      password: "",
      error: {},
      type: "password",
      passFlag: false,
      loaderFlag: false,
      errFlag: false,
      errMessage: "",
      loginFlag12: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    if (localStorage.jwtToken) {
      console.log(localStorage.jwtToken, "localStorage.jwtToken");
      this.props.history.push("/userpanel");
    } else {
      console.log("yes");
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "must be a valid email";
              break;
            default:
              err.message = "must enter an email";
              break;
          }
        });
        return errors;
      }),

    password: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a password";
              break;
          }
        });
        return errors;
      }),
    address: Joi.string(),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      email: this.state.email,
      password: this.state.password,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      this.setState({ loaderFlag: true, loginFlag12: true });
      let obj = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.dispatch(Actions.customerSignInAuth(obj));
      setTimeout(() => {
        this.setState({
          password: "",
        });
      }, 1000);
      var myFlag = true;
      store.subscribe(() => {
        let resNumber1 = store.getState().CustomerSignIN.customerSignInReducer;

        if (!resNumber1.isLoading) {
          if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
            console.log(resNumber1.data);

            if (resNumber1.data.user.emailVerified === true) {
              if (localStorage.loginFlag === "login") {
                if (this.state.loginFlag12) {
                  /*    if(myFlag){
                                myFlag=false */
                  this.setState({ loginFlag12: false });
                  this.props.history.push("/");
                  if (!toast.isActive(this.props.toastId.current)) {
                    this.props.toastId.current = toast.warn(
                      "You Have Successfully Login thanks!",
                      {
                        toastId: "logined",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        authFlag: true,
                      }
                    );
                    /* } */
                  }
                }
              }
            }
            this.setState({ loaderFlag: false });
          } else {
            console.log(resNumber1.errMsg, "resNumber1.errMsg");
            this.setState({
              loaderFlag: false,
              errFlag: true,
              errMessage: resNumber1.errMsg,
            });
          }
        }

        /*           let resNumber2 = store.getState().CustomerSignIN.authRiderReducer;
                if(resNumber2.isAuthenticated){
                    if(this.state.authFlag){
        
                    }
                   this.props.history.push('signin')
            }else{
             
            } */
      });
    }
  };
  handleSent = () => {
    this.setState({ show: false });
  };
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
          <div className="col-12">
            <div className="formInput form-control field-design">
              <img src={require("../../images/signIn/03-01.png")} />
              <input
                placeholder="Email"
                value={this.state.email}
                name="email"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="email"
              />

              {this.state.error.email && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.email}
                </p>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="formInput form-control field-design1">
              <img
                className="password-image1"
                src={require("../../images/signIn/04-01.png")}
              />
              <input
                placeholder="Password"
                value={this.state.password}
                name="password"
                onChange={this.changeHandler}
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type={this.state.type}
              />
              {this.state.passFlag ? (
                <img
                  className="password-image2"
                  onClick={this.handleShowPass}
                  title="hide password"
                  src={require("../../images/signIn/hide-01.png")}
                />
              ) : (
                <img
                  className="password-image2"
                  onClick={this.handleHidePass}
                  title="show password"
                  src={require("../../images/signIn/show-01.png")}
                />
              )}
              <div
                style={{ marginTop: "8px" }}
                className={
                  this.state.error.password ? "password-area" : "password-area1"
                }
              >
                {this.state.error.password && (
                  <p style={{ fontWeight: "550" }}>
                    {this.state.error.password}
                  </p>
                )}
                <p
                  style={{
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  <NavLink style={{ color: "red" }} exact to="/forgotpassword">
                    {" "}
                    Forget Password?
                  </NavLink>
                </p>
              </div>
              {/* forgotpassword */}
            </div>
          </div>

          <div className="col-12" style={{ marginTop: "0px" }}>
            <p style={{ fontWeight: "550", color: "red" }}>
              {this.state.errFlag ? this.state.errMessage : ""}
            </p>

            <div className="signon-bottom-area">
              {/*   <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/terms-and-conditions'>  Terms & Conditions</NavLink></p>
               */}
              <div
                className="button-loaader-area-cutomer-signup"
                style={{ marginTop: "5px" }}
              >
                <button
                  style={{ cursor: "pointer", borderRadius: "12px" }}
                  type="submit"
                >
                  Sign In
                </button>
                {this.state.loaderFlag ? (
                  <div style={{ marginLeft: "10px" }} className="loader"></div>
                ) : (
                  ""
                )}
              </div>

              <img
                style={{ cursor: "pointer", marginTop: "5px", width: "260px" }}
                src={require("../../images/signIn/PASS-01.png")}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default compose(
  withReducer("CustomerSignIN", reducer),
  connect(),
  withRouter
)(FormSignIn);
