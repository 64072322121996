import React, { Component } from "react";
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Form,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import Joi from 'joi-browser';
  import { toast } from 'react-toastify';
  import ReCAPTCHA from "react-google-recaptcha";
 
const recaptchaRef = React.createRef();
class Complain extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        show: false,
          name: '',
          phone: '',
          email: '',
          subject:'',
          address: '',
          description: '',
          error: {},
          captcha:null,
          buttonFlag:true,
          sentFlag:false
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  componentWillMount(){

  }
  componentDidMount(){

  }
  schema = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "string.email":
                    err.message = 'email mast be A Valid Email';
                    break;
                default:
                    err.message = 'email can not be empity';
                    break;
            }
        });
        return errors;
    }),
    phone: Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'phone can not be Empity';
                    break;
            }
        });
        return errors;
    }),
    name: Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'name can not be Empity';
                    break;
            }
        });
        return errors;
    }),
    description: Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'description can not be Empity';
                    break;
            }
        });
        return errors;
    }),
    subject: Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'subject can not be Empity';
                    break;
            }
        });
        return errors;
    })
    ,
    address: Joi.string(),
}
changeHandler = event => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
        error[event.target.name] = errorMassage;
    } else {
        delete error[event.target.name];
    }
    this.setState({
        [event.target.name]: event.target.value,
        error
    })
};
handleChange = (value) => {
    this.setState({
        country: value
    })
};

validationProperty = event => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] }
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null
};

validate = () => {
    const options = { abortEarly: false }
    const form = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject:this.state.subject,
        address:this.state.address,
        description: this.state.description,
    }
    const { error } = Joi.validate(form, this.schema, options)
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message
    return errors;
};
/* handleString=(name,phone,email,address,description)=>{
return ({`<div >
    <h2>From: ${name}</h2>
    <p>${description}</p>
    <br />
    <p>Phone: ${phone}</p>
<p>Email:${email}</p>
<p>Address:${address}</p>
</div>`})
} */
submitHandler = event => {
    event.preventDefault()
    const error = this.validate();
    if (error) {
        this.setState({
            error: error || {}
        })
    } else {
      /*   var emailBody=this.handleString(this.state.name,this.state.phone,this.state.email,this.state.address,this.state.description)
       */ let Obj={
           to:'info@legalemirates.com',
           emailBody:`<div >
           <h3>From: ${this.state.name}</h3>
           <p>${this.state.description}</p>
           <br />
           <p>Sender Detail:
           <p>Phone: ${this.state.phone}</p>
       <p>Email:${this.state.email}</p>
       <p>Address:${this.state.address}</p>
           </p>
           
       </div>`,
           subject:this.state.subject
       }
       fetch('https://api-legalemirates.wowets.com/api/v1/mailSender/sendMail', {
        method: 'POST',
        body: JSON.stringify(Obj),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        }
    })
    .then(response => response.json())
    .then(json => {
        this.setState({sentFlag:true})
        console.log(json);
        setTimeout(()=>{
            this.setState({
                name: '',
                phone: '',
                email: '',
                address: '',
                description: '',
                subject:''
            })
        },2000)
    })
    .catch(err => {console.error(err)
    alert("Something went wrong Please Again letter")
    });


    
       
        console.log(
            'Name' + '=' + this.state.name,
            'Phone' + '=' + this.state.phone,
            'Email' + '=' + this.state.email,
            'Address' + '=' + this.state.address,
            'Description' + '=' + this.state.description,
        )
        /* toast.success('Please check Consol log') */
    }
}
onChangeCaptha=(value)=>{
    if(value===null){
      this.setState({captcha:null,buttonFlag:true})
    }
    else{
      this.setState({captcha:value,buttonFlag:false})
    }
  }
  handleSent=()=>{
      this.setState({sentFlag:false})
  }
  render() {
   
    return (
      <div className="home-page-margin-top15">
        <div onClick={this.handleShow} className="livemeeting text-right">
                            <a style={{fontSize:'11px',cursor:'pointer'}}  >Suggestion/Complaint</a>
                            </div>
   {/*      <img title="Give Your suggestion here" onClick={this.handleShow}  style={{width:'35px',cursor:'pointer'}} src={require('../../images/about/ade-01.png')} />
       */}  {/*    <i onClick={this.handleShow} style={{cursor:'pointer',fontSize:'20px'}} title="Give Your suggestion here" className="fa fa-stack-exchange"></i>
      */}
        <Modal show={this.state.show} onHide={this.handleClose} size="md" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            >
         <img style={{width:'30px'}} src={require('../../images/about/ade-01.png')} />  Suggestion/Complaint
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.state.sentFlag ? 
              <div className="sent-message-area">
               <center>
                   <p><i class="fa fa fa-check-circle-o"></i></p>
                   <h2>thank you!</h2>
                   <p>Your submission has been received.</p>
                   <button className="sent-button-des" onClick={this.handleSent}>Ok</button>
               </center>
              </div>
              :
               <form onSubmit={this.submitHandler} className='contactForm'>
               <div className="row">
                   <div className="col-sm-6 col-12">
                       <div className="formInput">
                           <input
                               placeholder="Your Name"
                               value={this.state.name}
                               name="name"
                               style={{color:'black'}}
                               onChange={this.changeHandler}
                               className="form-control"
                               type="text" />
                           {this.state.error.name && <p>{this.state.error.name}</p>}
                       </div>

                   </div>
                   <div className="col-sm-6 col-12">
                       <div className="formInput">
                           <input
                               placeholder="Phone"
                               value={this.state.phone}
                               name="phone"
                               style={{color:'black'}}
                               onChange={this.changeHandler}
                               className="form-control"
                               type="phone" />
                           {this.state.error.phone && <p>{this.state.error.phone}</p>}
                       </div>
                   </div>
                   <div className="col-sm-6 col-12">
                       <div className="formInput">
                           <input
                               placeholder="Email"
                               value={this.state.email}
                               name="email"
                               style={{color:'black'}}
                               onChange={this.changeHandler}
                               className="form-control"
                               type="email" />
                           {this.state.error.email && <p>{this.state.error.email}</p>}
                       </div>
                   </div>
                   <div className="col-sm-6 col-12">
                       <div className="formInput">
                               <div className="formInput">
                                   <input
                                       placeholder="Address"
                                       value={this.state.address}
                                       name="address"
                                       style={{color:'black'}}
                                       onChange={this.changeHandler}
                                       className="form-control"
                                       type="address" />
                                       {this.state.error.address && <p>{this.state.error.address}</p>}
                               </div>

                       </div>
                   </div>
                    <div className="col-12">
                    <div className="formInput">
                           <input
                               placeholder="Subject"
                               value={this.state.subject}
                               name="subject"
                               style={{color:'black'}}
                               onChange={this.changeHandler}
                               className="form-control"
                               type="text" />
                           {this.state.error.subject && <p>{this.state.error.subject}</p>}
                       </div>

                  </div>
                   <div className="col-12">
                       <div className="formInput">
                           <textarea
                               className="form-control"
                               value={this.state.description}
                               style={{color:'black'}}
                               onChange={this.changeHandler}
                               placeholder="Suggestion/Complaint Description..."
                               name="description" />
                           {this.state.error.description && <p>{this.state.error.description}</p>}
                       </div>
                   </div>
                   <div className="col-12">
                       <div className="google-captcha-additionarea">
                       <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                       <button style={{marginTop:'10px'}} disabled={this.state.buttonFlag} type="submit">submit</button>
                       </div>
                      
                   </div>
               </div>
           </form>
            }
         
           
          </Modal.Body>
      
        </Modal>
      </div>
    );
  }
}
export default Complain;
