import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import "./style.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();
class Form extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: "",
      phone: "",
      email: "",
      address: "",
      subject: "",
      description: "",
      case: "",
      error: {},
      show: false,
      sentFlag: false,
      captcha: null,
      buttonFlag: true,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "must be a valid email";
              break;
            default:
              err.message = "must enter an email";
              break;
          }
        });
        return errors;
      }),
    phone: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a phone number";
              break;
          }
        });
        return errors;
      }),
    name: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a name";
              break;
          }
        });
        return errors;
      }),
    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a description";
              break;
          }
        });
        return errors;
      }),
    subject: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a subject";
              break;
          }
        });
        return errors;
      }),
    case: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a case type";
              break;
          }
        });
        return errors;
      }),
    address: Joi.string(),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      phone: this.state.phone,
      case: this.state.case,
      description: this.state.description,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      /*  info@legalemirates.com  var emailBody=this.handleString(this.state.name,this.state.phone,this.state.email,this.state.address,this.state.description)
       */ let Obj = {
        to: "info@legalemirates.com",
        emailBody: `<div >
           <h3>From: ${this.state.name}</h3>
           <h5>Case Type:${this.state.case}</h5>
           <p>${this.state.description}</p>
           <br />
           <p>Sender Detail:
           <p>Phone: ${this.state.phone}</p>
       <p>Email:${this.state.email}</p>
       
           </p>
           
       </div>`,
        subject: this.state.subject,
      };
      fetch("https://api-legalemirates.wowets.com/api/v1/mailSender/sendMail", {
        method: "POST",
        body: JSON.stringify(Obj),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({ show: true });
          console.log(json);
          setTimeout(() => {
            this.setState({
              name: "",
              phone: "",
              email: "",
              case: "",
              description: "",
              subject: "",
            });
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          alert("Something went wrong Please Again letter");
        });

      console.log(
        "Name" + "=" + this.state.name,
        "Phone" + "=" + this.state.phone,
        "Email" + "=" + this.state.email,
        "Address" + "=" + this.state.address,
        "Description" + "=" + this.state.description
      );
      /* toast.success('Please check Consol log') */
    }
  };
  onChangeCaptha = (value) => {
    if (value === null) {
      this.setState({ captcha: null, buttonFlag: true });
    } else {
      this.setState({ captcha: value, buttonFlag: false });
    }
  };
  handleSent = () => {
    this.setState({ show: false });
  };

  render() {
    const options = [
      { level: "Case Type", value: "Case Type" },
      { level: "Criminal Cases", value: "Criminal Cases" },
      { level: "Corporate Law", value: "Corporate Law" },
      { level: "Arbitration", value: "Arbitration" },
      {
        level: "International/ Interpol Cases",
        value: "International/ Interpol Cases",
      },
      { level: "Financial Crime", value: "Financial Crime" },
      { level: "Family business", value: "Family business" },
      { level: "Commercial", value: "Commercial" },
      { level: "Banking", value: "Banking" },
      {
        level: "Construction & Infrastructure",
        value: "Construction & Infrastructure",
      },
      { level: "Competition", value: "Competition" },
      {
        level: "Data Privacy & Cybersecurity",
        value: "Data Privacy & Cybersecurity",
      },
      { level: "Intellectual Property", value: "Intellectual Property" },
      { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
      { level: "Private Notary", value: "Private Notary" },
      { level: "Insurance", value: "Insurance" },
      { level: "Capital Market", value: "Capital Market" },
      { level: "Private Client Services", value: "Private Client Services" },
      { level: "Family Law", value: "Family Law" },
      { level: "Business Law", value: "Business Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Injury Law", value: "Injury Law" },
      { level: "Educational Law", value: "Educational Law" },
      { level: "VAT Law", value: "VAT Law" },
      { level: "Tax Law Advisory", value: "Tax Law Advisory" },
      { level: "International Tax Law", value: "International Tax Law" },
      { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
      { level: "Chemical", value: "Chemical" },
      { level: "Aviation", value: "Aviation" },
      { level: "Consumer Product", value: "Consumer Product" },
      { level: "Financial institution", value: "Financial institution" },
      {
        level: "Manufacture and Industrial",
        value: "Manufacture and Industrial",
      },
      { level: "Maritime & Shipping", value: "Maritime & Shipping" },
      { level: "Media", value: "Media" },
      { level: "Mining and Metals", value: "Mining and Metals" },
      { level: "Oil and Gas", value: "Oil and Gas" },
      {
        level: "Pharmaceuticals and Healthcare",
        value: "Pharmaceuticals and Healthcare",
      },
      { level: "Power", value: "Power" },
      { level: "Private Equity", value: "Private Equity" },
      { level: "Sovereigns", value: "Sovereigns" },
      { level: "Telecommunication", value: "Telecommunication" },
      { level: "Space Laws", value: "Space Laws" },
      { level: "Establishing Business", value: "Establishing Business" },
      { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
      { level: "Drug Law", value: "Drug Law" },
      { level: "Multinational Disputes", value: "Multinational Disputes" },
      { level: "Labor Law", value: "Labor Law" },
    ];

    return (
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Your Name"
                value={this.state.name}
                name="name"
                onChange={this.changeHandler}
                className="form-control"
                type="text"
              />
              {this.state.error.name && <p>{this.state.error.name}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Phone"
                value={this.state.phone}
                name="phone"
                onChange={this.changeHandler}
                className="form-control"
                type="phone"
              />
              {this.state.error.phone && <p>{this.state.error.phone}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Email"
                value={this.state.email}
                name="email"
                onChange={this.changeHandler}
                className="form-control"
                type="email"
              />
              {this.state.error.email && <p>{this.state.error.email}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <select
                value={this.state.case}
                className="form-control"
                onChange={this.changeHandler}
                name="case"
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.level}
                  </option>
                ))}
              </select>
              {this.state.error.case && <p>{this.state.error.case}</p>}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <input
                placeholder="Subject"
                value={this.state.subject}
                name="subject"
                onChange={this.changeHandler}
                className="form-control"
                type="text"
              />
              {this.state.error.subject && <p>{this.state.error.subject}</p>}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <textarea
                className="form-control"
                value={this.state.description}
                onChange={this.changeHandler}
                placeholder="Case Description..."
                name="description"
              />
              {this.state.error.description && (
                <p>{this.state.error.description}</p>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="google-captcha-additionarea">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                onChange={this.onChangeCaptha}
              />
              <button
                style={{ marginTop: "10px" }}
                disabled={this.state.buttonFlag}
                type="submit"
              >
                Appointment
              </button>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="heading-modal">
              <img
                style={{ width: "30px" }}
                src={require("../../images/about/ade-01.png")}
              />{" "}
              Appointment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sent-message-area">
              <center>
                <p>
                  <i class="fa fa fa-check-circle-o"></i>
                </p>
                <h2>thank you!</h2>
                <p>Your submission has been received.</p>
                <button className="sent-button-des" onClick={this.handleSent}>
                  Ok
                </button>
              </center>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    );
  }
}
export default Form;
