import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo2.png";
import "./style.scss";
import barCode from "../../images/address.jpg";
import Clock from "react-live-clock";

/* terms-and-conditions official-links-Law-portals */
const footerLinks = [
  {
    title: "Quick Link",
    menus: [
      { name: "Our Services", route: "practice", scroll: 0 },
      { name: "Legal Team", route: "team", scroll: 0 },
      { name: "Specialist and Experts", route: "attorneys", scroll: 0 },
      { name: "Recent Case", route: "case-studies", scroll: 0 },
      { name: "News Room", route: "news", scroll: 0 },
      { name: "Our Blog", route: "blogs", scroll: 0 },
      { name: "Terms & Conditions", route: "terms-and-conditions", scroll: 0 },
      { name: "Privacy Policy", route: "privacy-policy", scroll: 0 },
      {
        name: "Official Links &  Law Portals",
        route: "official-links-Law-portals",
        scroll: 0,
      },
    ],
  },
  {
    title: "Services Area",
    menus: [
      { name: "Criminal Law ", route: "speciality-criminal", scroll: 1000 },
      {
        name: "Business and corporate cases",
        route: "speciality-business",
        scroll: 1000,
      },
      {
        name: "International cases &Interpol",
        route: "speciality-interpol",
        scroll: 1000,
      },
      { name: "Labor Law", route: "speciality-labor", scroll: 1000 },
      {
        name: "Dispute & financial claims",
        route: "speciality-financial",
        scroll: 1000,
      },
      { name: "Family Law", route: "speciality-family", scroll: 1000 },
      {
        name: "Arbitration Law",
        route: "speciality-arbitration",
        scroll: 1000,
      } /*
            { name: 'Corporate Services', route: 'practice' },
            { name: 'Criminal ', route: 'practice' },
             { name: 'Data Privacy & Cybercrimes', route: 'practice' },
            { name: 'International Crimes', route: 'practice' },
            { name: 'Merger & Acquisitions', route: 'practice' },
            { name: 'Corporate Structuring', route: 'practice' },
            { name: 'Employment & Compensation', route: 'practice' },
            { name: 'Family Business', route: 'practice' },
            { name: 'Financial Crimes ', route: 'practice' },
            { name: 'Insolvency', route: 'practice' },
            { name: 'Insurance', route: 'practice' },
            { name: 'Intellectual Property', route: 'practice' }  */,
    ],
  },
  {
    title: "Contact Us",
    menus: [
      { name: "Office Address" },
      {
        name: "Legal Emirates LLC Sharjah Media City Sharjah, UAE",
        href: "https://goo.gl/maps/zp6YtBUNvCHKJmzW8",
      },
      // {
      //   name: "Office No. 3403 - 34th Floor - Burj Al Salam Tower - Trade Centre 1 -  Sheikh Zayed Road  - Dubai - United Arab Emirates - P.O. Box No. 89498.",
      //   href: "https://g.page/r/CWT_mAPfa4MLEBA",
      // },
      // { name: " United Arab Emirates." },
      // { name: "Phone: +9714 2555111", href: "tel:+97142555111" },
      // { name: "Fax: +9714 2368888", href: "fax:+97142368888" },
      {
        name: "Email: info@legalemirates.com",
        href: "mailto:info@legalemirates.com",
      },
      { name: "" },
      // { name: "", image: barCode },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footerLogo">
                <Link to="/">
                  <center>
                    {" "}
                    <img src={logo} style={{ width: "100%" }} alt="" />
                  </center>
                </Link>
                {/*      <p>We guarantee enduring legal advisors, pre-eminent services and your requisite work done. What else do you need? </p>
                 */}{" "}
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-12">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.name === "" ? (
                          <p style={{ color: "white" }}>
                            Sharjah Time:{" "}
                            <Clock
                              format={" h:mm:ss a, dddd, MMMM Do YYYY"}
                              timezone={"Asia/Dubai"}
                            />
                          </p>
                        ) : (
                          ""
                        )}
                        {item.route ? (
                          <Link
                            onClick={() => window.scrollTo(0, item.scroll)}
                            to={`/${item.route}`}
                          >
                            {item.name}
                          </Link>
                        ) : item.href ? (
                          <a href={item.href}>{item.name}</a>
                        ) : (
                          `${item.name}`
                        )}
                        {item.name === "" ? (
                          <p style={{ color: "white" }}>
                            <br />
                            <img style={{ width: "100%" }} src={item.image} />
                          </p>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                    {/*          <li><a href="tel:+97142555111">Phone: +9714 2555111</a></li>
                                        <li><a href="fax:+97142368888">Fax: +9714 2368888</a></li>
                                        <li><a href="mailto:legalemirates@outlook.com">Email: legalemirates@outlook.com</a></li> */}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>
                  Privacy Policy | © {new Date().getFullYear()}{" "}
                  legalemirates.com All rights reserved
                </span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a
                      href="https://www.facebook.com/Legal-Emirates-Bader-Al-Zaabi-111584597371288"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/LegalAlZaabi" target="_blank">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/legalalzaabi/"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/legalalzaabi/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/LegalAlZaabi/"
                      target="_blank"
                    >
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                  </li>
                  {/*      <li><a href="https://www.pinterest.com/wowets2020/" target='_blank'><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>*/}
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCsBvfRBxFrgBTezFu3d0KSQ/"
                      target="_blank"
                    >
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
