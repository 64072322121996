import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const InsuranceContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>	Insurance Cases    </h3>
             <p>The Law Firm operates a specialist Insurance Practice to meet all the needs of the insurance industry. Major regional and international insurers and reinsurers, brokers, and third-party administration all can take benefit from our decades of experience. </p>
             <p>We guide you on all types of insurance protection and claims. We have been involved in the formation of different insurance companies in the UAE whether a multinational or a small firm. 
Our Proficiencies 
</p>
          <ul style={{color:'#666666'}}>
               <li>
               •	Subrogation process
               </li>
               <li>•	Advising in health insurances and regulations in foreign insurers</li>
               <li>•	Setting up an insurance business in the UAE and regarding consultations</li>
               <li>•	Advising on insurance requirements and claims  </li>
               <li>•	Matters of personal injury claims  </li>
               <li>•	Arbitration and litigation matters related to insurance </li>
           </ul>
          {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default InsuranceContent