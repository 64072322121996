import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../Title'
import './style.scss'

// images
import baderpic1 from '../../images/founder/baderpic1.png'
import baderpic2 from '../../images/founder/baderpic2.png'
import baderpic3 from '../../images/founder/baderpic3.png'
import baderpic4 from '../../images/founder/baderpic4.png'
import baderpic5 from '../../images/founder/baderpic5.png'
import baderpic6 from '../../images/founder/baderpic6.png'
import baderpic7 from '../../images/founder/baderpic7.png'
import baderpic8 from '../../images/founder/baderpic8.png'
import baderpic9 from '../../images/founder/baderpic9.png'
import baderpic10 from '../../images/founder/baderpic10.png'
import baderpic11 from '../../images/founder/baderpic11.png'
import baderpic12 from '../../images/founder/baderpic12.png'

const teams = [

  
   /*  {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic4, 
    }, */
   /*  {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic5, 
    }, */
    {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic6, 
    },
/*     {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic7, 
    },
    {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic8, 
    }, */
    /* {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic9, 
    }, */
    {
        name: 'Bader Al Zaabi', level: 'The Founder ', image: baderpic2, 
    },
    {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic11, 
    },
  /*   {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic12, 
    }, */
    {
        name: 'Bader Al Zaabi', level: 'In Office ', image: baderpic1, 
    },
  /*   {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic10, 
    }, */
   
    {
        name: 'Bader Al Zaabi', level: 'The Founder', image: baderpic3, 
    },
            
]

const ImageGallery = ({ className, title, subTitle, slider, noGutters }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className={'row'}>
                    <div style={{height:'auto'}} className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
            
                            <Fragment>
                                {teams.map((team, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-12">
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img title={team.name} style={{width:'100%'}} alt={team.name} src={team.image} />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span style={{paddingBottom:'15px'}}>{team.level}</span>
                                            
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                  
                </div>
            </div>
        </div>
    )
}
export default ImageGallery