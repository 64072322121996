
import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import CetagorySidebar from '../../../components/CetagorySidebar'
import FooterArea from '../../../components/FooterArea'
import BannerSidebar from '../../../components/BannerSidebar'
import FamilyBusinessContent from '../../../components/SingleContentArea/familyBusinessContent'
import PricingTable from "../../../components/PricingTable";
import {Helmet} from "react-helmet";
// images
import breadcumb from '../../../images/breadcumb/Practice-Single-Area.jpg'
import banner from '../../../images/practice/2.jpg'
import single from '../../../images/practice/Family-business-Cases.jpg'
import BreakingNews from '../../../components/breaking'
import './style.scss'


const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Services', route: '/practice' },
    { name: 'Services area details' }
]



const FamilyBusiness = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Family Business Cases - Legal Emirates  </title>
                <link rel="canonical" href="https://legalemirates.com/speciality-family-business " />
                <meta name="description" content="Legal Emirates team will structure the binding agreements and will defend your family assets from every type of risk and will advise on dividing businesses justly." />
            </Helmet>

            <header className="headerArea">
          {/*   <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Services Area"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <aside className=" pr25">
                                <BannerSidebar
                                    image={banner}
                                    className="bannerWrapper"
                                />
                                <CetagorySidebar
                                    title="Category"
                                    className="cetagoryWrap"
                                />
                            </aside>
                        </div>
                        <div className="col-lg-8">
                            <FamilyBusinessContent
                                className="singleContentArea"
                                image={single}
                                avatar={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PricingTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            />
         {/*    <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default FamilyBusiness