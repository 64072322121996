import React, { Component } from 'react'
import Joi from 'joi-browser'
import { toast } from 'react-toastify';
import { NavLink, Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../Form/style.scss';
import './style.scss'

import './formStyle.scss'
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import './switch.css'
class PaymentFormInvoice extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        cardType:'',
        cardDate:'',
        cardNumber:'',
        CVC:'',
        holderName:'',
        totalAmount:'',
        VAT:'',
        error: {},
        type:'password',
        passFlag:false,
        date88:'text',
        date99:'text',
        show: false,
        termsFlag:true,
    } 
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
    schema = {
      
        cardType:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'select the card type first';
                        break;
                }
            });
            return errors;
        }),
        cardNumber:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Enter the card number first';
                        break;
                }
            });
            return errors;
        }),
        cardDate:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'must select the card expiry date';
                        break;
                }
            });
            return errors;
        }),
        holderName:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'card holder name can not be empty';
                        break;
                }
            });
            return errors;
        }),
        VAT:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'VAT Fee can not be empty';
                        break;
                }
            });
            return errors;
        }),
        totalAmount:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'total Amount can not be empty';
                        break;
                }
            });
            return errors;
        }),
        CVC:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'CVC Number can not be empty';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            cardType:this.state.cardType,
            cardDate:this.state.cardDate,
            cardNumber:this.state.cardNumber,
            CVC:this.state.CVC,
            holderName:this.state.holderName,
            totalAmount:this.state.totalAmount,
            VAT:this.state.VAT
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
          
            this.setState({
               show:true,
                cardType:'',
                cardDate:'',
                cardNumber:'',
                CVC:'',
                holderName:'',
                totalAmount:'',
                VAT:'',
        })
       
    }
}
    
    handleSent=()=>{
        this.setState({show:false})
    }
  
   
        handleConditions=()=>{
            if(this.state.termsFlag){
                this.setState({termsFlag:false})
            }else{
              this.setState({termsFlag:true})
            }
          }
    render() {
const options1=[
    { level: 'Select a Card* ', value: '' },
    { level: 'VISA Card', value: 'VISA Card' },
    { level: 'Master Card', value: 'Master Card' },
]

       
        return (
            <form onSubmit={this.submitHandler} className='counsellingForm'>
                <div className="row">
                    
                   
                         <div className="col-12 col-lg-6">
                        <div className="formInput">
                         
                                    <select
                                        value={this.state.cardType} 
                                        className="form-control"
                                         onChange={this.changeHandler} 
                                        name="cardType">
                                        {options1.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.cardType && <p>{this.state.error.cardType}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="formInput">
                         
                            <input
                                placeholder="Card NUmber**"
                                value={this.state.cardNumber}
                                name="cardNumber"
                              onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.cardNumber && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.cardNumber}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                    
                    <div className="formInput">
                    <input
                                placeholder="Card Expiry date"
                                name="cardDate"
                                onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              onFocus={()=>this.setState({date99:'date'})}
                                type={this.state.date99} />
                
                    {this.state.error.cardDate && <p style={{color:'red',textTransform:'uppercase',fontSize:'12px'}}>{this.state.error.cardDate}</p>}
                    </div>
            
                  </div>
                  <div className="col-12 col-lg-6">
                        <div className="formInput">
                         
                            <input
                                placeholder="CVC Number"
                                name="CVC"
                                value={this.state.CVC}
                                onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.CVC && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.CVC}</p>}
                        </div>
                    </div>
                  <div className="col-12">
                        <div className="formInput">
                         
                            <input
                                placeholder="Card Holder Name*"
                                name="holderName"
                                value={this.state.holderName}
                                 onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.holderName && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.holderName}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="formInput form-control field-design99">
                           <div style={{marginTop:'2px'}}>Open a file Fee</div>
                       <label className="switch99"><input type="checkbox" id="togBtn" /><div className="slider99 round99"><span className="on99">Yes</span><span className="off99">No</span></div></label>

                        </div>
                    </div>
                    <div className="col-12">
                        <div className="formInput">
                         
                            <input
                                placeholder="VAT @ 5%"
                                name="VAT"
                                value={this.state.VAT}
                                 onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.VAT && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.VAT}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="formInput">
                         
                            <input
                                placeholder="Total Amount"
                                name="totalAmount"
                                value={this.state.totalAmount}
                                 onChange={this.changeHandler} 
                               /*  id="emailField" */
                                className="form-control"
                              
                                type="text" />

                            {this.state.error.totalAmount && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.totalAmount}</p>}
                        </div>
                    </div>
                     <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area">
                       <div >
                        <div className="checkbow-style-area12" style={{color:'#666666'}}><span><input  onClick={this.handleConditions} className="checkbow-style12" type="checkbox" /></span> <span style={{fontSize:'15px',marginLeft:'10px'}}>By Creating an account you agree to</span></div>   
                       <p style={{color:'#666666',textDecoration:'underline',marginLeft:'30px'}}><NavLink style={{color:'#666666',textDecoration:'underline'}} exact to='/terms-and-conditions'>  Terms & Conditions</NavLink></p>    
                       
                       </div>
                        <button disabled={this.state.termsFlag} type="submit">Submit</button>
                        </div>
                       
                    </div>

                </div>
                <Modal show={this.state.show} onHide={this.handleClose} size="md" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            >
         <img style={{width:'30px'}} src={require('../../../images/about/man-01.png')} /> Corporate File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              <div className="sent-message-area">
               <center>
                   <p><i class="fa fa fa-check-circle-o"></i></p>
                   <h2>thank you!</h2>
                   <p>Your submission has been received.</p>
                   <button className="sent-button-des" onClick={this.handleSent}><Link style={{color:'white'}} to="profile" >OK</Link></button>
               </center>
              </div>
           
          </Modal.Body>
      
        </Modal>
            </form>
        )
    }
}
export default PaymentFormInvoice