import React, { Component } from 'react'

import { NavLink, Link,withRouter } from 'react-router-dom'
import './style.scss';
import './style.css';
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
    class CustomerEmailVerification extends Component{
        constructor(props, context) {
            super(props, context);
            this.state = {
                success:false,
                failure:false,
                verified:true,
                notVerified:true,
                msg:'The link has been Expired!'
        } 
     
       /*  store.subscribe(() => {
            let resNumber1 = store.getState().CustomerEmailVerifi.customerEmailVerifyReducer;
            console.log(resNumber1,"dddddddddddddddddd")
            if (!resNumber1.isLoading) {
              if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
              if(this.state.verified){
               this.setState({success:true,verified:false})
              }
              } else {
                if(this.state.notVerified){
                this.setState({failure:true,notVerified:false,msg:resNumber1.errMsg})
                }
            
              }
            }
          }); */
          let string=window.location.href.split('/');

        /*   console.log(string[string.length-1]) */
     let obj={
              userId:string[string.length-1]
          }
          this.props.dispatch(Actions.customerEmailVerify(obj))  
        
           
      }
      componentDidMount(){
      
        }
        componentWillReceiveProps(nextProps){
            let response=nextProps.customerEmailVerifyReducer;
                if (!response.isLoading) {
              if (response.errMsg == null && !(response.data===undefined)) {
              if(this.state.verified){
               this.setState({success:true,verified:false})
              }
              } else {
                if(this.state.notVerified){
                this.setState({failure:true,notVerified:false,msg:response.errMsg})
                }
            
              }
            }
        }
      /*   componentDidUpdate(nextProps){
            console.log(nextProps.customerEmailVerifyReducer,"ccccccccc") 
        }  */
      /*   static getDerivedStateFromProps(nextProps, prevState) { 
            console.log(prevState,"eeeeeeee")
            console.log(nextProps.customerEmailVerifyReducer,"ffffffffffffff")
        } */
        render() {
      
            return (
        <div className="SignUpArea">
            <div className="container">
                <div className="row">
                <div className="col-12 col-lg-3">
                   </div> 
                    <div className="col-12 col-lg-6">
                    
                     {this.state.success?
                         <div className="email-verify-online-area">
                         {/*  <emailVerifyCus /> */}
                          <div className="signup-verify-email-popup" >
            <div className="signup-verify-email-popup-top-area">
                <center>
                <img src={require('../../images/signIn/emailverify.png')} />
                </center>
             </div>    
             <div className="signup-verify-email-popup-bottom-area">
         <center>
         <h3 style={{color:'white'}} >Email Have been Verified!</h3>  
              <p style={{color:'white'}}>Your account is successfully activated. Please Sign in into your Account .</p>
         </center>
              <hr />
<center>   <button className="button-verify-email-address" style={{color:'white'}} onClick={()=>this.props.history.push('/signin')} >OK</button>
          </center>
              </div>

            </div>
                     </div>
                    :""
                     }  
                 {this.state.failure?    
                     <div className="email-verify-online-area">
                {/*  <emailVerifyCus /> */}
                 <div className="signup-verify-email-popup" style={{marginTop:'40px'}}>
            <div className="signup-verify-email-popup-top-area">
                <center>
                <img src={require('../../images/signIn/04-01.png')} />
                </center>
             </div>    
             <div className="signup-verify-email-popup-bottom-area">
         <center>
                 <h3 style={{color:'white'}} >{this.state.msg}</h3>  
              <p style={{color:'white'}}> Your Link is Expired please try again later.</p>
         </center>
              <hr />
<center>   <button className="button-verify-email-address" style={{color:'white'}} onClick={()=>this.props.history.push('/signin')} >OK</button>
          </center>
              </div>

            </div>
                     </div>
                     :''
                       }
                    </div>
              
                    <div className="col-12 col-lg-3">
                   </div> 
                    
                </div>
               
            </div>
        </div>
       
       )
    }
}
 const mapStateToProps=(state)=> {
    return {
        customerEmailVerifyReducer: state.CustomerEmailVerifi.customerEmailVerifyReducer
    };
  }
export default  compose(withReducer("CustomerEmailVerifi", reducer),connect(mapStateToProps),withRouter) 
  (CustomerEmailVerification); 