import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col,Button
} from "react-bootstrap";
import $ from "jquery";
import './style.css'
import * as Actions from "./store/actions";
import { compose } from "redux";
import '../Form/style.scss';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
class BreakingNews extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      class:"marquee-content",
      class1:"item-collection-1",
      authFlag:true,
      adminAuth:TextTrackCueList
    };
    this.toastId=React.createRef(null);
    store.subscribe(() => {
     
      let resNumber1 = store.getState().BreakingNews1.authRiderReducer;
      if(resNumber1.isAuthenticated){
          this.setState({authFlag:true})
  }else{
    this.setState({authFlag:true})
  }
    
  let resNumber2 = store.getState().BreakingNews1.AdminAuthRiderReducer;
  if(resNumber2.isAuthenticated){
      this.setState({adminAuth:true})
}else{
this.setState({adminAuth:true})
}
    });

    /* AdminAuthRiderReducer */

  }
  componentWillMount(){
 /*    window.setInterval(()=>{
      if(localStorage.hasOwnProperty('language')){
        if (localStorage.language == "Arabic"){
        this.setState({class:"marquee-content1",class1:"item-collection-11"})
        }else{
          this.setState({class:"marquee-content",class1:"item-collection-1"})
        }
      }

    
 },100); */
  
  }
  componentDidMount(){
   /*  $('.goog-te-combo').on('change',function(){
      language = $("select.goog-te-combo option:selected").text();
       alert(language);
    }) */
    //works IE/SAFARI/CHROME/FF
  }
  handleColor=()=>{
    this.setState({class:"my-first-class-name"})
  }
 
  handleAlert=()=>{
    if(! toast.isActive(this.toastId.current)) {
      this.toastId.current =toast.error('Signup first to get access to our premium services', {
      position: "top-right",
      toastId:'logined',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    }
  }
  render() {
   
    return (
      <div>
        {this.state.authFlag || this.state.adminAuth ?
                <div className="breaking-news-class">
           <div className="marquee15">
           <div class={this.state.class}>
            <p class={this.state.class1}>
              <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
              
                
                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dc.gov.ae/PublicServices/SessionAgenda.aspx?lang=ar" target="_blank">Schedules of all cases' hearings </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>


<p> <a style={{color:'white',textDecoration:''}}  href="https://translate.google.com/" target="_blank">Google Translate </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

<p> <a style={{color:'white',textDecoration:''}}  href="https://amer.gdrfad.gov.ae/visa-inquiry" target="_blank">DUBAI VISA INQUIRY </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>




                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.makani.ae/desktop/?8" target="_blank">Makani  </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>




<p> <a style={{color:'white',textDecoration:''}}  href="https://smartservices.ica.gov.ae/echannels/web/client/default.html#/applicationTracking" target="_blank">Application Status </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
            

                <p> <a style={{color:'white',textDecoration:''}}  href="https://pp.gov.ae" target="_blank">Public Prosecution
judiciary system </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
               
                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.who.int/" target="_blank"> World Health Organization Latest News  </a></p>
            
                   
                   
                       <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                   
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/goodconductcertificate?firstView=true" target="_blank"> Get a Police Clearance Certificate  </a></p>
                 
               
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/finesinquiry?firstView=true" target="_blank"> Inquire and Pay your Traffic Fines </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.interpol.int/en/Contacts/Contact-INTERPOL#" target="_blank">Contact INTERPOL  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="https://es.adpolice.gov.ae/trafficservices/registration/Register.aspx?Culture=en" target="_blank">Abu Dhabi Police e-Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/financialcircularpayment?firstView=true" target="_blank">Dubai Police Criminal Status of Financial Cases  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dubaipolice.gov.ae/wps/portal/home/services/individualservices/cybercrimeService?firstView=true" target="_blank">Dubai Police e-Crime   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="Dubai Police Services" target="_blank">Dubai Police Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="https://pp.gov.ae/webcenter/portal/PPServices/page155?centerWidth=100%25&leftWidth=0%25&rightWidth=0%25&showFooter=false&showHeader=false&_adf.ctrl-state=iaeywe3gm_1&_afrLoop=8129451216078221#!%40%40%3FshowFooter%3Dfalse%26_afrLoop%3D8129451216078221%26leftWidth%3D0%2525%26showHeader%3Dfalse%26rightWidth%3D0%2525%26centerWidth%3D100%2525%26_adf.ctrl-state%3Diaeywe3gm_5" target="_blank">Smart Guest Visit   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dc.gov.ae/PublicServices/InquiryByCaseNumber.aspx?lang=en" target="_blank">Dubai Courts Case Inquiry  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                

                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dc.gov.ae/PublicServices/Home.aspx" target="_blank">Dubai Courts Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  href="https://sheikhmohammed.ae/en-us" target="_blank">Sheikh Mohammed Bin Rashid Al Maktoum  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.smartdubai.ae/" target="_blank">Smart Dubai   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.uscourts.gov/" target="_blank"> United States Courts </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.icj-cij.org/en" target="_blank"> International Court of Justice  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dxbpp.gov.ae/default.aspx?src=301&id=909&Lang=en" target="_blank">Govt of Dubai Public Prosecution   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.moj.gov.ae/en/home.aspx" target="_blank">UAE MINISTRY OF JUSTICE   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.moj.gov.ae/en/services.aspx" target="_blank"> UAE MINISTRY OF JUSTICE e-Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.adjd.gov.ae/sites/eServices/EN/Pages/CaseInquiry.aspx" target="_blank">UAE JUDICIAL DEPARTMENT Case Inquiry   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.adjd.gov.ae/en/pages/home.aspx" target="_blank"> UAE JUDICIAL DEPARTMENT  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="http://www.courts.rak.ae/en/Pages/default.aspx" target="_blank"> Govt. Ras Al Khaimah Courts Department  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://grpportal.rak.ae/irj/portal/court_civil_inquiry/anonymous?guest_user=anon01_en" target="_blank"> Govt. Ras Al Khaimah Case Inquiry  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://es.adpolice.gov.ae/trafficservices/finespublic/inquiry.aspx" target="_blank">Abu Dhabi Traffic Fines Inquiry   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.cia.gov/index.html" target="_blank"> CIA  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.un.org/en/" target="_blank"> UNITED NATIONS  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.mohap.gov.ae/en/Pages/default.aspx" target="_blank"> UAE MINISTRY OF HEALTH & PREVENTION  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.dubaiairports.ae/home-page" target="_blank"> Dubai Airports  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://gdrfad.gov.ae/en" target="_blank">Federal Authority for Identity & Citizenship – DUBAI   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.uscis.gov/" target="_blank"> U.S. Citizenship & Immigration Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://www.gov.uk/browse/citizenship/citizenship" target="_blank"> Citizenship & Living in the UK  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  href="https://u.ae/en/help/contact-us/the-government" target="_blank"> UAE Government Entities  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  href="https://www.adjd.gov.ae/en/pages/eservicedirectory.aspx" target="_blank"> UAE JUDICIAL DEPARTMENT – Digital Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.ica.gov.ae/en/services/e-services/id-card-status.aspx" target="_blank"> Federal Authority for Identity & Citizenship – UAE   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://smartpass.government.ae/index" target="_blank">  Smart Pass  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.smartdubai.ae/apps-services/details/uae-pass" target="_blank"> UAE PASS  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.rak.ae/wps/portal/rak/home" target="_blank"> Ras Al Khaimah Official Portal   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://u.ae/#/" target="_blank"> UAE Official Portal    </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://ejari.gov.ae/" target="_blank"> EJARI  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="http://www.makani.ae/desktop/index.html?7" target="_blank"> Makani   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.shjpolice.gov.ae/" target="_blank"> Government of Sharjah   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.ajmanpolice.gov.ae/portal/" target="_blank">  Ajman Police </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://fs.fujairahpolice.gov.ae/Default.aspx" target="_blank"> Fujairah Police GHQ   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="http://dubaided.ae/English/Pages/default.aspx" target="_blank"> Dubai Economy   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="https://www.tamm.abudhabi/en" target="_blank"> TAMM – Abu Dhabi Government Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  href="http://www.uaqpolice.gov.ae/" target="_blank"> Umm Al Quwain Police   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                
             </p>
               
          
              </div>
             </div>
             
              </div> 
              :
              
              
              /* Second */
              
              <div className="breaking-news-class">
           <div className="marquee15">
           <div class={this.state.class}>
            <p class={this.state.class1}>
              <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
              
                
                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}  href="javascript:void(0)" >Schedules of all cases' hearings </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>


<p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)" >Google Translate </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

<p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}  href="javascript:void(0)" >DUBAI VISA INQUIRY </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>




<p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">DUBAI VISA INQUIRY PORTAL </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
            

                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">Public Prosecution
judiciary system </a></p>
                <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
               
                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> World Health Organization Latest News  </a></p>
            
                   
                   
                       <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                   
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> Get a Police Clearance Certificate  </a></p>
                 
               
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Inquire and Pay your Traffic Fines </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)" >Contact INTERPOL  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">Abu Dhabi Police e-Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Dubai Police Criminal Status of Financial Cases  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Dubai Police e-Crime   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">Dubai Police Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Smart Guest Visit   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>

                <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">Dubai Courts Case Inquiry  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                

                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Dubai Courts Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Sheikh Mohammed Bin Rashid Al Maktoum  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Smart Dubai   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> United States Courts </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> International Court of Justice  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)">Govt of Dubai Public Prosecution   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">UAE MINISTRY OF JUSTICE   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> UAE MINISTRY OF JUSTICE e-Services  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">UAE JUDICIAL DEPARTMENT Case Inquiry   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> UAE JUDICIAL DEPARTMENT  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> Govt. Ras Al Khaimah Courts Department  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Govt. Ras Al Khaimah Case Inquiry  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Abu Dhabi Traffic Fines Inquiry   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> CIA  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> UNITED NATIONS  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> UAE MINISTRY OF HEALTH & PREVENTION  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Dubai Airports  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">Federal Authority for Identity & Citizenship – DUBAI   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> U.S. Citizenship & Immigration Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Citizenship & Living in the UK  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                  <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> UAE Government Entities  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> UAE JUDICIAL DEPARTMENT – Digital Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)" target="_blank"> Federal Authority for Identity & Citizenship – UAE   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">  Smart Pass  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> UAE PASS  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Ras Al Khaimah Official Portal   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> UAE Official Portal    </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> EJARI  </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Makani   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Government of Sharjah   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)">  Ajman Police </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> Fujairah Police GHQ   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Dubai Economy   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}} onClick={this.handleAlert}   href="javascript:void(0)"> TAMM – Abu Dhabi Government Services   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                 <p> <a style={{color:'white',textDecoration:''}}  onClick={this.handleAlert}   href="javascript:void(0)"> Umm Al Quwain Police   </a></p>
                  <p style={{paddingLeft:'40px',paddingRight:'40px'}}><img
                  src={require("../../images/logo/logo2.png")}
                  alt="First slide"
                  style={{width:'40px'}}
                /></p>
                
             </p>
               
          
              </div>
             </div>
             
        </div> }
          
      </div>
    );
  }
}
 
export default compose(
  withReducer("BreakingNews1", reducer),
  connect()
)(BreakingNews);   