import React, { Component } from 'react'
import Joi from 'joi-browser'
import { toast } from 'react-toastify';
import { NavLink, Link } from 'react-router-dom'
import '../Form/style.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class FormCorporateSignUp1 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        email: '',
        password:'',
        error: {},
        type:'password',
        passFlag:false,
        AuthorizeName:'',
        number:'',
        pass1:'',
        pass2:'',
        address:'',
        CompanyName:'',
        vnumber:'',
        optionVlaue:'Company',
        companyFlag:false,
        passFlag:false,
        passSubmitFlag:false,
        termsFlag:true,
        termsSubmitFlag:false,
        captcha:null,
        buttonFlag:true,
    } 
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'email mast be A Valid Email';
                        break;
                    default:
                        err.message = 'email can not be empity';
                        break;
                }
            });
            return errors;
        }),
        AuthorizeName:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Authorize  can not be Empity';
                        break;
                }
            });
            return errors;
        }),
        number:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'MObile Number can not be Empity';
                        break;
                }
            });
            return errors;
        }),
        
        pass1:Joi.string().min(6).max(15).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'password  between 6 and 15 ';
                        break;
                }
            });
            return errors;
        }),

        address: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Address can not be Empity';
                        break;
                }
            });
            return errors;
        }),
        CompanyName: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'Company Name can not be Empity';
                        break;
                }
            });
            return errors;
        }),
        vnumber: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'VAT number can not be Empity';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    changeHandler1=(e)=>{
        if(e.target.value==="Personal"){
            this.props.hnadleCompanySignup({companyFlag:false})
        }else{
            this.props.hnadleCompanySignup({companyFlag:true})
          
        }
        this.setState({optionVlaue:e.target.value})
    }
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            AuthorizeName:this.state.AuthorizeName,
            number:this.state.number,
            email: this.state.email,
            pass1:this.state.pass1,
            address:this.state.address,
            vnumber:this.state.vnumber,
            CompanyName:this.state.CompanyName
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
            if(this.state.passFlag){
                if (this.state.termsFlag) {
                    this.setState({ termsSubmitFlag: true });
                    /* 
                            termsSubmitFlag
                            termsFlag
                            */
                  } else {
              this.setState({
                  email: '',
                  password:'',
                  passSubmitFlag:false
              })
          }
            }else{
             this.setState({passSubmitFlag:true})   
            }
              
         
           
        }
    }
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }
    handleConfirmPass=(e)=>{
        let pass2=e.target.value;
        this.setState({pass2:pass2})
        if((pass2.match(this.state.pass1)) && ((pass2.toString()).length===(this.state.pass1.toString()).length)){
            this.setState({passFlag:true})
        }
        else{
          this.setState({passFlag:false,passSubmitFlag:true})
        }

    }
    onChangeCaptha=(value)=>{
      if(value===null){
        this.setState({captcha:null,buttonFlag:true})
      }
      else{
        this.setState({captcha:value,buttonFlag:false})
      }
    }
    render() {
        const options = [
            { level: 'Company', value: 'Company' },
            { level: 'Personal', value: 'Personal' }
        ]
        const options1 = [
            { level: 'Company Name', value: 'Company Name' },
            { level: 'Company Name', value: 'Company Name' },
        ]
       
        return (
         
                <div>
                         <form onSubmit={this.submitHandler} className='contactForm'>
                         <div className="row">
                         <div className="col-sm-6 col-12">
                         <div className="formInput form-control field-design3">
                         <img src={require('../../images/signIn/05-01.png')} />
                                     <select
                                         value={this.state.optionVlaue}
                                         style={{border:'none',border:'none',height:'35px'}}
                              className="dropdownselectValue"
                                         onChange={this.changeHandler1}
                                         name="address">
                                         {options.map(option => (
                                             <option
                                                 key={option.value}
                                                 value={option.value}
                                             >
                                                 {option.level}
                                             </option>
                                         ))}
                                     </select>
     
                         </div>
                     </div>
                         <div className="col-sm-6 col-12">
                          <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/05-01.png')} />
                                 <input
                                     placeholder="Authorize Name"
                                     value={this.state.AuthorizeName}
                                     name="AuthorizeName"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="text" />
     
                                 {this.state.error.AuthorizeName && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.AuthorizeName}</p>}
                             </div>
                      
                          </div>
                          <div className="col-sm-6 col-12">
                         <div className="formInput form-control field-design3">
                         <img src={require('../../images/signIn/Company-01.png')} />
                                     <select
                                       /*   value={this.state.optionVlaue} */
                                         style={{border:'none',border:'none',height:'35px'}}
                              className="dropdownselectValue"
                                        /*  onChange={this.changeHandler1} */
                                         name="CompanyName">
                                         {options1.map(option => (
                                             <option
                                                 key={option.value}
                                                 value={option.value}
                                             >
                                                 {option.level}
                                             </option>
                                         ))}
                                     </select>
                                     {this.state.error.CompanyName && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.CompanyName}</p>}
                           
                         </div>
                     </div>
                        
                         <div className="col-sm-6 col-12">
                             <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/03-01.png')} />
                                 <input
                                     placeholder="Corporate Email Address"
                                     value={this.state.email}
                                     name="email"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="email" />
     
                                 {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.email}</p>}
                             </div>
                         </div>
                         <div className="col-sm-6 col-12">
                             <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/04-01.png')} />
                                 <input
                                     placeholder="Insert your password"
                                     value={this.state.pass1}
                                     name="pass1"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="password" />
     
                                 {this.state.error.pass1 && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.pass1}</p>}
                             </div>
                         </div>
                         <div className="col-sm-6 col-12">
                        <div className="formInput form-control field-design2">
                            <img src={require('../../images/signIn/04-01.png')} />
                            <input
                                placeholder="Confirm your password"
                                value={this.state.pass2}
                                name="pass2"
                                onChange={this.handleConfirmPass}
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type="password" />
       {this.state.passSubmitFlag ? (
                this.state.passFlag ? (
               ""
                ) : (
                    <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    Password do not match 
                  </p>
                )
              ) : (
                ""
              )}
                        </div>
                    </div>
                         <div className="col-sm-6 col-12">
                          <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/06-01.png')} />
                                 <input
                                     placeholder="Phone Number"
                                     value={this.state.number}
                                     name="number"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="text" />
     
                                 {this.state.error.number && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.number}</p>}
                             </div>
                           </div> 
                           <div className="col-sm-6 col-12">
                          <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/VAT-01.png')} />
                                 <input
                                     placeholder="VAT Number"
                                     value={this.state.vnumber}
                                     name="vnumber"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="text" />
     
                                {this.state.error.vnumber && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.vnumber}</p>}
                              </div>
                           </div> 
                         <div className="col-12">
                             <div className="formInput form-control field-design2">
                                 <img src={require('../../images/signIn/07-01.png')} />
                                 <input
                                     placeholder="Address"
                                     value={this.state.address}
                                     name="address"
                                     onChange={this.changeHandler}
                                    /*  id="emailField" */
                                     className=""
                                     style={{border:'none',background:'none',border:'none',height:'35px'}}
                                     type="text" />
     
                                 {this.state.error.address && <p style={{fontWeight:'550',marginTop:'8px'}}>{this.state.error.address}</p>}
                             </div>
                         </div>
                        
                         <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area">
                       <div >
                        <div className="checkbow-style-area" style={{color:'white'}}> <span>
                  {" "}
                  <input
                    class="styled-checkbox-signup"
                    name="termsConditions"
                    onChange={this.handleConditions}
                    id="styled-checkbox-term"
                    type="checkbox"
                    value="valueterm"
                  />
                  <label for="styled-checkbox-term"></label>
                </span>{" "} <span style={{fontSize:'15px',marginLeft:'10px',marginTop:'-10px'}}>By Creating an account you agree to</span></div>   
                       <p style={{color:'#666666',textDecoration:'underline',marginLeft:'30px'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/terms-and-conditions'>  Terms & Conditions</NavLink></p>    
                       {this.state.termsSubmitFlag ? (
                this.state.termsFlag ? (
                  <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    You must accept the terms & Conditions
                  </p>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
                       </div>
                        <div></div>
                        </div>
                        <div className="signon-bottom-area1">
              <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                <button disabled={this.state.buttonFlag}  type="submit">Sign Up</button>
              </div>
                    </div>
                         </div>
                         </form>
                   
                  
           
                    </div>   
         
        )
    }
}
export default FormCorporateSignUp1