import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'

  import ReCAPTCHA from "react-google-recaptcha";
  import { Search, Grid, Header, Segment ,Label,List,Icon,Input} from 'semantic-ui-react'





  const recaptchaRef = React.createRef();
class AdminMenuAreaDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
    } 
 
  }

  /* visitor */
    render() {

     /*   console.log(this.state.exp,"this.state.exp") */
        return (
          <div>
        <div className="ipad-customer-menu-items">
        <div className="container">
                <div className="row">
                 
         
           <div className="col-lg-6 col-md-6 col-12">
 
           <div className="ipad-menu-services">
           <a href="https://lawyer.legalemirates.com/" style={{color:'white'}} target="_blank">
          <Icon name="law" /> Lawyer Panel
          </a>
         </div>
             </div>
             <div className="col-lg-6 col-md-6 col-12">

<div className="ipad-menu-services" >
  <a href="https://accountant.legalemirates.com" style={{color:'white'}} target="_blank">
<Icon name="calculator" />  Accountant Panel
</a>
</div>
 </div>
            <div className="col-lg-6 col-md-6 col-12">
 
            <div className="ipad-menu-services" >
            <a href="https://receptionist.legalemirates.com" style={{color:'white'}} target="_blank">
          <Icon name="yelp" /> Receptionist Panel
          </a>
         </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://ipad.legalemirates.com/" style={{color:'white'}} target="_blank">
<Icon name="mobile alternate" /> IPAD Panel
</a>
</div>
   </div>
   <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://visitor.legalemirates.com/" style={{color:'white'}} target="_blank">
<Icon name="users" /> Office Visitor 
</a>
</div>
   </div>
   <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://baderalzaabi.legalemirates.com/" style={{color:'white'}} target="_blank">
<Icon name="newspaper outline" /> Bader Al Zaabi Card
</a>
</div>
   </div>
   <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://consultant.legalemirates.com/" style={{color:'white'}} target="_blank">
<Icon name="newspaper outline" /> Consultant Panel
</a>
</div>
   </div>
   <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://legalemirates.com/coming-soon" style={{color:'white'}} target="_blank">
<Icon name="cc mastercard" /> Debt Collection Agency
</a>
</div>
   </div>
   <div className="col-lg-6 col-md-6 col-12">
 
 <div className="ipad-menu-services" >
 <a href="https://legalemirates.com/coming-soon" style={{color:'white'}} target="_blank">
<Icon name="key" /> Admin Panel
</a>
</div>
   </div>
          
          </div>

          </div>          
        
      
       {/*   <div className="ipad-menu-services" onClick={()=> message.success(`this feature is coming soon`)}>
          <Icon name="arrow alternate circle right outline" />    Office Boy Services
         </div> */}
      
        
        </div>
          </div>
        )
    }
}

export default AdminMenuAreaDetail