import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import "../Form/style.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import FormCorporateSignUp1 from "./corporateSignup1";
const recaptchaRef = React.createRef();
class FormCorporateSignUp extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
      password: "",
      error: {},
      type: "password",
      passFlag: false,
      fname: "",
      number: "",
      pass1: "",
      pass2: "",
      address: "",
      optionVlaue: "Personal",
      companyFlag: false,
      passFlag: false,
      passSubmitFlag: false,
      termsFlag: true,
      termsSubmitFlag: false,
      captcha: null,
      buttonFlag: true,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "must be a valid email";
              break;
            default:
              err.message = "must enter an email";
              break;
          }
        });
        return errors;
      }),
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a name";
              break;
          }
        });
        return errors;
      }),
    number: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a mobile number";
              break;
          }
        });
        return errors;
      }),

    pass1: Joi.string()
      .min(6)
      .max(15)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "password between 6 and 15";
              break;
          }
        });
        return errors;
      }),

    address: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter an address";
              break;
          }
        });
        return errors;
      }),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  changeHandler1 = (e) => {
    if (e.target.value === "Personal") {
      this.setState({ companyFlag: false });
    } else {
      this.setState({ companyFlag: true });
    }
    this.setState({ optionVlaue: e.target.value });
  };
  hnadleCompanySignup = (companyFlag) => {
    if (companyFlag.companyFlag) {
      this.setState({ companyFlag: true });
      this.setState({ optionVlaue: "Company" });
    } else {
      this.setState({ companyFlag: false });
      this.setState({ optionVlaue: "Personal" });
    }
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      number: this.state.number,
      email: this.state.email,
      pass1: this.state.pass1,
      address: this.state.address,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      if (this.state.passFlag) {
        if (this.state.termsFlag) {
          this.setState({ termsSubmitFlag: true });
          /* 
                            termsSubmitFlag
                            termsFlag
                            */
        } else {
          this.setState({
            email: "",
            password: "",
            passSubmitFlag: false,
          });
        }
      } else {
        this.setState({ passSubmitFlag: true });
      }
    }
  };
  handleSent = () => {
    this.setState({ show: false });
  };
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleConfirmPass = (e) => {
    let pass2 = e.target.value;
    this.setState({ pass2: pass2 });
    if (
      pass2.match(this.state.pass1) &&
      pass2.toString().length === this.state.pass1.toString().length
    ) {
      this.setState({ passFlag: true });
    } else {
      this.setState({ passFlag: false, passSubmitFlag: true });
    }
  };
  onChangeCaptha = (value) => {
    if (value === null) {
      this.setState({ captcha: null, buttonFlag: true });
    } else {
      this.setState({ captcha: value, buttonFlag: false });
    }
  };

  render() {
    const options = [
      { level: "Personal", value: "Personal" },
      { level: "Company", value: "Company" },
    ];
    const options1 = [
      { level: "Company Name", value: "Company Name" },
      { level: "Company Name", value: "Company Name" },
    ];

    return (
      <div>
        {this.state.companyFlag ? (
          <FormCorporateSignUp1
            hnadleCompanySignup={this.hnadleCompanySignup}
          />
        ) : (
          <form onSubmit={this.submitHandler} className="contactForm">
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design3">
                  <img src={require("../../images/signIn/05-01.png")} />
                  <select
                    value={this.state.optionVlaue}
                    style={{ border: "none", border: "none", height: "35px" }}
                    className="dropdownselectValue"
                    onChange={this.changeHandler1}
                    name="address"
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.level}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/05-01.png")} />
                  <input
                    placeholder="Full Name"
                    value={this.state.fname}
                    name="fname"
                    onChange={this.changeHandler}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="text"
                  />

                  {this.state.error.fname && (
                    <p style={{ fontWeight: "550", marginTop: "8px" }}>
                      {this.state.error.fname}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/06-01.png")} />
                  <input
                    placeholder="Phone Number"
                    value={this.state.number}
                    name="number"
                    onChange={this.changeHandler}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="text"
                  />

                  {this.state.error.number && (
                    <p style={{ fontWeight: "550", marginTop: "8px" }}>
                      {this.state.error.number}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/03-01.png")} />
                  <input
                    placeholder="Corporate Email Address"
                    value={this.state.email}
                    name="email"
                    onChange={this.changeHandler}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="email"
                  />

                  {this.state.error.email && (
                    <p style={{ fontWeight: "550", marginTop: "8px" }}>
                      {this.state.error.email}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/04-01.png")} />
                  <input
                    placeholder="Insert your password"
                    value={this.state.pass1}
                    name="pass1"
                    onChange={this.changeHandler1}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="password"
                  />

                  {this.state.error.pass1 && (
                    <p style={{ fontWeight: "550", marginTop: "8px" }}>
                      {this.state.error.pass1}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/04-01.png")} />
                  <input
                    placeholder="Confirm your password"
                    value={this.state.pass2}
                    name="pass2"
                    onChange={this.handleConfirmPass}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="password"
                  />
                  {this.state.passSubmitFlag ? (
                    this.state.passFlag ? (
                      ""
                    ) : (
                      <p
                        style={{
                          fontWeight: "550",
                          marginTop: "8px",
                          color: "red",
                        }}
                      >
                        Password do not match
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="formInput form-control field-design2">
                  <img src={require("../../images/signIn/07-01.png")} />
                  <input
                    placeholder="Address"
                    value={this.state.address}
                    name="address"
                    onChange={this.changeHandler}
                    /*  id="emailField" */
                    className=""
                    style={{
                      border: "none",
                      background: "none",
                      border: "none",
                      height: "35px",
                    }}
                    type="text"
                  />

                  {this.state.error.address && (
                    <p style={{ fontWeight: "550", marginTop: "8px" }}>
                      {this.state.error.address}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-12" style={{ marginTop: "0px" }}>
                <div className="signon-bottom-area">
                  <div>
                    <div
                      className="checkbow-style-area"
                      style={{ color: "white" }}
                    >
                      {" "}
                      <span>
                        {" "}
                        <input
                          class="styled-checkbox-signup"
                          name="termsConditions"
                          onChange={this.handleConditions}
                          id="styled-checkbox-term"
                          type="checkbox"
                          value="valueterm"
                        />
                        <label for="styled-checkbox-term"></label>
                      </span>{" "}
                      <span
                        style={{
                          fontSize: "15px",
                          marginLeft: "10px",
                          marginTop: "-10px",
                        }}
                      >
                        By Creating an account you agree to
                      </span>
                    </div>
                    <p
                      style={{
                        color: "#666666",
                        textDecoration: "underline",
                        marginLeft: "30px",
                      }}
                    >
                      <NavLink
                        style={{ color: "#fff", textDecoration: "underline" }}
                        exact
                        to="/terms-and-conditions"
                      >
                        {" "}
                        Terms & Conditions
                      </NavLink>
                    </p>
                    {this.state.termsSubmitFlag ? (
                      this.state.termsFlag ? (
                        <p
                          style={{
                            fontWeight: "550",
                            marginTop: "8px",
                            color: "red",
                          }}
                        >
                          You must accept the terms & Conditions
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div></div>
                </div>
                <div className="signon-bottom-area1">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                    onChange={this.onChangeCaptha}
                  />
                  <button disabled={this.state.buttonFlag} type="submit">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}
export default FormCorporateSignUp;
