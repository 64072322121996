import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const NotaryContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Private Client and Notary Service </h3>
        <p>
          The Law Firm offers a team of multijurisdictional lawyers with years
          of international experience, deep knowledge of the region, and unique
          service to its clients.{" "}
        </p>
        <p>We advise on: </p>
        <ul style={{ color: "#666666" }}>
          <li>• Corporate and commercial legal issues </li>
          <li>
            • Meditation on any type of dispute (litigation, arbitration and out
            of court settlements)
          </li>
          <li>
            • Family matters (family business, wills, divorce, child custody,
            etc.)
          </li>
          <li>• Real estate and insurance matters </li>
          <li>• Crimes (financial, cybersecurity and criminal cases)</li>
        </ul>
        <h5>Our notary services include: </h5>
        <ul style={{ color: "#666666" }}>
          <li>• Authentication of power of attorneys for overseas </li>
          <li>
            • Authentication of foreign wills and documents related to owning a
            property abroad{" "}
          </li>
          <li>• Authentication of personal information and documents </li>
        </ul>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default NotaryContent;
