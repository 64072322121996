import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CHANGE_PASSWORD_CUTOMER = "CHANGE_PASSWORD_CUTOMER";
export const CHANGE_PASSWORD_CUTOMER_LOADING =
"CHANGE_PASSWORD_CUTOMER_LOADING";
export const CHANGE_PASSWORD_CUTOMER_LOADING_FAILED =
"CHANGE_PASSWORD_CUTOMER_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const changePasswordLoading = ms => ({
    type: CHANGE_PASSWORD_CUTOMER_LOADING,
    payload: ms
    });
    
    export const changePasswordLoadingFailed = ms => ({
    type: CHANGE_PASSWORD_CUTOMER_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function changePassword(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/users/auth/changePassword`,obj);
    return dispatch => {

    dispatch(changePasswordLoading());
   console.log(request,"request")
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response changePassword")
    dispatch({
    type: CHANGE_PASSWORD_CUTOMER,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
        if (error.response.status === 400 || error.response.status === 404 || error.response.status === 402 || error.response.status === 403) {
            console.log(error.response)
    dispatch(
        changePasswordLoadingFailed(`The Link Has Expired`)
    );
    } else {
    dispatch(changePasswordLoadingFailed('Opps Something Went Wrong!'));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(changePasswordLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(changePasswordLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


