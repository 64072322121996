import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const DrugsContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3>Drug Law </h3>
        <p>
          The UAE has a zero-tolerance policy against the smuggling and
          recreational use of drugs. There are different types of crimes related
          to drugs apart from just using it, for instance, drug possession, it’s
          trafficking, and transporting, and if someone is found guilty over
          these crimes there can be life imprisonment, hefty fines, probations,
          or in the worst-case scenario death penalties.
        </p>
        <p>
          The law firm has a long experience and history in these types of cases
          and our team is ready to help if you or your relatives are facing
          these kinds of severe charges in the events of drug trafficking or
          transporting etc.{" "}
        </p>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default DrugsContent;
