import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const FamilyBusinessContent = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      <div className="singleContentImg">
        <img src={image} alt="" />
      </div>
      <div className="singleContentWrap">
        <h3> Family business Cases </h3>
        <p>
          Just like any other business, there are pros and cons of a family
          business as well, there are always legal issues that families face
          while doing their businesses. Family matters are always complex due to
          their emotional attachment. Our family law team is an expert in
          dealing with all kinds of matters involving families and their
          businesses. Without damaging the family fabric, they give advice and
          provide legal solutions to families facing these issues.{" "}
        </p>
        <p>
          Our team will structure the binding agreements which will defend the
          family assets from every type of risk and we will make sure to pave
          the way for seamless succession for your family business.{" "}
        </p>
        <p>Our team will:</p>
        <ul style={{ color: "#666666" }}>
          <li>• Structure your business to manage legal issues and risks. </li>
          <li>
            • Formulate trust deeds which permit transparent transitions to
            future stewardship.
          </li>
          <li>
            • Find a way to resolve legal issues of business without damaging
            the fabric of a family.{" "}
          </li>
        </ul>
        {avatar && (
          <div className="avatarWra">
            {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default FamilyBusinessContent;
