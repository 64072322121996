import React from 'react'
import './style.scss'

import img1 from '../../images/instragram/12.jpg'
import img2 from '../../images/instragram/7.jpg'
import img3 from '../../images/instragram/8.jpg'
import img4 from '../../images/instragram/9.jpg'
import img5 from '../../images/instragram/10.jpg'
import img6 from '../../images/instragram/11.jpg'

const instagrams = [
    img1, img2, img3, img4, img5, img6,
]

const Twitter = () => {
    return (
        <div className="instagramWrap">
            <h3 style={{marginTop:'20px'}}>Twitter</h3>
            <ul>
                {instagrams.map(instagram => (
                    <li key={instagram}><img src={instagram} alt=""/></li>
                ))}
            </ul>
        </div>
    )
}
export default Twitter;
