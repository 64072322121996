import React, { Component } from "react";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Form,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
const photoDef = require("../../images/about/upload.png");
const recaptchaRef = React.createRef();
class JoinTeam extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      name: "",
      phone: "",
      email: "",
      subject: "",
      address: "",
      photo15: "",
      description: "",
      error: {},
      captcha: null,
      buttonFlag: true,
      sentFlag: false,
      preview: photoDef,
      photo: "",
      cv: "",
      cv15: "",
      position: "",
      partner: "",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  componentWillMount() {}
  componentDidMount() {}
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be empity";
              break;
          }
        });
        return errors;
      }),
    phone: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "phone can not be Empity";
              break;
          }
        });
        return errors;
      }),
    name: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "name can not be Empity";
              break;
          }
        });
        return errors;
      }),
    photo15: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "select your photo";
              break;
          }
        });
        return errors;
      }),
    cv15: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "select your CV";
              break;
          }
        });
        return errors;
      }),
    position: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "enter your position first";
              break;
          }
        });
        return errors;
      }),
    partner: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "select business partner";
              break;
          }
        });
        return errors;
      }),
    address: Joi.string(),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      photo15: this.state.photo15,
      partner: this.state.partner,
      position: this.state.position,
      cv15: this.state.cv15,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  /* handleString=(name,phone,email,address,description)=>{
return ({`<div >
    <h2>From: ${name}</h2>
    <p>${description}</p>
    <br />
    <p>Phone: ${phone}</p>
<p>Email:${email}</p>
<p>Address:${address}</p>
</div>`})
} */
  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      const emailBody = `<div >
            <h5>From: ${this.state.name}</h5>
            <p>position:${this.state.position}</p>
            <p>Business partner Lawyer:${this.state.partner}</p>
            <br />
            <p>Sender Detail:
            <p>Phone: ${this.state.phone}</p>
        <p>Email:${this.state.email}</p>
            </p>
            
        </div>`;
      const formData = new FormData();
      console.log(this.state.photo, "kkkkkkkkk");
      formData.append("cv", this.state.cv);
      formData.append("photo", this.state.photo);
      formData.append("to", "info@legalemirates.com");
      formData.append("emailBody", emailBody);
      formData.append("subject", "Join As Lawyer");
      fetch("https://api-legalemirates.wowets.com/api/v1/mailSender/sendCV", {
        method: "POST",
        body: formData,
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) {
            this.setState({ sentFlag: true });
            console.log(response);
            setTimeout(() => {
              this.setState({
                name: "",
                phone: "",
                email: "",
                position: "",
                partner: "",
                cv15: "",
                photo15: "",
                photo: "",
                cv: "",
              });
            }, 2000);
          } else if (response.status == 401) {
            console.log(response);
            alert("Oops! ");
          } else {
            this.setState({ sentFlag: true });
            console.log(response);
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Something went wrong Please Again letter");
        });
    }
  };
  onChangeCaptha = (value) => {
    if (value === null) {
      this.setState({ captcha: null, buttonFlag: true });
    } else {
      this.setState({ captcha: value, buttonFlag: false });
    }
  };
  handleSent = () => {
    this.setState({ sentFlag: false });
  };
  handleFileChange = (e) => {
    this.changeHandler(e);
    this.setState({
      photo: e.target.files[0],
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview: [reader.result],
      });
    };
  };
  handleCVChange = (e) => {
    this.changeHandler(e);
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name").innerHTML = e.target.files[0].name;
  };
  render() {
    const options = [
      { level: "Business Partner ", value: "Business Partner " },
      { level: "Business Partner ", value: "Business Partner " },
      /*  { level: 'Banking & Finance', value: 'Banking & Finance' },
        { level: 'Capital Market', value: 'Capital Market' },
        { level: 'Commercial', value: 'Commercial' },
        { level: 'Competition', value: 'Competition' },
        { level: 'Construction & Infrastructure', value: 'Construction & Infrastructure' },
        { level: 'Corporate', value: 'Corporate' },
        { level: 'Corporate Services', value: 'Corporate Services' },
        { level: 'Criminal', value: 'Criminal' },
        { level: 'Data Privacy & Cybercrimes', value: 'Data Privacy & Cybercrimes' },
        { level: 'International Crimes', value: 'International Crimes' },
        { level: 'Merger & Acquisitions', value: 'Merger & Acquisitions' },
        { level: 'Corporate Structuring', value: 'Corporate Structuring' },
        { level: 'Employment & Compensation', value: 'Employment & Compensation' },
        { level: 'Family Business', value: 'Family Business' },
        { level: 'Financial Crimes', value: 'Financial Crimes' },
        { level: 'Insolvency', value: 'Insolvency' },
        { level: 'Insurance', value: 'Insurance' },
        { level: 'Intellectual Property', value: 'Intellectual Property' } */
    ];
    return (
      <div className="home-page-margin-top15">
        <div onClick={this.handleShow} className="livemeeting text-right">
          <a style={{ fontSize: "11px", cursor: "pointer" }}>Join Our Team</a>
        </div>
        {/*      <img title="Give Your suggestion here" onClick={this.handleShow}  style={{width:'35px',cursor:'pointer'}} src={require('../../images/about/ade-01.png')} />
         */}{" "}
        {/*    <i onClick={this.handleShow} style={{cursor:'pointer',fontSize:'20px'}} title="Give Your suggestion here" className="fa fa-stack-exchange"></i>
         */}
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="heading-modal">
              <img
                style={{ width: "30px" }}
                src={require("../../images/about/man-01.png")}
              />{" "}
              Join Our Team
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.sentFlag ? (
              <div className="sent-message-area">
                <center>
                  <p>
                    <i class="fa fa fa-check-circle-o"></i>
                  </p>
                  <h2>thank you!</h2>
                  <p>Your submission has been received.</p>
                  <button className="sent-button-des" onClick={this.handleSent}>
                    Ok
                  </button>
                </center>
              </div>
            ) : (
              <form onSubmit={this.submitHandler} className="contactForm">
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="upload-photo-area">
                      <div>
                        <center>
                          {" "}
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              marginTop: "30px",
                            }}
                            src={this.state.preview}
                          />
                        </center>

                        <div className="image-upload">
                          <label for="file-input2">
                            <p style={{ fontSize: "12px", cursor: "pointer" }}>
                              Upload your photo
                            </p>
                          </label>

                          <input
                            id="file-input2"
                            name="photo15"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.error.photo15 && (
                      <p
                        style={{
                          color: "red",
                          textTransform: "uppercase",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.error.photo15}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="formInput">
                      <input
                        placeholder="Your Name"
                        value={this.state.name}
                        name="name"
                        style={{ color: "black" }}
                        onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                      />
                      {this.state.error.name && <p>{this.state.error.name}</p>}
                    </div>
                    <div className="formInput">
                      <input
                        placeholder="Phone Number"
                        value={this.state.phone}
                        name="phone"
                        style={{ color: "black" }}
                        onChange={this.changeHandler}
                        className="form-control"
                        type="phone"
                      />
                      {this.state.error.phone && (
                        <p>{this.state.error.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formInput">
                      <input
                        placeholder="Email"
                        value={this.state.email}
                        name="email"
                        style={{ color: "black" }}
                        onChange={this.changeHandler}
                        className="form-control"
                        type="email"
                      />
                      {this.state.error.email && (
                        <p>{this.state.error.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formInput">
                      <input
                        placeholder="Your Position"
                        name="position"
                        style={{ color: "black" }}
                        onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                      />
                      {this.state.error.position && (
                        <p>{this.state.error.position}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formInput">
                      <select
                        className="form-control"
                        style={{ backgroundColor: "#F3F0E9" }}
                        onChange={this.changeHandler}
                        name="partner"
                      >
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.level}
                          </option>
                        ))}
                      </select>
                      {this.state.error.partner && (
                        <p>{this.state.error.partner}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formInput">
                      <div class="inputfile-box18">
                        <input
                          type="file"
                          id="file"
                          name="cv15"
                          class="inputfile18"
                          accept=".png,.jpg,.jpeg,.pdf,.docx"
                          onChange={this.handleCVChange}
                        />
                        <label for="file">
                          <span id="file-name" class="file-box">
                            {" "}
                            Upload your Resume/CV
                          </span>
                          <span class="file-button18">Choose a file</span>
                        </label>
                      </div>
                      {this.state.error.cv15 && (
                        <p
                          style={{
                            color: "red",
                            textTransform: "uppercase",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.error.cv15}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="google-captcha-additionarea">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                        onChange={this.onChangeCaptha}
                      />
                      <button
                        style={{ marginTop: "10px" }}
                        disabled={this.state.buttonFlag}
                        type="submit"
                      >
                        submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default JoinTeam;
