import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import HomePageOne from '../Pages/HomePageOne'
import CustomerSignIn from '../Pages/customerSignIn'
import HomePageTwo from '../Pages/HomePageTwo'
import HomePageThree from '../Pages/HomePageThree'
import AboutPage from '../Pages/AboutPage'
import PracticePage from '../Pages/PracticePage'
import PracticeSinglePage from '../Pages/PracticeSinglePage'
import Crimnal from  '../Pages/PracticeSinglePage/criminal'
import BussinessCorporate from '../Pages/PracticeSinglePage/bussiness-corporate'
import Interpol from  '../Pages/PracticeSinglePage/interpol'
import Labor from  '../Pages/PracticeSinglePage/labor'
import Financial from  '../Pages/PracticeSinglePage/finanacial'
import Family from '../Pages/PracticeSinglePage/family'
import DubaiExcellence from '../Pages/BlogLeftPage/DubaiExcellence'
import Arbitration from '../Pages/PracticeSinglePage/arbitration'
import RealState from '../Pages/PracticeSinglePage/realState'
import Construction  from '../Pages/PracticeSinglePage/contstruction'
import Poverty  from '../Pages/PracticeSinglePage/poverty'
import TeamPage1 from '../Pages/TeamPage/teampage1'
import BlogDetailPage1 from '../Pages/blogsDetail'
import KremlinRussia from '../Pages/BlogLeftPage/KremlinRussia'
import CIJ_ICJ from '../Pages/BlogLeftPage/CIJ_ICJ'
import Merger from '../Pages/PracticeSinglePage/merger'
import Commercial from '../Pages/PracticeSinglePage/commercial'
import Market from '../Pages/PracticeSinglePage/market'
import MohapNews from '../Pages/BlogLeftPage/mohapnews'
import SpacePortUAE from '../Pages/BlogLeftPage/SpacePortUAE'
import RakCourts from '../Pages/BlogLeftPage/RakCourts'
import CIA from '../Pages/BlogLeftPage/CIA'
import GulfNewsVideo from '../Pages/BlogLeftPage/GulfNewsVideo'
import AjmanDigitalGov from '../Pages/BlogLeftPage/AjmanDigitalGov'
import ShjPolice from '../Pages/BlogLeftPage/ShjPolice'
import UAEmGov from '../Pages/BlogLeftPage/UAEmGov'
import CounsellingVisuallyWriting from '../Pages/counsellingVisuallyWriting'
import DubaiOfficeNews from '../Pages/BlogLeftPage/dubaiOffice'
import InterpolNews from '../Pages/BlogLeftPage/interpolNews'
import DubaiCourtsNews from '../Pages/BlogLeftPage/DubaiCourts'
import Amerkel57 from '../Pages/BlogLeftPage/amerkel57'
import  BBCWorld from '../Pages/BlogLeftPage/BBCWorld'
import WowNews from '../Pages/BlogLeftPage/wowNews'
import CorporateFilePage from '../Pages/corporateFile'
import UnitedNationsNews from '../Pages/BlogLeftPage/UnitedNations'
import PageNotFoundPage from '../Pages/404'
import Seekadvice from '../Pages/blogsDetail/Seekadvice'
import MOJ_UAENews from '../Pages/BlogLeftPage/MOJ_UAE'
import Narendramodi from '../Pages/BlogLeftPage/narendramodi'
import RAKeGov from '../Pages/BlogLeftPage/RAKeGov'
import EmmanuelMacron from '../Pages/BlogLeftPage/EmmanuelMacron'
import SpaceX from '../Pages/BlogLeftPage/SpaceX'
import IATA from '../Pages/BlogLeftPage/IATA'
import NASA from '../Pages/BlogLeftPage/NASA'
import AlsisiOfficial from '../Pages/BlogLeftPage/AlsisiOfficial'
import HamdanNews from '../Pages/BlogLeftPage/HamdanNews'
import ImranKhanPTI from '../Pages/BlogLeftPage/ImranKhanPTI'
import Sharjahmedia from '../Pages/BlogLeftPage/sharjahmedia'
import Infrastructure from '../Pages/blogsDetail/dubaiInfrastructure'
import Forbes from '../Pages/BlogLeftPage/Forbes'
import UAEMinistry from '../Pages/blogsDetail/UAEMinistry'
import Insurance from  '../Pages/PracticeSinglePage/insurance'
import UserInvoicePage from '../Pages/invoiceViewPage'
import CustomerFilePage from '../Pages/customerFile'
import AdminSignIn from '../Pages/adminSignIn'
/* import ProfilePage from '../Pages/profilePage' */
import AwqafUAENews from '../Pages/BlogLeftPage/AwqafUAE'
import ProfilePageCorporate from '../Pages/corporateProfilePage'
import OklaMuniLeague from '../Pages/BlogLeftPage/OklaMuniLeague'
import COVIDNews from '../Pages/blogsDetail/COVIDNews'
import AngelaMerkeICDU from '../Pages/BlogLeftPage/AngelaMerkeICDU'
import AdminPassReset from '../Pages/adminPasswordReset'
import IsraelArabic from '../Pages/BlogLeftPage/IsraelArabic'
import UAE_PP from '../Pages/BlogLeftPage/UAE_PP'
import ESA from '../Pages/BlogLeftPage/ESA'
import SmartDubaiNews from '../Pages/BlogLeftPage/SmartDubai'
import CNBCi from '../Pages/BlogLeftPage/CNBCi'
import ADJD_Official from '../Pages/BlogLeftPage/ADJD_Official'
import Uaespaceagency from '../Pages/BlogLeftPage/uaespaceagency'
import UMOHRE_UAENews from '../Pages/BlogLeftPage/MOHRE_UAE'
import Abudhabi_tammNews from '../Pages/BlogLeftPage/abudhabi_tamm'
import BorisJohnsonNews from '../Pages/BlogLeftPage/borisJohnsonNews'

import RT_com from '../Pages/BlogLeftPage/RT_com'
import Dubai_DEDNews from '../Pages/BlogLeftPage/Dubai_DED'
import RegSprecher from '../Pages/BlogLeftPage/RegSprecher'
import Zaikandongxi from '../Pages/BlogLeftPage/zaikandongxi'
import AlBayanNews from '../Pages/BlogLeftPage/AlBayanNews'
import SheikhNews from '../Pages/BlogLeftPage/sheikhNews'
import CustomerEmailVerify from '../Pages/emailVerify'
import EmiratesArline from '../Pages/BlogLeftPage/Emirates'
import khaleejtimes from '../Pages/BlogLeftPage/khaleejtimes'
import ChinaDaily from '../Pages/BlogLeftPage/ChinaDaily'
import DIFCCourtsNews from '../Pages/BlogLeftPage/DIFCCourts'
import BGOV from '../Pages/BlogLeftPage/BGOV'
import CounsellingPaidWriting from '../Pages/counsellingPaidWritting'
import Prince___Salman from '../Pages/BlogLeftPage/Prince___Salman'
import CustomerSignUpArea from '../Pages/customerSignUP'
import CorporateSignUP from '../Pages/corporateSignUp'
import RecentNews from '../Pages/RecentNews'
import HumanRightsNews from '../Pages/BlogLeftPage/humanRughts'
import kentuckycourts from '../Pages/BlogLeftPage/kentuckycourts'
import Portfolio from '../Pages/portfolio'
import AlyaumNews from '../Pages/BlogLeftPage/alyaumNews'
import CGTNOfficial from '../Pages/BlogLeftPage/CGTNOfficial'
import DubaiChamberNews from '../Pages/BlogLeftPage/DubaiChamber'
import USLaborNews from '../Pages/BlogLeftPage/USLabor'
import CorporateSignIn from '../Pages/corporateSignIn'
import CdaDubaiNews from '../Pages/BlogLeftPage/cdaDubai'
import TheUAETRANews from '../Pages/BlogLeftPage/TheUAETRA'
import ArtificialIntelligenceNews from '../Pages/BlogLeftPage/artificialIntelligenceNews'
import ZaidNews from '../Pages/BlogLeftPage/zaidNews'
import MunicipalityNews from '../Pages/BlogLeftPage/MunicipalityNews'
import ForgotPassword from '../Pages/forgotPass'
import Tax from '../Pages/PracticeSinglePage/tax'
import GovtUAENews from '../Pages/BlogLeftPage/govtUae'
import RakPoliceNews from '../Pages/BlogLeftPage/rakPolice'
import Notary from  '../Pages/PracticeSinglePage/notary'
import InvoiceTermsPage from '../Pages/invoiceTermsPage'
import CNNInternationalNews from '../Pages/BlogLeftPage/CNNInternational'
import AdPoliceNews from '../Pages/BlogLeftPage/adPolice'
import DohSocialNews from '../Pages/BlogLeftPage/doHSocial'
import OpenFileTermsPage from '../Pages/openFileTermsPage'
import TrumpNews from '../Pages/BlogLeftPage/trumpNews'
import GetMarried from '../Pages/blogsDetail/GetMarried'
import MOIUAENews from '../Pages/BlogLeftPage/MOIUAE'
import WHONews from '../Pages/BlogLeftPage/WHONews'
import DhaDubaiNews from '../Pages/BlogLeftPage/dhaDubai'
import Drugs from  '../Pages/PracticeSinglePage/drugs'
import DubaiPoliceNews from '../Pages/BlogLeftPage/dubaiPolice'
import MultinationalCompany from '../Pages/PracticeSinglePage/multinationCompany'
import MoneyLaundering from '../Pages/PracticeSinglePage/moneyLaundering'
import EstablishBusiness from '../Pages/PracticeSinglePage/establishBusiness'
import CounsellingFreeOnTime from '../Pages/counsellingFree'
import FamilyBusiness from  '../Pages/PracticeSinglePage/familyBusiness'
import userPanelPage from '../Pages/userProfilePage'
import AjmanPoliceNews from '../Pages/BlogLeftPage/ajmanPolice'
import ArbitrationLawyers from '../Pages/blogsDetail/ArbitrationLawyers'
import PortfolioPage from '../Pages/PortfolioPage'
import SinglePortfolioPage from '../Pages/SinglePortfolioPage'
import PrivacyPolicyPage from '../Pages/privacyPolicyPage'
import TeamPage from '../Pages/TeamPage'
import SingleTeamPage from '../Pages/SingleTeamPage'
import TermsAndConditionsPage from '../Pages/termsPage'
import InvoiceViewSMSPage from '../Pages/userInvoicePage'
import ContactPage from '../Pages/ContactPage'
import BlogLeftPage from '../Pages/BlogLeftPage'
import BlogRightPage from '../Pages/BlogRightPage'
import BlogFullWidth from '../Pages/BlogFullWidth'
import IndustriesPage from '../Pages/IndustriesPage'
import LawPortalsAreaPage from '../Pages/officailLInkPage'
import CookiesNotify from '../../cookies-notify';
import HeaderTop from '../../components/HeaderTop';
import IntellectualProperty from '../Pages/blogsDetail/IntellectualProperty'
import ComingSoon from '../Pages/comingSoon'
import {setCurrentUser, logoutUser} from '../../components/customerSignIn/store/actions/customerSignIn';
import {logoutAdmin,
  setCurrentAdmin} from '../../components/adminSignIn/store/actions/codeVerifyAuth'
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../components/customerSignIn/setAuthToken';
import store from '../../store';
import ResetPassword from '../Pages/resetPass'
import $ from 'jquery'
import history from '../../history';
import  BreakingNews from '../../components/breaking'
import * as Actions from "./store/actions";
import AdminePenelPage from '../Pages/adminMenuPage'
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './App.css';
/*    <HeaderTop className="headerTop" /> */
class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
          authFlag:false,
          adminAuth:false
    } 
   
    store.subscribe(() => {
     
        let resNumber1 = store.getState().App1.authRiderReducer;
        if(resNumber1.isAuthenticated){
            this.setState({authFlag:true})
            console.log(resNumber1.isAuthenticated,"resNumber1.isAuthenticated")
    }else{
      console.log(resNumber1.isAuthenticated,"else")
      this.setState({authFlag:false})
    }
     

      /* AdminAuthRiderReducer */
      
      let resNumber2 = store.getState().App1.AdminAuthRiderReducer;
      if(resNumber2.isAuthenticated){
          this.setState({adminAuth:true})
    
  }else{
  
    this.setState({adminAuth:false})
  }
    });
    
    
  }

   componentDidMount(){
    if(localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      const decoded = jwt_decode(localStorage.jwtToken);
      console.log(decoded)
      store.dispatch(setCurrentUser(localStorage.getItem('user')));
    
    
      const currentTime = Date.now() / 1000;
      if(decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.reload('/signin');
      }
    }

/* logoutAdmin,
setCurrentAdmin
 */
    if(localStorage.adminToken1) {
   
      const decoded1 = jwt_decode(localStorage.adminToken1);
   
    
    
      const currentTime = Date.now() / 1000;
      if(decoded1.exp < currentTime) {
        store.dispatch(logoutAdmin());
        window.location.reload('/adminsignin');
      }else{
        setAuthToken(localStorage.adminToken1);
        store.dispatch(setCurrentAdmin(localStorage.getItem('admin')));
      }
    }

  } 
    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter history={history}>
                
                    <ToastContainer autoClose={2500} position="top-center" />
                    <CookiesNotify />
                    <BreakingNews />
                    <header className="headerArea">
                    <HeaderTop className="headerTop" />
                    </header>
                    <Switch>
                      {/* AdmineReceipnest LawPortalsAreaPage */}
                      <Route exact path="/" component={HomePageOne} />{/*GetMarried */}
                      <Route exact path="/official-links-Law-portals" component={LawPortalsAreaPage} />
                      <Route exact path="/blogs/intellectual-property" component={IntellectualProperty} />
                      <Route exact path="/blogs/dubai-marriage" component={GetMarried} />
                      <Route exact path="/blogs/arbitration-lawyers" component={ArbitrationLawyers} />
                        <Route exact path="/fileterms" component={OpenFileTermsPage} />
                        <Route exact path="/administration" component={AdminePenelPage} />
                        <Route exact path="/invoiceterms" component={InvoiceTermsPage} />
                        <Route exact path="/resetpassword/:token" component={ResetPassword} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/signin" component={CustomerSignIn} />
                        <Route exact path="/corporate-signin" component={CorporateSignIn} />
                        <Route exact path="/coming-soon" component={ComingSoon} />
                         <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
                         <Route  path="/verifyEmail/:id" component={CustomerEmailVerify} /> 
                         <Route  path="/invoice/:id" component={InvoiceViewSMSPage} /> 
                        <Route exact path="/industries" component={IndustriesPage} />
                        <Route exact path="/about-us" component={AboutPage} />
                        <Route exact path="/practice" component={PracticePage} />
                        <Route exact path="/practice-details" component={Crimnal} />
                        <Route exact path="/speciality-criminal" component={Crimnal} />
                        <Route exact path="/speciality-business" component={BussinessCorporate} />
                        <Route exact path="/speciality-interpol" component={Interpol} />
                        <Route exact path="/speciality-labor" component={Labor} />
                        <Route exact path="/speciality-financial" component={Financial} />
                        <Route exact path="/speciality-family" component={Family} />
                        <Route exact path="/speciality-arbitration" component={Arbitration} />
                        <Route exact path="/speciality-construction" component={Construction} />
                        <Route exact path="/speciality-merger" component={Merger} />
                        <Route exact path="/speciality-market" component={Market} />
                        <Route exact path="/speciality-insurance" component={Insurance} />
                        <Route exact path="/speciality-real-state" component={RealState} />
                        <Route exact path="/speciality-tax" component={Tax} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                   
                        <Route exact path="/corporate-signup" component={CorporateSignUP} />
                       
                      
                  
                      {/*   <Route exact path="/recentnews/" component={RecentNews} /> */}
                   
                       
                       
                        <Route exact path="/counselling-paid" component={CounsellingPaidWriting} />
                        <Route exact path="/counselling-visually" component={CounsellingVisuallyWriting} />
                    
                        <Route exact path="/consultant" component={ComingSoon} />
                        <Route exact path="/Specialists" component={ComingSoon} />
                      
                        <Route exact path="/counselling-free-time" component={CounsellingFreeOnTime} />
                    
                        <Route exact path="/speciality-multinational-company" component={MultinationalCompany} />
                        <Route exact path="/speciality-drug" component={Drugs} />
                        <Route exact path="/news/sharjah" component={DubaiOfficeNews} />
                        <Route exact path="/speciality-money-laundering" component={MoneyLaundering} />
                        <Route exact path="/speciality-establish-business" component={EstablishBusiness} />
                        <Route exact path="/speciality-notary" component={Notary} />
                        <Route exact path="/speciality-family-business" component={FamilyBusiness} />
                        <Route exact path="/speciality-poverty" component={Poverty} />
                        <Route exact path="/speciality-commercial" component={Commercial} />
                        <Route exact path="/case-studies" component={PortfolioPage} />
                        {/* <Route exact path="/case-studies-details" component={SinglePortfolioPage} /> */}
                        <Route exact path="/team" component={ComingSoon} />
                        <Route exact path="/attorneys-single" component={SingleTeamPage} />
                       {/*  <Route exact path="/attorneys" component={TeamPage1} /> */}
                       <Route exact path="/attorneys" component={ComingSoon} />
                    
                  {/*    <Route exact path="/attorneys" component={TeamPage} /> */} {/* CorporateFilePage */}
            
                        <Route exact path="/founder" component={SingleTeamPage} />
                        <Route exact path="/contact-us" component={ContactPage} />
                        <Route exact path="/news/MohammedZAid" component={ZaidNews} />
                        <Route exact path="/news/dubaipolice" component={DubaiPoliceNews} />
                        <Route exact path="/blogs/UAE-Ministry" component={UAEMinistry} />
                        <Route exact path="/blogs/seek-advice" component={Seekadvice} />
                       {/*  <Route exact path="/corporateprofile" component={ProfilePageCorporate} /> */}
                        <Route exact path="/blogs/coronavirus-news" component={COVIDNews} />
                        <Route exact path="/blogs/coronavirus" component={BlogDetailPage1} />
                        <Route exact path="/news/" component={BlogLeftPage} />
                        <Route exact path="/news/DXBMediaOffice" component={BlogLeftPage} />
                        <Route exact path="/blogs" component={BlogRightPage} />
                        <Route exact path="/blogs/dubai-infrastructure" component={Infrastructure} />
                        <Route exact path="/news/adpolice" component={AdPoliceNews} />
                        <Route exact path="/news/Hamdan" component={HamdanNews} />
                        <Route exact path="/news/SheikhMohammed" component={SheikhNews} />
                        <Route exact path="/news/rakpolice" component={RakPoliceNews} />
                        <Route exact path="/news/ajmanpolice" component={AjmanPoliceNews} />
                        <Route exact path="/news/uae" component={GovtUAENews} />
                        <Route exact path="/news/mohap" component={MohapNews} />
                        <Route exact path="/news/dhadubai" component={DhaDubaiNews} />
                        <Route exact path="/news/interpol" component={InterpolNews} />
                        <Route exact path="/news/cdadubai" component={CdaDubaiNews} />
                        <Route exact path="/news/municipality" component={MunicipalityNews} />
                        <Route exact path="/news/humanrights" component={HumanRightsNews} />
                        <Route exact path="/news/BorisJohnson" component={BorisJohnsonNews} />
                        <Route exact path="/news/trump" component={TrumpNews} />
                        <Route exact path="/news/ArtificialIntelligence" component={ArtificialIntelligenceNews} />
                        <Route exact path="/news/who" component={WHONews} />
                        <Route exact path="/news/UnitedNations" component={UnitedNationsNews} />
                        <Route exact path="/news/Abudhabitamm" component={Abudhabi_tammNews} />
                        <Route exact path="/news/MOIUAE" component={MOIUAENews} />
                        <Route exact path="/news/SmartDubai" component={SmartDubaiNews} />
                        <Route exact path="/news/DubaiCourts" component={DubaiCourtsNews} />
                        <Route exact path="/news/DubaiChamber" component={DubaiChamberNews} />
                        <Route exact path="/news/USLabor" component={USLaborNews} />
                        <Route exact path="/news/UMOHRE_UAE" component={UMOHRE_UAENews} />
                        <Route exact path="/news/TheUAETRA" component={TheUAETRANews} />
                        <Route exact path="/newDubai_DED" component={Dubai_DEDNews} />
                        <Route exact path="/news/DIFCCourts" component={DIFCCourtsNews} />
                        <Route exact path="/news/BBCWorld" component={BBCWorld} />
                        <Route exact path="/news/CNNInternational" component={CNNInternationalNews} />
                        <Route exact path="/news/Alyaum" component={AlyaumNews} />
                        <Route exact path="/news/AlBayan" component={AlBayanNews} />
                        <Route exact path="/news/khaleejtimes" component={khaleejtimes} />
                        <Route exact path="/news/GulfNewsVideo" component={GulfNewsVideo} />
                        <Route exact path="/news/RegSprecher" component={RegSprecher} />
                        <Route exact path="/news/amerkel57" component={Amerkel57} />
                        <Route exact path="/news/KremlinRussia" component={KremlinRussia} />
                        <Route exact path="/news/RT_com" component={RT_com} />
                        <Route exact path="/news/Zaikandongxi" component={Zaikandongxi} />
                        <Route exact path="/news/ChinaDaily" component={ChinaDaily} />
                        <Route exact path="/news/CGTNOfficial" component={CGTNOfficial} />
                        <Route exact path="/news/emirates" component={EmiratesArline} />
                        <Route exact path="/news/kentuckycourts" component={kentuckycourts} />
                        <Route exact path="/news/Sharjahmedia" component={Sharjahmedia} />
                        <Route exact path="/news/SpacePortUAE" component={SpacePortUAE} />
                        <Route exact path="/news/Uaespaceagency" component={Uaespaceagency} />
                        <Route exact path="/news/ShjPolice" component={ShjPolice} />
                        <Route exact path="/news/UAEmGov" component={UAEmGov} />
                        <Route exact path="/news/AjmanDigitalGov" component={AjmanDigitalGov} />
                        <Route exact path="/news/RakCourts" component={RakCourts} />
                        <Route exact path="/news/RAKeGov" component={RAKeGov} />
                        <Route exact path="/news/PrinceSalman" component={Prince___Salman} />
                        <Route exact path="/news/AngelaMerkeICDU" component={AngelaMerkeICDU} />
                        <Route exact path="/news/EmmanuelMacron" component={EmmanuelMacron} />
                        <Route exact path="/news/IsraelArabic" component={IsraelArabic} />
                        <Route exact path="/news/AlsisiOfficial" component={AlsisiOfficial} />
                        <Route exact path="/news/ImranKhanPTI" component={ImranKhanPTI} />
                        <Route exact path="/news/Narendramodi" component={Narendramodi} />
                        <Route exact path="/news/OklaMuniLeague" component={OklaMuniLeague} />
                        <Route exact path="/news/ADJD_Official" component={ADJD_Official} />
                        <Route exact path="/news/CIA" component={CIA} />
                        <Route exact path="/news/SpaceX" component={SpaceX} />
                        <Route exact path="/news/Forbes" component={Forbes} />
                        <Route exact path="/news/ESA" component={ESA} />
                        <Route exact path="/news/NASA" component={NASA} />
                        <Route exact path="/news/IATA" component={IATA} />
                        <Route exact path="/news/CIJ_ICJ" component={CIJ_ICJ} />
                        <Route exact path="/news/CNBCi" component={CNBCi} />
                        <Route exact path="/news/BGOV" component={BGOV} />
                        <Route exact path="/news/UAE_PP" component={UAE_PP} />
                        <Route exact path="/news/wow" component={WowNews} />
                        <Route exact path="/news/AwqafUAE" component={AwqafUAENews} />
                        <Route exact path="/news/MOJ_UAE" component={MOJ_UAENews} />
                        <Route exact path="/news/dohsocial" component={DohSocialNews} />
                        <Route exact path="/news/dubaiexcellence" component={DubaiExcellence} />
                        <Route exact path="/blog-fullwidth" component={BlogFullWidth} />
                        <Route exact path="/signup" component={CustomerSignUpArea} />

                        {this.state.authFlag || localStorage.jwtToken || this.state.adminAuth || localStorage.adminToken1 ?
                        <>
                           {/*  <Route exact path="/profile" component={ProfilePage} /> */}
                            <Route exact path="/userpanel" component={userPanelPage} />
                            <Route exact path="/corporatefile" component={CorporateFilePage} />
                  <Route exact path="/customerfile" component={CustomerFilePage} />
                  <Route exact path="/invoiceView" component={UserInvoicePage} />
                       {/*  <Route exact path="/team" component={TeamPage} /> UserInvoicePage*/}
                        
                        </>
                        :''}
              {/* PageNotFoundPage */}
                          <Route exact path="/404" component={PageNotFoundPage} />
                          <Redirect to="/404" />
                    </Switch>
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default compose(
  withReducer("App1", reducer),
  connect()
)(App);   
 {/* <Route exact path="/founder" component={ComingSoon} /> */}