import * as reduxModule from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createReducer from './reducers';
import thunk from 'redux-thunk';

reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT';


const enhancer = compose(
    applyMiddleware(thunk)
);

const store = createStore(createReducer(), enhancer);

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
};

export default store;
