import React from "react";
import SectionTitle from '../Title'
import './style.scss'
import { Link } from 'react-router-dom'
import image from '../../images/vat.png'
const service78=
  {
      icon: image,
      title: 'VAT Law ',
      content: 'We provide services of VAT law as our team of lawyers is specialized in auditing and accounting and can deal with VAT/Tax regulations as per your need. Since UAE has imposed Value Added Tax in the form of law, the role of VAT consultants has surged. Our services include VAT consultation and advisory, VAT registration, VAT returns, VAT implementation, and VAT recovery. '
  }
const ServiceArea = ({ className, title, subTitle,services,readMore ,handleReadMore,handleLessService}) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                 <div className="serviceIcon">
                                    <i className={`fi ${service.icon}`}></i>
                                </div> 
                                <div className="serviceContent">
                    <h3><Link style={{color:'white'}} to={service.route}>{service.title}</Link></h3>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))    }
                    
                    {readMore ? "":       <div  className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                 <div className="serviceIcon">
                                     <img style={{width:'70px'}} src={service78.icon} />
                                {/*     <i className={`fi ${service78.icon}`}></i> */}
                                </div> 
                                <div className="serviceContent">
                                <h3>{service78.title}</h3>
                                    <p>{service78.content}</p>
                                </div>
                            </div>
                        </div> 
}
                </div>
            </div> 
          {readMore ?
             <center> {/* <Link to="/practice"> */}
             <div id="container12345" onClick={handleReadMore}>
                                             <button className="learn-more12345">
                                               <div className="circle12345">
                                                 <span className="icon12345 arrow12345"></span>
                                               </div>
                                               <p className="button-text12345">
                                                More Services{" "}
                                               </p>
                                             </button>
                                           </div>
              {/*    </Link> */}</center>
            :
            <center> {/* <Link to="/practice"> */}
            <div id="container12345" onClick={handleLessService}>
                                            <button className="learn-more12345">
                                              <div className="circle12345">
                                                <span className="icon12345 arrow12345"></span>
                                              </div>
                                              <p className="button-text12345">
                                               Less Services{" "}
                                              </p>
                                            </button>
                                          </div>
             {/*    </Link> */}</center>
            }
           
        </div>

    )
}
export default ServiceArea