import React from "react";
import FormForgotPass from "./sendEmailForm";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import "./style.css";
const ForgotPasswordArea = ({ className }) => {
  return (
    <div className="ForgotPasswordArea">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12"></div>
          <div className="col-12 col-lg-4">
            {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
             */}{" "}
            <h2 style={{ color: "white" }}>
              <img
                style={{ width: "40px", marginRight: "15px" }}
                src={require("../../images/signIn/01-01.png")}
              />
              Forget Password
            </h2>
            <img
              src={require("../../images/logo/logo2.png")}
              style={{ width: "120px", marginBottom: "20px" }}
            />
            <h6 style={{ color: "white", paddingBottom: "15px" }}>
              Enter your email address below,
              <br /> and we'll send you an email allowing you to reset it
            </h6>
            <FormForgotPass />
          </div>
          <div className="col-lg-4 col-12"></div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordArea;
