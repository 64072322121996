 import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const CommercialContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>		Commercial Law    </h3>
          <p>Commercial contracts, joint ventures, commercial agencies, agreements, franchises, and business law are included in our Commercial law services. Our team has expertise in this sector and can deal with any kind of legal challenge and can make a gamut of commercial agreements required by businesses. </p>
           <p>Our team has a complete understanding of the UAE legal and regulatory environment and has the unique ability to give the output of international standards. </p>
           <p>We are specialized in pleading before the commercial courts at all levels, these are some of the legal matters that we cover: </p>
           <h4>Commercial Contracts and Compliance</h4>
           <p>We have been in this faculty for 27 years and we understand the legal risks businesses have in UAE, our services range from reviewing the standard agreements to drafting complex commercial contracts. </p>
           <h4>Joint ventures </h4>
           <p>We advise our local and international clients on their joint ventures. Our team is aware of the region’s law terms and behavior related to business, including the foreign shareholding rules. </p>
           <h4>Commercial Agencies </h4>
           <p>Clients can take our advice on all the commercial agency matters including distribution agreements, franchise agreements, and other types of agreements.  </p>
           {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default CommercialContent