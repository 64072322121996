import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
import ContactArea from '../../../components/ContactArea'
// images
import breadcumb from '../../../images/breadcumb/Contact.jpg'
import BreakingNews from '../../../components/breaking'
import {Helmet} from "react-helmet";

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact us or Schedule an Appointment - Legal Emirates </title>
                <link rel="canonical" href="https://legalemirates.com/contact-us " />
                <meta name="description" content="Appointment with Lawyer or Consultant. Facing any kind of Ethical or Legal issue and want Law services, you are in the right place. " />
            </Helmet>

            <header className="headerArea">
          {/*   <BreakingNews />
                 <HeaderTop className="headerTop" /> */}
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contact"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
<ContactArea
                className="contactArea"
            />
       {/*      <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h3>Our Contacts</h3>
                                <p>With more than 27 years of experience as a law firm, our lawyers have built instinctive professionalism within themselves that you won't find anywhere else and still they try to solve every legal issue with full devotion and special care. So, don’t waste time and contact us for any legal solution. We are looking forward to your response.
                                </p>
                                <h4>Address</h4>
                               <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.21488290087!2d55.284316514328005!3d25.22968643657449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43a67cbd77fd%3A0xff05d8a3fdea211b!2zV293IEVsZWN0cm9uaWMgVHJhbnNwb3J0IFNlcnZpY2VzIER1YmFpINmI2KfZiCDZhNmE2K7Yr9mF2KfYqiDYp9mE2KXZhNmD2KrYsdmI2YbZitipINmE2YTZhtmC2YQ!5e0!3m2!1sen!2s!4v1584691630511!5m2!1sen!2s" target="_blank"> <span style={{color:'black'}}>34th Floor Burj Al Salam Tower, Trade Centre1, Sheikh Zayed Road Dubai.</span></a>
                                <h4>Phone</h4>
                                <span> <a href="tel:+97142555111" style={{color:'#565656'}}><i className="fa fa-phone"></i> +9714 2555111</a> </span>
                                <h4>Fax</h4>
                                <span> <a href="fax:+97142368888" style={{color:'#565656'}}><i className="fa fa-envelope-open"></i> +9714 2368888</a> </span>
                                <h4>Email</h4>
                                <span> <a href="mailto:info@legalemirates.com" style={{color:'#565656'}}><i className="fa fa-envelope"></i> info@legalemirates.com</a></span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactUSForm">
                            <h3>Appointment Schedule here</h3>
<div class="calendly-inline-widget" data-url="https://calendly.com/legalemirates/1" style={{width:'100%',height:'530px'}}></div>
<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>

                              <h3>Quick Contact Form</h3>
                                <Form
                                    addressInfo={true}
                                /> 
                            </div>
                        </div>
                    </div>
                   <div style={{width:'100%',height:'500px',marginTop:'50px',marginBottom:'-100px'}} >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.21488290087!2d55.284316514328005!3d25.22968643657449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43a67cbd77fd%3A0xff05d8a3fdea211b!2zV293IEVsZWN0cm9uaWMgVHJhbnNwb3J0IFNlcnZpY2VzIER1YmFpINmI2KfZiCDZhNmE2K7Yr9mF2KfYqiDYp9mE2KXZhNmD2KrYsdmI2YbZitipINmE2YTZhtmC2YQ!5e0!3m2!1sen!2s!4v1584691630511!5m2!1sen!2s" width="100%" height="400" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                
                </div> 
                </div>
            </div> */}

          {/*   <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage