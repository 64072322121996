import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../Form/style.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  

  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class InvoiceTerms extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        
        expired:true,
    } 
  }

    render() {

       
        return (
          <div>
          
                <div className="password-link-expiration-page">
                 <center>
                 <img style={{width:'150px',marginTop:'30px'}}  src={require('../../images/logo/logo2.png')} />
                <h1>Invoice Terms & Conditions <br/> <span style={{fontSize:'16px'}}>(BADER AL ZAABI ADVOCATE OFFICE) <br /> (for Opening a case)</span></h1>
                
                  </center>
                 <div className="invoice-terms-condtions" >
               <ol>
                  <li>   <p>Kindly retain the original computer generated receipt for any upcoming claims. If original receipt is lost, no claims or reprint of the receipt will be entertained.</p>
              </li>
              <li>Claims or objections to be made in writing within 10 days from the date of the invoice issued.</li>
              <li>If no claims or objections are made within the previously mentioned period in clause, then it shall be deemed as an approval of consumer in relation to the provided transaction and their values.</li>
              <li>Documents for the transactions done to be forwarded along with the original invoice.</li>
              <li>The maximum liability in the event of any claims or objections is limited to the value of the claimed transactions, which is/are mentioned in the invoice.</li>
              <li>BADER HAMAD AL ZAABI ADVOCATES & LEGAL CONSULTANTS reserve the right to review and aimed the above terms from time as it judges fit, and as per the applicable laws.</li>
                </ol>
                 </div>
                </div>   
          </div>
        )
    }
}
 
export default InvoiceTerms