import React from "react";
import Form from "../Form";
import "./style.scss";
import "./style.css";
import Appointment from "./appointment";
import Clock from "react-live-clock";
const ContactArea = ({ className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <div className="contactInfo">
              <span>For Our Honorabe Clients</span>
              <h2>Appointment Scheduling</h2>
              <h6>
                Call us 24/7 at{" "}
                <a href="tel:+97142555111" style={{ color: "white" }}>
                  <i className="fa fa-phone"></i> +9714 2555111
                </a>{" "}
                or{" "}
                <a
                  style={{ color: "white" }}
                  href="https://us04web.zoom.us/j/8109890170"
                  target="_blank"
                >
                  {" "}
                  Live Meeting
                </a>{" "}
                or fill out the form.
              </h6>
              <p style={{ color: "white" }}>
                Dubai Time:{" "}
                <Clock
                  format={"dddd, MMMM Do YYYY, h:mm:ss a"}
                  timezone={"Asia/Dubai"}
                />
              </p>
              {/*   <!-- Calendly inline widget begin --> */}
              {/*  <Appointment /> */}
              <br />
              <center>
                {" "}
                <a
                  style={{
                    color: "white",
                    backgroundColor: "#D4C291",
                    padding: "5px",
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                  href="https://calendly.com/legal_emirates"
                  target="_blank"
                >
                  Appointment with a Lawyer or Consultant{" "}
                </a>
              </center>

              <p>
                Facing any kind of Ethical or Legal issue and want Law services,
                you are at the right place. Visit us for consultation, our
                attorneys will diagnose the matter using their professional
                techniques and advise you the best outcome possible. Schedule
                your live meeting with one of the practiced lawyer regarding
                services and concerns about the case.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
             */}
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactArea;
