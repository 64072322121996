import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
const TermsAndConditions = ({ className }) => {
    return (
        <div className="TermsConditionsArea">
            <div className="container">
                <div className="row">
                  
                    <div className="col-12 col-lg-10">
                          <h2 style={{color:'white',marginTop:'20px'}}>Legal Emirates Terms & Conditions</h2>
                          <p style={{color:'whitesmoke'}}>These terms and conditions apply to all the users and visitors who want to use the Legalemirates.com website operated and owned by BADER HAMAD AL ZAABI Advocate & Legal Consultations. Kindly read the terms, conditions, and go through all the points cautiously before taking our services to avoid any trouble. Reading this page will help you know the use of the content of this Website, legal rights, and obligations.</p>
                          <p style={{color:'whitesmoke'}}>Whilst reading these conditions if at any point you feel disagree with any of the points then kindly discontinue using this Website or contact us immediately to avoid any inconvenience. The content of this Website always keeps updating including the privacy policies and terms and conditions so keep yourself up to date and try not to miss any important information during the use of the Website.

</p>
                           <h3  style={{color:'white'}}>1.	Use of the Website </h3>
                           <p  style={{color:'whitesmoke'}}>The content on this Website is prepared and owned by Legal Emirates Law Firm. Most of the material on this Website is general information and the purpose is to give visitors an idea to understand the law website and the basic law terms. Before acting on any of the information written on this Website on your own, you must take proper legal advice from an attorney or legal authority.</p>
                           <h3  style={{color:'white'}}>2.	Intellectual Property Right </h3>
                           <p  style={{color:'whitesmoke'}}>All types of material on this Website including written content, logos, trademarks, footage, and any other thing is the property of the Legal Emirates. Yes, you have limited access to this content and you can use this material to some extent.</p>
                           <ul style={{color:'whitesmoke'}}>
                               <li>-You can download, print, save data of the Website apart from just viewing and reading it;</li>
                               <li>-You can share our links on your social media;</li>
                               <li>-You cannot republish, reproduce and copy the Content;</li>
                               <li>-You have no right or license to use the information given on the Website.</li>
                            
                           </ul>
                           <h3  style={{color:'white'}}>3.	Communication </h3>
                           <p  style={{color:'whitesmoke'}}>You can communicate with us in different ways. For instant questions, there is a chat-box on the website you can ask whatever you want and share your information. However, keep that in mind, do not share your confidential information via emails or chat-boxes, and keep your confidential data to yourself until you make face-to-face meetings with our attorney or legal consultant. Therefore, we advise you to be careful while sharing your confidential personal information about your case.</p>
                           <h3  style={{color:'white'}}>4.	Site Access</h3>
                           <p  style={{color:'whitesmoke'}}>You have limited access to our Website. You are not allowed to make any changes to the Website. If you have any kind of issue or you find anything non-ethical, contact us, we will respect your suggestion and will be glad to hear from you.</p>
                           <h3  style={{color:'white'}}>5.	Third-Party Links </h3>
                           <p  style={{color:'whitesmoke'}}>This Website contains links to third-party sites as well. We do not recommend the privacy practices of third-party sites that are linked to this Website. You must know that you enter third-party sites at your own risk.</p>
                           <h3  style={{color:'white'}}>6.	Accuracy of Information </h3>
                           <p  style={{color:'whitesmoke'}}>We try to assure that the information provided on this Website is accurate and errorless. However, it is quite possible that you will find a mistake as it is just human to err. We cannot guarantee the complete accuracy and reliability of the information. If you wish to suggest a solution in case you find some error, contact us we will be grateful for receiving your suggestion and will try to rectify it as soon as possible.</p>
                           <p  style={{color:'whitesmoke'}}>We advise you not to act on your own according to the information provided on this Website. Hire a professional legal consultant, lawyer, or advocate before taking a legal step.</p>
                           <h3  style={{color:'white'}}>
7.	Limitation of liability 
</h3>
                           <p  style={{color:'whitesmoke'}}>To the extent permitted by the law, Legal Emirates will not be liable for any direct, indirect, incidental, or consequential damage or loss in case of using or inability to using the website and the website content.</p>
                         
    <h3  style={{color:'white'}}>
    8.	Jurisdiction
</h3>
                           <p  style={{color:'whitesmoke'}}>Whilst using this Website if there is occurring of any kind of dispute, such dispute is subject to the laws of the UAE.  </p>
                          <br />
                          <br />
                           <p  style={{color:'whitesmoke'}}>Thank you for reading and implementing all the legal terms of the Website and thank you for your cooperation. We hope this Website is helpful for you and it answers all your queries and doubts as well. For more queries and consultation, feel free to contact us.
  </p>
 
                    </div>
                    <div className="col-lg-2 col-12">
                    
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default TermsAndConditions