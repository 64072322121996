import React from "react";
import FormSignIn from "./signInForm";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import "./style.css";
const CorporateSignINArea = ({ className }) => {
  return (
    <div className="SignInAreaCorporate">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12"></div>
          <div className="col-12 col-lg-5">
            {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
             */}{" "}
            <h2 style={{ color: "white" }}>
              <img
                style={{ width: "40px", marginRight: "15px" }}
                src={require("../../images/signIn/01-01.png")}
              />
              Corporate Sign In
            </h2>
            <img
              src={require("../../images/logo/logo2.png")}
              style={{ width: "120px", marginBottom: "15px" }}
            />
            <h6 style={{ color: "white", paddingBottom: "5px" }}>
              Welcome to Online Access!
            </h6>
            <FormSignIn />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CorporateSignINArea;
