import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import SectionTitle from '../../../components/Title'
import CounsellingVisually from '../../../components/counsellingVisually'
import {Helmet} from "react-helmet";

import './style.scss'
const CounsellingVisuallyWriting = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Paid Visual Counseling Service - Legal Emirates </title>
                <link rel="canonical" href="https://legalemirates.com/counselling-visually " />
                <meta name="description" content="If you are looking for visual consultations for your corporate or commercial issues, take live counseling here at Legal Emirates. " />
            </Helmet>

            <header className="headerArea">
           {/* <BreakingNews />
            <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
           <CounsellingVisually
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default CounsellingVisuallyWriting