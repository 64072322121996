import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import RecentPosts from '../../../components/RecentPosts'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
import Twitter from '../../../components/twitter'
// images
import breadcumb from '../../../images/breadcumb/news-2.jpg'
import blog3 from '../../../images/blog-details/blog-3.jpg'
import {Helmet} from "react-helmet";

import './style.scss'



const Seekadvice = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs - Legal Emirates  </title>
                <link rel="canonical" href="https://legalemirates.com/blogs/Seek-advice " />
                <meta name="description" content="Learn more about law terms and find about Legal Emirates attorneys, law teams and many more through our blogs. " />
            </Helmet>

            <header className="headerArea">
 {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
            <div className="blogPostArea blogPostLeftArea" style={{paddingTop:'45px'}}>
                <center>
                   <h1 style={{fontWeight:'550'}}>  Seek advice</h1> 
                   <h2 style={{fontWeight:'550'}}>    Seek advice from experts, not politicians</h2>
                   <p style={{fontWeight:'550'}}>16 May 2020/Dubai</p>
                </center>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                     
                <div  className="blogPostWrapper1">
                    <div className="blogPostImg">
                        <img src={blog3} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                    <p style={{fontWeight:'550'}}>In the time like this when the whole world is facing a global problem that has never occurred before hundreds of doctors, scientists, politicians, and even teachers, everyone is speaking about the virus and giving his or her advice on how should we deal with COVID-19. And the sad part is that people are listening to such people around the world for instance people are expecting their political leaders to come forward and guide them. The message or advice from such persons amplifies and changes its theme every time it circulates in the media and that is a very big concern. In the first place even don’t know if the advice our leaders are giving us is authentic and attested by experts or not. So, we must not act blindly on what our leaders are saying. </p>
                    <p style={{fontWeight:'550'}}>There are experts whom we must listen to in this time of global crisis. Large health organizations like World Health Organization and the Centers for Disease Control and Prevention have a bunch of qualified scientists who are total experts in the study of COVID-19 and they are aware of day to day situations around the world. </p>
                    <p style={{fontWeight:'550'}}>"Public officials need to listen to scientists and consider this epidemic as the most serious health event to occur in 100 years," Steffanie Strathdee, associate dean of global health sciences at the University of California San Diego. </p>
                    <p style={{fontWeight:'550'}}>In this article, we will only share the basic needed information that is authentic and has been delivered by experts around the globe.</p>
                    <h4 style={{fontWeight:'550'}}> Wash your hands frequently</h4>
                    <p style={{fontWeight:'550'}}>Washing your hands may be the most important of all the safety precautions. Regularly and thoroughly clean your hands with soap. </p>
                    <p style={{fontWeight:'550'}}>That will kill the virus if it’s on your hands.</p>
                    <h4 style={{fontWeight:'550'}}>    Maintain social distance </h4>
                    <p style={{fontWeight:'550'}}>Always try to maintain at least three feet distance when you are outside or passing by some people and especially if someone you see is coughing or sneezing. </p>
                    <p style={{fontWeight:'550'}}>When someone coughs or sneezes small liquid droplets come out of their mouth or nose which may have infected with a virus and if you are near him there are a lot of chances that the air you are inhaling is carrying those droplets. </p>
                    <h4 style={{fontWeight:'550'}}>  Do not touch eyes, nose, and mouth</h4>
                    <p style={{fontWeight:'550'}}>Hands are one of the biggest causes of spreading this virus. So, try not to bring your hands near the eyes, nose, and mouth because these days there is a good chance that your hands are having the coronavirus. </p>
                    <h4 style={{fontWeight:'550'}}> Follow respiratory hygiene </h4>
                   <p style={{fontWeight:'550'}}>Make sure you and the people around you follow good respiratory hygiene. Cover your mouth with something while coughing or sneezing use tissues or bent elbow if you have nothing around. </p>
                   <h4 style={{fontWeight:'550'}}>Seek medical care if you are not feeling well </h4>
                   <p style={{fontWeight:'550'}}>Staying home is the first best thing you should do if you are not feeling well as if you have a fever, cough and breathing issues, etc. Call the medical staff they will come to diagnose you or take you to hospital. Do not make move on your own follow the directions of your local authority. </p>
                   <h4 style={{fontWeight:'550'}}>   Stay informed and follow the direction given by your healthcare provider</h4>
                   <p style={{fontWeight:'550'}}>Stay informed all the time about the latest activities related to coronavirus in your area. Follow the advice given by your local health department or national health ministry on how to keep yourself and others safe from COVID-19. </p>
                   <p style={{fontWeight:'550'}}>If you have been to a place which is now a corona outbreak center or any of your friend or a family member has become positive for coronavirus, the very first thing you must do is to isolate yourself from others and wait for 14 days. </p>
                  </div>
                </div>
           
                  
                        </div>

                    </div>
                </div>
            </div>
           {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default Seekadvice