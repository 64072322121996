import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Portfolio from "../../../components/Portfolio";
import ContactArea from "../../../components/ContactArea";
import { Link } from "react-router-dom";
import BreakingNews from "../../../components/breaking";
import ImageGallery from "../../../components/imageGallery";
// images
import breadcumb from "../../../images/breadcumb/ATTORNEYS-single.jpg";
import team from "../../../images/Attorneys-single/1.jpg";
import portfolio1 from "../../../images/studies/1.jpg";
import portfolio2 from "../../../images/studies/2.jpg";
import portfolio3 from "../../../images/studies/3.jpg";
import {Helmet} from "react-helmet";

import "./style.scss";

const breadcumbMenu = [
  { name: "Home", route: "/" },
  { name: "Attorneys", route: "/attorneys" },
  { name: "Attorneys Details" },
];
const teamMembarInfo = [
  { level: "Position: ", text: "Founder & Lawyer" },
  {
    level: "Practice Area: ",
    text: "Criminal, family, commercial, and real estate laws, etc.",
  },
  { level: "Experience: ", text: "27 Years" },
  /*    { level: 'Address: ', text: '34th Floor Burj Al Salam Tower, Trade Centre1, Sheikh Zayed Road Dubai.' },
    { level: 'Phone: ', text: '+971 42555111' },
    { level: 'Email: ', text: 'info@legalemirates.com' },
    { level: 'Fax: ', text: ' +971 42368888' }, */
];
const menus = [
  {
    level: "Address: ",
    name: "34th Floor Burj Al Salam Tower, Trade Centre1, Sheikh Zayed Road Dubai.",
    href: "https://www.google.com/maps/place/Wow+Electronic+Transport+Services+Dubai+%D9%88%D8%A7%D9%88+%D9%84%D9%84%D8%AE%D8%AF%D9%85%D8%A7%D8%AA+%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9+%D9%84%D9%84%D9%86%D9%82%D9%84%E2%80%AD/@25.2298036,55.284274,17z/data=!4m8!1m2!2m1!1s34th+Floor+Burj+Al+Salam+Tower,+Trade+Centre1,+Dubai.!3m4!1s0x3e5f43a67cbd77fd:0xff05d8a3fdea211b!8m2!3d25.2296816!4d55.2865052",
  },

  {
    level: "Email: ",
    name: "info@legalemirates.com",
    href: "mailto:info@legalemirates.com",
  },
  { level: "Phone: ", name: "+9714 2555111", href: "tel:+97142555111" },
  { level: "Fax: ", name: "+9714 2368888", href: "fax:+97142368888" },
];
const teamContents = [
  "He worked for the Dubai Public Prosecution since 1993 until he left work and practiced law in the profession from 2008 to the present day.",
];

const portfolioItem = [
  { images: portfolio1, title: "General Service", subtitle: "Corporate" },
  { images: portfolio2, title: "Personal Issue", subtitle: "General" },
  { images: portfolio3, title: "Business Accounting", subtitle: "Business" },
];

const SingleTeamPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bader Al Zaabi - Founder & Lawyer - Legal Emirates </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/founder "
        />
        <meta
          name="description"
          content="Graduated from a prestigious university, decades of legal experience in various law branches, Bader Al Zaabi is the founder of Legal Emirates. "
        />
      </Helmet>

      <header className="headerArea">
        {/*   <BreakingNews />
                 <HeaderTop className="headerTop" />  */}
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Our Founder"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <div className="singleTeamArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="singleTeamImgWrap">
                    <div className="singleTeamImg">
                      <img src={team} alt="" />
                    </div>
                    <h4>Bader al Zaabi</h4>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="singleTeamContent">
                    <h4>Important Information</h4>
                    <ul className="teamMembarInfo">
                      {teamMembarInfo.map((teamInfo, i) => (
                        <li key={i}>
                          <span>{teamInfo.level}</span>
                          {teamInfo.text}
                        </li>
                      ))}

                      {menus.map((item, i) => (
                        <li key={i}>
                          <span>{item.level}</span>
                          {
                            <a style={{ color: "#666666" }} href={item.href}>
                              {item.name}
                            </a>
                          }{" "}
                        </li>
                      ))}
                    </ul>
                    <ul className="socialShare">
                      <li>
                        <a
                          href="https://www.facebook.com/Legal-Emirates-Bader-Al-Zaabi-111584597371288"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/LegalAlZaabi"
                          target="_blank"
                        >
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/legalalzaabi/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/legalalzaabi/"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.pinterest.com/LegalAlZaabi/"
                          target="_blank"
                        >
                          <i className="fa fa-pinterest" aria-hidden="true"></i>
                        </a>
                      </li>
                      {/*      <li><a href="https://www.pinterest.com/wowets2020/" target='_blank'><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>*/}
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCsBvfRBxFrgBTezFu3d0KSQ/"
                          target="_blank"
                        >
                          <i
                            className="fa fa-youtube-play"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="singleTeamInfo">
                    <h3>Education</h3>
                    <p>
                      Graduate of a prestigious university, College of Law and
                      Economics, Dubai College of Sharia and Arabic Language at
                      Academic University Dubai, approved by the Judicial
                      Institute in the United Arab Emirates. He has many
                      experiences in various commercial legal practices inside
                      and outside the country.
                    </p>
                    <h5>Personal Experience </h5>
                    <ul>
                      {teamContents.map((teamContent) => (
                        <li key={teamContent}>{teamContent}</li>
                      ))}
                    </ul>
                    <h5>Language</h5>
                    <span>Arabic & English (fluent)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ImageGallery */}
      <ImageGallery
        title="Image/Gallery"
        subTitle="Art & Gallery"
        className="teamArea teamAreaStyleTwo"
        noGutters={true}
      />
      {/* <Portfolio
                title="Participated Case"
                subTitle="Here Our Best Work"
                fullWidth={true}
                portfolioItem={portfolioItem}
                className="portfolioArea portfolioAreaStyleFour"
            /> */}
      {/*   <ContactArea
                className="contactArea"
            /> */}
      {/*      <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default SingleTeamPage;
