import React, { useEffect, useState } from "react";
import FormCustomerSignUp from "./customerSignUpForm";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import "./style.css";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
function CustomerSignUpArea() {
  const toastId = React.useRef(null);
  const [values, setValues] = React.useState(null);
  const loading = useSelector(({ CustomerSignUp }) => {
    return CustomerSignUp.customerSignUpReducer.isLoading;
  });

  const errMsg = useSelector(
    ({ CustomerSignUp }) => CustomerSignUp.customerSignUpReducer.errMsg
  );
  const signUpResponce = useSelector(
    ({ CustomerSignUp }) => CustomerSignUp.customerSignUpReducer.data
  );

  useEffect(() => {
    if (signUpResponce.length > 1) {
      console.log(signUpResponce, "signUpResponce");
    }
  });
  return (
    <div className="SignUpArea">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
             */}{" "}
            <h2 style={{ color: "white", marginBottom: "30px" }}>
              <img
                style={{ width: "40px", marginRight: "15px" }}
                src={require("../../images/signIn/02-01.png")}
              />
              Customer Sign Up
            </h2>
            <FormCustomerSignUp toastId={toastId} />
          </div>
          <div className="col-lg-4 col-12"></div>
        </div>
      </div>
    </div>
  );
}
export default withReducer("CustomerSignUp", reducer)(CustomerSignUpArea);
