import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Portfolio from "../../../components/Portfolio";
// images
import breadcumb from "../../../images/breadcumb/Case-Stadies.jpg";
// images
import portfolio1 from "../../../images/studies/1.jpg";
import portfolio2 from "../../../images/studies/2.jpg";
import portfolio3 from "../../../images/studies/3.jpg";
import portfolio4 from "../../../images/studies/4.jpg";
import portfolio5 from "../../../images/studies/5.jpg";
import BreakingNews from "../../../components/breaking";
import Testmonial from "../../../components/Testmonial";
import { Helmet } from "react-helmet";

import "./style.scss";

const breadcumbMenu = [
  { name: "Home", route: "/" },
  { name: "Recent Case Studies" },
];

const portfolioItem = [
  { images: portfolio1, title: "General Service", subtitle: "Corporate" },
  { images: portfolio2, title: "Personal Issue", subtitle: "General" },
  { images: portfolio3, title: "Business Accounting", subtitle: "Business" },
  { images: portfolio4, title: "Accounting issue", subtitle: "Criminal" },
  {
    images: portfolio5,
    title: "Business Accounting",
    subtitle: "Family Issue",
  },
  { images: portfolio1, title: "General Service", subtitle: "Corporate" },
  { images: portfolio2, title: "Personal Issue", subtitle: "General" },
  { images: portfolio3, title: "Business Accounting", subtitle: "Business" },
  { images: portfolio4, title: "Accounting issue", subtitle: "Criminal" },
];

const PortfolioPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recent Case Studies - Legal Emirates </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/case-studies "
        />
        <meta
          name="description"
          content="Excellent performances in our recent case studies with 100% client satisfaction, Legal Emirates provides preeminent legal services to its customers. "
        />
      </Helmet>

      <header className="headerArea">
        {/*       <BreakingNews />
                <HeaderTop className="headerTop" /> */}
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Case Studies"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <Portfolio
        title="Our Recent Case Studies"
        subTitle="Here Our Best Work"
        fullWidth={true}
        portfolioItem={portfolioItem}
        className="portfolioArea portfolioAreaStyleTwo"
      />
      <Testmonial className="testmonialArea" />
      {/*    <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default PortfolioPage;
