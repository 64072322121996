import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CUSTOMER_EMAIL_VERIFICATION = "CUSTOMER_EMAIL_VERIFICATION";
export const CUSTOMER_EMAIL_VERIFICATION_LOADING =
"CUSTOMER_EMAIL_VERIFICATION_LOADING";
export const CUSTOMER_EMAIL_VERIFICATION_LOADING_FAILED =
"CUSTOMER_EMAIL_VERIFICATION_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const customerEmailVerifyLoading = ms => ({
    type: CUSTOMER_EMAIL_VERIFICATION_LOADING,
    payload: ms
    });
    
    export const customerEmailVerifyLoadingFailed = ms => ({
    type: CUSTOMER_EMAIL_VERIFICATION_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function customerEmailVerify(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/users/auth/verifyEmail`,obj);
    return dispatch => {

    dispatch(customerEmailVerifyLoading());
   console.log(request,"request")
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response customerEmailVerify")
    dispatch({
    type: CUSTOMER_EMAIL_VERIFICATION,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        customerEmailVerifyLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(customerEmailVerifyLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(customerEmailVerifyLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(customerEmailVerifyLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


