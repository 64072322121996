import React, { useState, Fragment,useEffect } from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import logo1 from '../../images/logo/logo12.png'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import './style.scss'
import './style.css'
const cetagoryMenu = [
    { name: 'Criminal Law ', route: "speciality-criminal" },
    { name: 'Business and corporate', route: "speciality-business" },
    { name: 'International/Interpol', route: "speciality-interpol" },
    { name: 'Labor Law', route: "speciality-labor" },
    { name: 'Dispute & financial ', route: "speciality-financial" },
    { name: 'Family Law', route: "speciality-family" },
    { name: 'Arbitration Law', route: "speciality-arbitration" },
    { name: 'Construction and Infrastructure ', route: "speciality-construction" },
    { name: 'Real Estate Law', route: "speciality-real-state" },
    { name: 'Corporate & Mergers  ', route: "speciality-merger" },
    { name: 'Intellectual Property', route: "speciality-poverty" },
    { name: 'Commercial Law ', route: "speciality-commercial" },
    { name: 'Capital Markets ', route: "speciality-market" },
    { name: 'Insurance Cases  ', route: "speciality-insurance" },
    { name: 'Family business   ', route: "speciality-family-business" },
    { name: 'Tax Law ', route: "speciality-tax" },
    { name: 'Private ClientService  ', route: "speciality-notary" },
    { name: 'Establishing Businesses ', route: "speciality-establish-business" } ,
    { name: 'Anti-Money Laundering  ', route: "speciality-money-laundering" },
    { name: 'Drug Law ', route: "speciality-drug" },
    { name: 'Multinational Companies ', route: "speciality-multinational-company" },
]

const HeaderBottom = props => {
    const [search, setSearch] = useState()
    const [responsive, setResponsive] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [logoFlag, setlogoFlag] = useState(false)
    const [user, setUser] = useState('')
    const [admin, setAdmin] = useState('')
    const [authFlag, setAuthFlag] = useState(false)
    const [authFlagAdmin, setAuthFlagAdmin] = useState(false)
    const dispatch = useDispatch();


/* authRiderReducer */

  const CurrentUser = useSelector(
        ({ HeaderBottom1 }) => HeaderBottom1.authRiderReducer
         ); 
          useEffect(() =>
   {
    if(CurrentUser.isAuthenticated){
        setAuthFlag(true)
        setUser(CurrentUser.isAuthenticated)
      /*   console.log(CurrentUser,"CurrentUser header") */
    }
}
  );

/* AdminAuthRiderReducer */
const CurrentAdmin = useSelector(
    ({ HeaderBottom1 }) => HeaderBottom1.AdminAuthRiderReducer
     ); 
      useEffect(() =>
{
if(CurrentAdmin.isAuthenticated){
    setAuthFlagAdmin(true)
    setAdmin(CurrentAdmin.isAuthenticated)
    console.log(CurrentUser,"CurrentAdmin header")
}
}
);

    
    const submitHandler = e => {
        e.preventDefault()
        console.log(search)
    }
    const clickHandler = () => {
        setTrigger(!trigger)
    }
    const responsiveHandler = () => {
        setResponsive(!responsive)
    }
    // When the user scrolls the page, execute myFunction 
window.onscroll = function() {myFunction()};


function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("myBar").style.width = scrolled + "%";

}
function handleLanguage(){
    
    /* alert("yes")
    window["googleTranslateElementInit"](""); */
  
}
window.setInterval(()=>{
    if(localStorage.hasOwnProperty('language')){
      if (localStorage.language == "Arabic"){
setlogoFlag(true)
      }else{
        setlogoFlag(false)
      }
    }

  
},100);
function logoutCustomer(){
    localStorage.setItem('loginFlag','')
    dispatch(Actions.logoutUser())
}
function logoutAdmin(){

   
    dispatch(Actions.logoutAdmin())
 
    /* logoutAdmin */
}
    return (
        <div className={props.className}>
             <div className="progress-container">
             <div className="progress-bar" id="myBar"></div>
             </div>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-lg-3 col-md-10 col-sm-6 col-8">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logoFlag?logo1:logo}  alt="" /> {/* <span className="logo-text" >Legal Emirates</span> */}
                                </NavLink>
                            </div>
                        </div>
                        {/* mainMenuWrap8888 */}
                        <div className={responsive ? "col-lg-9 responsiveWrapper active" : "col-lg-9 responsiveWrapper"}>
                            <ul className={logoFlag ? "mainMenuWrap" :"mainMenuWrap"} >
                               {/*  <li><NavLink exact to='/'>Home</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/'>Home One</NavLink></li>
                                        <li><NavLink exact to='/home-two'>Home Two</NavLink></li>
                                        <li><NavLink exact to='/home-three'>Home Three</NavLink></li>
                                    </ul>
                                </li> */}
                                <li onClick={handleLanguage}><NavLink exact to='/'>Home</NavLink></li>
                                <li><NavLink exact to='/about-us'>About Us</NavLink></li>
                                <li className="our-services-mobile"><NavLink exact to='/practice'>Our Services</NavLink>
                                    <ul className="subMenu">
                                    <li><NavLink exact to='/practice'>  All Services</NavLink></li>   
                                    <li><NavLink exact to='/practice-details'>Single Service</NavLink></li>
                                       
                                        <li><NavLink exact to='/industries'>  Corporate Services </NavLink></li>
                                    </ul>
                                </li>
                                <li className="our-services-pc"><NavLink exact to='/practice'>Our services</NavLink>
                                    <ul className="subMenu1">
                                    <div className="row">
                                <div className="col-lg-3 col-12">
                                <li><NavLink className="services-practive-border" exact to='/practice'>  All Services</NavLink></li>   
                                 {/*    <li><NavLink className="services-practive-border1" exact to='/practice-details'> Single Service</NavLink></li>
                                      */}  
                                        <li><NavLink className="services-practive-border2" exact to='/industries'>  Corporate  Services</NavLink></li>
                                  </div>  
                                  <div className="col-lg-9 col-12">
                                  <div className="row">
                                      {cetagoryMenu.map((item)=>{
                                          return  <div className="col-lg-4 col-12">
                                          <li ><NavLink onClick={()=>window.scrollTo(0,1000)} style={{borderBottom:'none',fontSize:'14px'}} exact to={item.route}>{item.name} </NavLink></li>
                                           </div>
                                      })}
                                  
                                    
                                    </div>
                                  </div> 
                                
                                  </div>
                                  
                                    </ul>
                                </li>
                              
                              {/*   <li><NavLink exact to='/case-studies'>Cases</NavLink>
                                    <ul className="subMenu">
                                      
                                        <li><NavLink exact to='/case-studies-details'>Case single</NavLink></li>
                                        <li><NavLink exact to='/case-studies'>Cases Corporate</NavLink></li>
                                    </ul>
                                </li> */}
                                <li><NavLink exact to='/news'>News</NavLink>
                                   <ul className="subMenu">
                                       
                                       
                                        <li><NavLink exact to='/news'> News Room</NavLink></li>
                                      {/*   <li><NavLink exact to='/recentnews'> Recent News</NavLink></li> */}
                                        <li><NavLink exact to='/blogs'> Blogs </NavLink></li>
                                        {/*  <li><NavLink exact to='/blog-fullwidth'>Blog FullWidth</NavLink></li>*/}
                                    </ul> 
                                </li>
                                <li><NavLink  rel="canonical" exact to='/founder'>Our Team</NavLink>
                                    <ul className="subMenu">
                                   {/*  <li><NavLink exact to='/founder'></NavLink></li> */}
                                    <li><NavLink  rel="canonical"  exact to='/founder'>The Founder</NavLink></li> 
                                    <li><NavLink  exact to='/team'>Legal Team</NavLink></li> 
                                        <li><NavLink exact to='/attorneys'>Experts and Specialists</NavLink></li>
                                      
                                    
                                    </ul>
                                </li>
                                <li><NavLink exact to='/contact-us'>Contact Us</NavLink></li>
                               {authFlag || authFlagAdmin ? '' :
                                <li><NavLink exact to='/signin'>Sign In</NavLink>
                                <ul className="subMenu">
                                    <li><NavLink exact to='/signin'> Customer Sign In</NavLink></li> 
                                    <li><NavLink exact to='/corporate-signin'>Corporate Sign In</NavLink></li>
                                    <li><NavLink exact to='/administration'>Administration Login</NavLink></li>
                                </ul>
                            </li>
                               }
                               {authFlag || authFlagAdmin ? '':
                                 <li><NavLink exact to='/signup'>Sign Up</NavLink>
                                 <ul className="subMenu">
                                     <li><NavLink exact to='/signup'> Customer Sign Up</NavLink></li>
                                     <li><NavLink exact to='/corporate-signup'> Corporate Sign Up</NavLink></li>
                                 </ul>
                             </li>
                            }
                               {authFlag?    <li><NavLink exact to='/userpanel'><img style={{width:'25px',borderRadius:'50%',marginRight:'10px'}} src={require('../../images/img_avatar2.png')} />{user}</NavLink>
                                 <ul className="subMenu">
                                 <li><NavLink exact to='/userpanel'>Customer Account</NavLink></li>
                                     <li><NavLink exact to='/signin' onClick={logoutCustomer}><i class="fa fa-power-off"></i> Logout</NavLink></li>
                                    
                                 </ul>
                             </li>
                             :''
                              
                            }
                            {authFlagAdmin? <li><NavLink exact to='/admin'><img style={{width:'25px',borderRadius:'50%',marginRight:'10px'}} src={require('../../images/img_avatar2.png')} />{admin}</NavLink>
                                 <ul className="subMenu">
                                 <li><NavLink exact to='/admin'>Admin</NavLink></li>
                                     <li><NavLink exact to='/admin' onClick={logoutAdmin}><i class="fa fa-power-off"></i> Logout</NavLink></li>
                                    
                                 </ul>
                             </li> :''}
                              
                               {/*  <li><NavLink exact to='/customerfile'>Open a file</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/customerfile'> Customer file</NavLink></li> 
                                        <li><NavLink exact to='/corporatefile'>Corporate  file</NavLink></li>
                                    </ul>
                                </li> */}
                              
                               {/*  <li>   <div style={{marginBottom:'-7px'}}
                id="google_translate_element"
              >
              </div></li> */}
                            </ul>
                        </div>
                       <div className="col-lg-1 col-md-2 col-sm-6 col-4">
                            <div className="searchMenuWrapper">
                                {/*  <div className="searchWrap">
                                    <i onClick={clickHandler} className="fa fa-search"></i>
                                    <div className={trigger ? 'searchform active' : 'searchform'}>
                                        <form onSubmit={submitHandler}>
                                            <input
                                                placeholder="search here"
                                                value={search}
                                                type="text"
                                                onChange={event => setSearch(event.target.value)}
                                            />
                                            <button><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </div>  */}
                                <div onClick={responsiveHandler} className="responsiveTrigger">
                                    <span className="first"></span>
                                    <span className="second"></span>
                                    <span className="third"></span>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}
 export default compose(
  withReducer("HeaderBottom1", reducer),
  connect(),withRouter
)(HeaderBottom);  