import React, { Component } from "react";
import Joi from "joi-browser";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink, Link } from "react-router-dom";
import "../Form/style.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";

import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

class FormCustomerSignUp extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      email: "",
      password: "",
      error: {},
      type: "password",
      passFlag: false,
      fname: "",
      lname: "",
      phone: "",
      pass1: "",
      pass2: "",
      address: "",
      DOB: "",
      dateType: "text",
      termsConditions: false,
      termsSubmitFlag: false,
      passFlag: false,
      passSubmitFlag: false,
      termsFlag: true,
      termsSubmitFlag: false,
      captcha: null,
      buttonFlag: false,
      loaderFlag: false,
      errorFlag: false,
      errorMassage15: "",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    if (localStorage.adminToken1) {
      this.props.history.push("./admin");
    } /* else{
      this.props.history.push('./adminsignin') 
    } */
    /* if(localStorage.adminToken1){
    this.props.history.push('./adminsignin')
  }
} */
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "must be a valid email";
              break;
            default:
              err.message = "must enter an email";
              break;
          }
        });
        return errors;
      }),
    fname: Joi.string()
      .min(3)
      .max(20)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "between 3 to 20 characters";
              break;
          }
        });
        return errors;
      }),
    lname: Joi.string()
      .min(3)
      .max(20)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "between 3 to 20 characters";
              break;
          }
        });
        return errors;
      }),
    phone: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter a mobile number";
              break;
          }
        });
        return errors;
      }),

    pass1: Joi.string()
      .min(8)
      .max(15)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "between 3 to 20 characters";
              break;
          }
        });
        return errors;
      }),

    address: Joi.string()
      .min(4)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "minimum 4 characters";
              break;
          }
        });
        return errors;
      }),
    DOB: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "must enter date of birth";
              break;
          }
        });
        return errors;
      }),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      lname: this.state.lname,
      phone: this.state.phone,
      email: this.state.email,
      pass1: this.state.pass1,
      DOB: this.state.DOB,
      address: this.state.address,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
      this.setState({ passSubmitFlag: true });
    } else {
      if (this.state.passFlag) {
        if (this.state.termsFlag) {
          this.setState({ termsSubmitFlag: true });
          /* 
                            termsSubmitFlag
                            termsFlag
                            */
        } else {
          this.setState({ loaderFlag: true, errorFlag: true });
          let obj = {
            password: this.state.pass1,
            email: this.state.email,
            firstName: this.state.fname,
            lastName: this.state.lname,
            phoneNumber: "+" + this.state.phone,
            address: this.state.address,
            dob: this.state.DOB,
          };
          this.props.dispatch(Actions.customerSignUp(obj));

          /*  setTimeout(() => {
            this.setState({
              email: "",
              password: "",
              DOB: "",
              address: "",
              fname: "",
              lname: "",
              phone: "",
              termsConditions: false,
              passSubmitFlag: false,
              termsFlag: true,
              pass1: "",
              pass2: "",
            });
          }, 1000); */
        }
      } else {
        this.setState({ passSubmitFlag: true });
      }
    }
  };
  handleSent = () => {
    this.setState({ show: false });
  };
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleConditions = () => {
    if (this.state.termsFlag) {
      this.setState({ termsFlag: false });
    } else {
      this.setState({ termsFlag: true });
    }
  };
  handleConfirmPass = (e) => {
    let pass2 = e.target.value;
    this.setState({ pass2: pass2 });
    if (
      pass2.match(this.state.pass1) &&
      pass2.toString().length === this.state.pass1.toString().length
    ) {
      this.setState({ passFlag: true });
    } else {
      this.setState({ passFlag: false, passSubmitFlag: true });
    }
  };
  onChangeCaptha = (value) => {
    if (value === null) {
      this.setState({ captcha: null, buttonFlag: true });
    } else {
      this.setState({ captcha: value, buttonFlag: false });
    }
  };
  componentDidMount() {
    if (!toast.isActive(this.props.toastId.current)) {
      this.props.toastId.current = toast.error(
        "Signup first to get access to our premium services",
        {
          position: "top-right",
          toastId: "logined",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.customerSignUpReducer;
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg === null && !(resNumber1.errMsg === undefined)) {
        this.setState({ show: true, loaderFlag: false });
      } else {
        if (this.state.errorFlag) {
          this.setState({
            show: false,
            loaderFlag: false,
            errorFlag: false,
            errorMassage15: resNumber1.errMsg,
          });
          if (!toast.isActive(this.props.toastId.current)) {
            this.props.toastId.current = toast.error(
              "Something went Wrong OR Your Email is Already Exists please Sign In",
              {
                position: "top-right",
                toastId: "logined",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          /*   this.props.history.push('/signin') */
          /* this.props.history.push('/signin') */
        }
      }
    }
  }
  render() {
    return (
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/05-01.png")} />
              <input
                placeholder="First Name"
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="text"
              />

              {this.state.error.fname && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.fname}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/05-01.png")} />
              <input
                placeholder="Last Name"
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="text"
              />

              {this.state.error.lname && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.lname}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/date.png")} />
              <input
                placeholder="Date of birth"
                value={this.state.DOB}
                name="DOB"
                onChange={this.changeHandler}
                min="1900-01-01"
                max={`${new Date().getFullYear() - 10}-12-31`}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type={this.state.dateType}
                onFocus={() => this.setState({ dateType: "date" })}
              />

              {this.state.error.DOB && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.DOB}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-12">
            <div className="formInput15">
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                onlyCountries={[
                  "ae",
                  "pk",
                  "us",
                  "gb",
                  "bd",
                  "in",
                  "eg",
                  "kz",
                  "ir",
                  "sa",
                  "ph",
                  "om",
                  "kw",
                ]}
                masks={{
                  ae: ".. ... ....",
                  at: "... .......",
                  us: "... ... ...",
                  gb: ".... ... ..",
                  bd: "... .... ...",
                  in: "... .... ...",
                  eg: "... .... ...",
                  kz: "... .... ...",
                  ir: "... .... ...",
                  sa: "... .... ..",
                  ph: "... .... ...",
                  om: "... .... .",
                  kw: "... .... ...",
                  pk: "... .......",
                }}
                country={"ae"}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone: phone })}
              />

              {this.state.phone === "" ? (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.phone}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/03-01.png")} />
              <input
                placeholder="Email"
                value={this.state.email}
                name="email"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="email"
              />

              {this.state.error.email && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.email}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/07-01.png")} />
              <input
                placeholder="Address"
                value={this.state.address}
                name="address"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="text"
              />

              {this.state.error.address && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.address}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/04-01.png")} />
              <input
                placeholder="Enter your password"
                value={this.state.pass1}
                name="pass1"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="password"
              />

              {this.state.error.pass1 && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.pass1}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src={require("../../images/signIn/04-01.png")} />
              <input
                placeholder="Confirm your password"
                value={this.state.pass2}
                name="pass2"
                onChange={this.handleConfirmPass}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="password"
              />
              {this.state.passSubmitFlag ? (
                this.state.passFlag ? (
                  ""
                ) : (
                  <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    Password does not match
                  </p>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          {/* errorMassage15 */}
          <div
            className="col-12"
            style={{ marginTop: "0px", marginBottom: "10px" }}
          >
            <p style={{ color: "white" }}> {this.state.errorMassage15}</p>
          </div>
          <div className="col-12" style={{ marginTop: "0px" }}>
            <div className="signon-bottom-area">
              <div>
                <div className="checkbow-style-area" style={{ color: "white" }}>
                  {" "}
                  <span>
                    {" "}
                    <input
                      class="styled-checkbox-signup"
                      name="termsConditions"
                      onChange={this.handleConditions}
                      id="styled-checkbox-term"
                      type="checkbox"
                      value="valueterm"
                    />
                    <label for="styled-checkbox-term"></label>
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "15px",
                      marginLeft: "10px",
                      marginTop: "-10px",
                    }}
                  >
                    By creating an account you agree to our
                  </span>
                </div>
                <p
                  style={{
                    color: "#666666",
                    textDecoration: "underline",
                    marginLeft: "30px",
                  }}
                >
                  <NavLink
                    style={{ color: "#fff", textDecoration: "underline" }}
                    exact
                    to="/terms-and-conditions"
                  >
                    {" "}
                    Terms & Conditions
                  </NavLink>
                </p>
                {this.state.termsSubmitFlag ? (
                  this.state.termsFlag ? (
                    <p
                      style={{
                        fontWeight: "550",
                        marginTop: "8px",
                        color: "red",
                      }}
                    >
                      You must accept the terms & Conditions
                    </p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div></div>
            </div>
            <div className="signon-bottom-area1">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                onChange={this.onChangeCaptha}
              />
              <div className="button-loaader-area-cutomer-signup">
                {this.state.loaderFlag ? (
                  <div style={{ marginRight: "10px" }} className="loader"></div>
                ) : (
                  ""
                )}
                <button disabled={this.state.buttonFlag} type="submit">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Body style={{ padding: "0px" }}>
            <div className="signup-verify-email-popup">
              <div className="signup-verify-email-popup-top-area">
                <center>
                  <img src={require("../../images/signIn/emailverify.png")} />
                </center>
              </div>
              <div className="signup-verify-email-popup-bottom-area">
                <center>
                  <h3>Congratulations!</h3>
                  <p>
                    Your account is successfully created. Please verify your
                    email to processed.
                  </p>
                </center>
                <hr />
                <center>
                  {" "}
                  <button
                    className="button-verify-email-address"
                    style={{ color: "black" }}
                    onClick={() => this.props.history.push("/signin")}
                  >
                    OK
                  </button>
                </center>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerSignUpReducer: state.CustomerSignUP.customerSignUpReducer,
  };
};

export default compose(
  withReducer("CustomerSignUP", reducer),
  connect(mapStateToProps),
  withRouter
)(FormCustomerSignUp);
