import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col,
  Button,Modal
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Icon,Table } from "semantic-ui-react";
import "./style.scss";
import { Tabs } from "antd";
import OpenFiles from './openFile'
import CounsellingUser from './counseling'
import ScheduleMeeting from './scheduleMeeting'
import UserInvoices from './userInvoices'
import GovtFee from './govtFees'
import AvatarEditor from 'react-avatar-editor';
import "antd/dist/antd.css";
import * as Actions from "./store/actions";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";

import withReducer from "../../store/withReducer";
import OpenCases from './openCases'

const { TabPane } = Tabs;
class UserProfile extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        image:'',
        show:false,
        show1:false,
        show2:false,
        img:false,
        upload:false,
        flag:false,
        flag2:true,
        flag1:false,
        user:{},
        profileImage:'',
        imageHash:'',
        upload1:false
    };
  }
  handleClose=()=>{
      this.setState({show:false})
  }
  handleShow=()=>{
    this.setState({show:true})
}
handleClose1=()=>{
    this.setState({show1:false})
}
handleShow1=()=>{
  this.setState({show1:true,upload1:false})
}
 
  componentDidMount() {}
 
  handleImagePreview = (event)=>{
    this.setState({image:URL.createObjectURL(event.target.files[0]),img:true,show:true,upload:false})

   
  }
  setEditorRef = (editor) => this.editor = editor;
   updatePhoto=()=>{
    if (this.editor) {
      const canvas = this.editor.getImage().toDataURL();
  let obj={
    fileData:canvas
  }
  this.props.dispatch(Actions.updateProfileCustomer(obj))
  this.setState({upload:true,flag:true})

  }
  
  }
  componentWillMount(){
    this.props.dispatch(Actions.getCustomer());
  }
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.getCustomerReducer;
    if (!resNumber1.isLoading) {
        if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
    if(this.state.flag2){
        this.setState({flag2:false,user:resNumber1.data.user,profileImage:resNumber1.data.user.profileImage,imageHash:Date.now()})
    }
        }else{
             if(resNumber1.errMsg==="This token is expire! Please log in again"){
          this.props.dispatch(Actions.logoutUser())
                }
        }
    }  
    let resNumber2 =nextProps.updateProfileReducer;
    if (!resNumber2.isLoading) {
        if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
       if(this.state.flag){
          this.setState({profileImage:resNumber2.data.profileImage,show:false,flag:false,imageHash:Date.now()})
       }
        }
    }  
    let resNumber3 =nextProps.updateBasicInfoReducer;
    if (!resNumber3.isLoading) {
        if (resNumber3.errMsg == null && !(resNumber3.data===undefined)) {
       if(this.state.flag1){
          this.setState({user:resNumber3.data.user,show1:false,flag1:false,upload1:false})
       }
        }
    }

    /*  */
    
}
 handleupdateInfo=(e)=>{
    e.preventDefault()
    this.setState({upload1:true,flag1:true})
    let obj={
      firstName:document.getElementById('fname').value,
      lastName:document.getElementById('lname').value,
      phoneNumber:((document.getElementById('phone1111').value).replace(/\s/g, '')).replace(/\s/g, '-'),
      address:document.getElementById('address1111').value,
      dob:document.getElementById('date1111').value
    }
    this.props.dispatch(Actions.updateBasicInfoCustomer(obj))
    
    /* updateBasicInfoCustomer */
    }
     logoutCustomer=()=>{
        localStorage.setItem('loginFlag','')
        this.props.dispatch(Actions.logoutUser())
    } 
  render() {
      /* console.log(this.state.show,"ghhhhhhhhhhhhhh") */
    return (
      <div className="userPanleArea444">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12" style={{marginTop:'15px'}}>
              <div className="user-panel-new-left-area">
                <div className="rider_panel_page_main_area-left-area">
                  <div className="rider_panel_page_main_area-left-area-top-edit-btn-profile">
                    <div
                      onClick={this.handleShow1}
                      className="rider-panel-edit-profile-btn"
                    >
                      Edit
                    </div>
                  </div>
                  <div className="rider-panel-edit-profile">
                    <div class="rider-panel-edit-profile-img">
                      {" "}
                      {/* jj profileEditCamera */}
                      <img
                        src={
                          this.state.profileImage.length>0
                            ? `${this.state.profileImage}?${this.state.imageHash}`
                            : "https://wow-app.s3-ap-southeast-1.amazonaws.com/profile_images/profile_pic.png"
                        }
                        width={130}
                        height={130}
                        style={{
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                      />
                      <span class="rider-panel-edit-status rider-panel-edit-active">
                        {" "}
                        <div className="image-upload">
                          <label for="file-input">
                            <img
                              style={{ cursor: "pointer" }}
                              src={require("../../images/profile/2.png")}
                            />
                          </label>

                          <input
                            id="file-input"
                            type="file"
                            onChange={this.handleImagePreview}
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="rider-panel-edit-profile-name">
                    <center>
                      <p className="rider-panel-edit-profile-name-text">
                      {this.state.user.firstName && this.state.user.firstName+" "+this.state.user.lastName} 
                      </p>
                    </center>
                  </div>

                  <div className="rider-panel-gender-and-location-area">
                    <p>
                      <span style={{ marginRight: "10px", marginTop: "-4px" }}>
                        <Icon size="large" name="tablet alternate" />
                      </span>
                      {this.state.user.phoneNumber && this.state.user.phoneNumber}
                    </p>
                    <p>
                      <span style={{ marginRight: "8px", marginTop: "-4px" }}>
                        {" "}
                        <Icon size="large" name="mail outline" />
                      </span>{" "}
                      {this.state.user.email && this.state.user.email}{" "}
                    </p>
                    <p style={{display:'flex',justifyContent:'flex-start'}}>
                      <span style={{ marginRight: "10px", marginTop: "-4px" }}>
                        <Icon size="large" name="map marker alternate" />
                      </span>{" "}
                      {this.state.user.address && this.state.user.address}{" "}
                    </p>
                  </div>
                  <div className="rider-panel-gender-and-location-area1">
                    <p>
                      <span style={{ marginRight: "4px", marginTop: "-4px" }}>
                        <Icon size="large" name="file alternate outline" />
                      </span>{" "}
                      <Link
                        style={{ color: "white" }} to="/customerfile">
                      Open a File
                      </Link>
                    </p>
                    <p>
                      <span style={{ marginRight: "10px", marginTop: "-4px" }}>
                        <Icon size="large" name="power off" />
                      </span>
                      <Link
                        style={{ color: "white" }}
                        onClick={this.logoutCustomer} to="/signin"
                      >
                        Logout
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9  col-12"  style={{marginTop:'15px'}}>
              <div className="user-panel-new-right-area">
                <div className="user-panel-new-right-area-top-tabs-area">
                  <Tabs defaultActiveKey="1" /* onChange={callback} */ type="card">
                    <TabPane
                      tab={
                        <span>
                          <Icon size="large" name="bookmark outline" />
                         {" "} File Information
                        </span>
                      }
                     key="1">
                    <OpenFiles /> 
                    </TabPane>
                    <TabPane 
                    tab={
                        <span>
                          <Icon size="large" name="balance scale" />
                         {" "} Case Information
                        </span>
                      }
                    key="2">
                     <OpenCases />
                    </TabPane>
                    <TabPane 
                     tab={
                        <span>
                          <Icon size="large" name="sliders horizontal" />
                         {" "}  Invoices Information
                        </span>
                      }
                    key="3">
                      <UserInvoices />
                    </TabPane>
                    <TabPane 
                     tab={
                        <span>
                          <Icon size="large" name="clock outline" />
                         {" "} Meeting dates
                        </span>
                      }
                    key="4">
                     <ScheduleMeeting />
                    </TabPane>
                    <TabPane 
                    tab={
                        <span>
                          <Icon size="large" name="calendar alternate outline" />
                         {" "} Counselling
                        </span>
                      }
                    key="5">
                      <CounsellingUser />
                    </TabPane>
                    {/* <TabPane tab="Govt Cases Fee" key="6">
                      <GovtFee />
                    </TabPane> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} centered size='sm'>
        <Modal.Header closeButton>
              <Modal.Title style={{color:'#CBBC99'}}>
              <i className="fa fa-image"  aria-hidden="true"></i> <span><h3 style={{display:'inline',fontWeight:'500',color:'#CBBC99',fontSize:'24px'}}>Upload Picture</h3></span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <center>
              
            <div className="image_uploader_area">
                <AvatarEditor
                 ref={this.setEditorRef}
                   image={ this.state.img==false ? require("../../images/about/profileImg.png") :this.state.image  }
                   width={180}
                   height={180}
                   border={50}
                   color={[255, 255, 255, 0.6]} // RGBA
                   scale={1.2}
                   rotate={0}
                   borderRadius={140}
                />
                    </div>
                 {this.state.upload ? <div>  <div className="loader"></div><p>uploading Please wait....</p></div> :''}      
            </center>
               
            </Modal.Body>

            <Modal.Footer>
            <div className="">
            <Button onClick={this.handleClose}  className='website-text' variant="secondary" style={{backgroundColor:'#CBBC99',color:'white'}}>
                   cancel
                            </Button>
                <Button  onClick={this.updatePhoto}    className="upload_btn" className='website-text' variant="secondary" style={{backgroundColor:'#CBBC99',color:'white',marginLeft:'5px'}}>
                      Save
                            </Button>
                </div>

          </Modal.Footer>
           
           
          </Modal>
          <Modal show={this.state.show1} onHide={this.handleClose1} centered size='md'>
        <Modal.Header closeButton>
              <Modal.Title style={{color:'#CBBC99'}}>
              <i className="fa fa-user-plus"  aria-hidden="true"></i> <span><h3 style={{display:'inline',fontWeight:'500',color:'#CBBC99',fontSize:'24px'}}>Basic Info</h3></span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
         
              
            <form onSubmit={this.handleupdateInfo}  className='contactForm'>
                <div className="row">
                     <div className="col-sm-6 col-12">
                     <div className="formInput form-control field-design2">
                            <img src={require('../../images/signIn/05-01.png')} />
                            <input
                                placeholder="First Name"
                                required
                                name="fname"
                           defaultValue={this.state.user.firstName && this.state.user.firstName}
                         
                                id="fname"
                                className=""
                                style={{border:'none',fontWeight:'550',background:'none',border:'none',height:'35px',color:'#666666'}}
                                type="text" />

                        </div>
                      </div> 
                      <div className="col-sm-6 col-12">
                     <div className="formInput form-control field-design2">
                            <img src={require('../../images/signIn/05-01.png')} />
                            <input
                                placeholder="Last Name"
                             id="lname"
                                name="lname"
                                defaultValue={this.state.user.lastName && this.state.user.lastName}
                                required
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px',color:'#666666',fontWeight:'550'}}
                                type="text" />

                      </div>
                      </div>
                      <div className="col-sm-6 col-12">
                     
                     <div className="formInput form-control field-design2">
                            <img src={require('../../images/signIn/date.png')} />
                            <input
                                placeholder="Date of birth"
                                style={{color:'#666666'}}
                                id="date1111"
                                defaultValue={this.state.user.dob && new Date(this.state.user.dob).toISOString().substring(0, 10)}
                               name="DOB"
                               required
                              min="1900-01-01" max={`${(new Date().getFullYear()-10)}-12-31`}
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px',color:'#666666',fontWeight:'550'}}
                                type="date"
                             /*    type={this.state.dateType}
                                onFocus={()=>this.setState({dateType:'date'})} */
                                />

                       </div>
                      </div>
                
                      <div className="col-sm-6 col-12">
                   
                   <div className="formInput16" >
                   <PhoneInput
                    inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true,
                       id:'phone1111'
                     }}
                     inputStyle={{color:'#666666'}}
                     onlyCountries={['ae', 'pk','us','gb','bd','in','eg','kz','ir','sa','ph','om','kw']}
                     masks={{pk:'... .......',ae: '.. ... ....', at: '... .......',us: '... ... ...',gb: '.... ... ..',bd: '... .... ...',in:'... .... ...',eg:'... .... ...',kz:'... .... ...',ir:'... .... ...',sa:'... .... ..',ph:'... .... ...',om:'... .... .',kw:'... .... ...'}}
                     value={this.state.user.phoneNumber && this.state.user.phoneNumber}
                   country={'ae'}
                 /*   value={this.state.phone}
                   onChange={(phone)=>this.setState({phone:phone})}  */
                 />
                

                  </div>
               </div>
                   
               
                     
                    <div className="col-sm-6 col-12">
                        <div className="formInput form-control field-design2">
                            <img src={require('../../images/signIn/07-01.png')} />
                         
                            <input
                                placeholder="Address"
                                defaultValue={this.state.user.address && this.state.user.address}
                             id="address1111"
                              name="address"
                              required
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px',color:'#666666',fontWeight:'550'}}
                                type="text" />

                      </div>
                    </div>
              
                    <div className="col-12">
                    <center>
                   {this.state.upload1 ? <div>  <div className="loader"></div><p> Please wait....</p></div> :''}</center>                   
                    
                      </div>
                 
                </div>
                     
       
            <Modal.Footer>
            <div className="">
            <Button onClick={this.handleClose1}  className="button-of-the-update" variant="secondary" style={{backgroundColor:'#CBBC99',color:'white'}}>
                   cancel
                            </Button>
                <Button type="submit"   className="upload_btn button-of-the-update" className='website-text' variant="secondary" style={{backgroundColor:'#CBBC99',color:'white',marginLeft:'5px'}}>
                      Update
                            </Button>
                </div>

          </Modal.Footer>
          </form>
            </Modal.Body>

           
           
           
          </Modal>
      </div>
    );
  }
}
const mapStateToProps=(state)=> {
    return {
        getCustomerReducer: state.UserProfile1.getCustomerReducer,
        updateProfileReducer:state.UserProfile1.updateProfileReducer,
        updateBasicInfoReducer:state.UserProfile1.updateBasicInfoReducer,
        getFilesReducer:state.UserProfile1.getFilesReducer
    };
  } 
export default compose(
    withReducer("UserProfile1", reducer),
    connect(mapStateToProps)
  )(UserProfile);
