import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const ConstructionContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>	Construction and Infrastructure   </h3>
           <p>We provide services to help you resolve legal issues related to construction, from all types of documentations to any arising dispute in your project. We offer clients all the insights into the shifting of the commercial market and tell them to act accordingly. Governments and states, energy companies, developers, and contractors all can take our advice on their legal matters from us.</p>
           <p>We make sure that our approach is clear without surprises and we also ensure that the documents and the pieces of advice are drafted concisely to make all the process more convenient for our clients. </p>
           <p>Our team of lawyers is specialized in all the aspects of construction and infrastructure law, the behavior of contractors and employers, relation between employers and employees, and other industry matter. </p>
           <p>Just to give an idea of our legal services there is a list below:</p>
           <ul style={{color:'#666666'}}>
               <li>•	Tender documentation </li>
               <li>•	Contract administration </li>
               <li>•	Strategic Project Planning </li>
               <li>•	Risk managing strategies</li>
               <li>•	Claims preparation </li>
               <li>•	Services related to all kinds of dispute </li>
           </ul>
               {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default ConstructionContent