import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import SearchSidebar from "../../../components/SearchSidebar";
import CetagorySidebar from "../../../components/CetagorySidebar";
import RecentPosts from "../../../components/RecentPosts";
import BlogPost from "../../../components/BlogPost";
import Tags from "../../../components/Tags";
import Instagram from "../../../components/Instagram";
import BreakingNews from "../../../components/breaking";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";
// images
import breadcumb from "../../../images/breadcumb/News-1.jpg";
import CetagorySidebar1 from "./sideBar";
import { Helmet } from "react-helmet";

import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "News" }];

function Dubai_DEDNews() {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>News Room - Legal Emirates </title>
        <link
          rel="canonical"
          href="https://legalemirates.com/newDubai_DED "
        />
        <meta
          name="description"
          content="Browse Legal Emirates newsroom and discover more about the latest news."
        />
      </Helmet>

      <header className="headerArea">
        {/* <BreakingNews />
               <HeaderTop className="headerTop" /> */}
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="News Room"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <div className="blogPostArea blogPostLeftArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <aside>
                {/*   <SearchSidebar className="searchSidebarWrapper" /> */}
                {/* <CetagorySidebar title="Cetagory" className="cetagoryWrap" />
                <RecentPosts className="recentPostWrapper" />
                <Tags /> */}
                <CetagorySidebar1 />
                {/*   <Instagram /> */}
              </aside>
            </div>
            <div className="col-lg-8">
              <div className="centerContent">
                <div className="selfCenter standardWidth">
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="Dubai_DED"
                    options={{ height: 4200 }}
                    noHeader
                    noFooter

                    /*   onComplete={action} */
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
}
export default Dubai_DEDNews;
