import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import RecentPosts from '../../../components/RecentPosts'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
import Twitter from '../../../components/twitter'
// images
import breadcumb from '../../../images/breadcumb/news-2.jpg'
import blog3 from '../../../images/blog-details/blog-2.jpg'
import {Helmet} from "react-helmet";

import './style.scss'



const COVIDNews = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs - Legal Emirates  </title>
                <link rel="canonical" href="https://legalemirates.com/blogs/coronavirus-news " />
                <meta name="description" content="If you are looking for visual consultations for your corporate or commercial issues, take live counseling here at Legal Emirates. " />
            </Helmet>

            <header className="headerArea">
 {/*            <BreakingNews />
                <HeaderTop className="headerTop" />  */}
                <HeaderBotton className="headerBottomArea" />
            </header>
            <div className="blogPostArea blogPostLeftArea" style={{paddingTop:'45px'}}>
                <center>
                   <h1 style={{fontWeight:'550'}}> Coronavirus</h1> 
                   <h2 style={{fontWeight:'550'}}>   The good news of Coronavirus</h2>
                   <p style={{fontWeight:'550'}}>16 May 2020/Dubai</p>
                </center>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                     
                <div  className="blogPostWrapper1">
                    <div className="blogPostImg">
                        <img src={blog3} alt="" />
                    </div>
                    <div className="blogPostContent" style={{marginTop:'30px'}}>
                    <p style={{fontWeight:'550'}}>Every day since the outbreak of coronavirus the situation is getting worse and worse around the world. There is nothing but fear, worry, and uncertainty. The concern about it is justified but, whether it’s a natural disaster, a war or a great public health threat there is always hope and one must be optimistic about it. In the case of COVID-19, it’s seems everything is getting worse as the days are passing and maybe it's true but there are certain things that give us hope. We will discuss some of them. </p>
                    <h4 style={{fontWeight:'550'}}>  Most people recover</h4>
                   <p style={{fontWeight:'550'}}>The estimates now are suggesting that 99% of the total COVID-19 infected people are going to recover. And till now the death rate is about 1%. Which is far less than some previous virus outbreaks. However, the number of Coronavirus affected people is increasing much faster as compared to the upper viruses and far more cases are expected, but based on fatality rates it could be far worse. </p>
                   <h4 style={{fontWeight:'550'}}>  Children seem less infected</h4>
                   <p style={{fontWeight:'550'}}>The vast majority that has caught up in trouble due to COVID-19 is of adults. And when the kids are infected, they recover so fast without having much danger. That’s really good news for kids and parents. But there is a problem because children can carry the virus without showing any symptoms and there is a high possibility that elders near them can get infected. </p>
                   <h4 style={{fontWeight:'550'}}>   The number of cases in China is declining </h4>
                   <p style={{fontWeight:'550'}}>That’s very good news for the whole world because Wuhan province of China was the center where the outbreak began and spread to probably every corner of the world, and now the cases there are not rising, the spike on the graph is maintaining its height that’s a big relief for China and for the rest of the world as well. But the credit must be given to the national authorities for containing infection through strict measures and aggressive diagnostic testing. This is proof that efforts to contain the spread can be successful. </p>
                   <h4 style={{fontWeight:'550'}}>   Organizations have stepped forward to play their role </h4>
                   <p style={{fontWeight:'550'}}>At this crucial time, let us take a moment and appreciate the support of the national and international organizations around the world. It doesn’t matter where do you live there are innumerable authorities, celebrities, businessmen and politicians who are playing their role in helping you people. So, we all should be grateful for that. 

</p>
<h4 style={{fontWeight:'550'}}>  Internet </h4>
                   <p style={{fontWeight:'550'}}>In the time like these where the world is temporarily locked down, there are hundreds of thousands of people who have started working in virtual offices as they are working from home. And while staying at home we are receiving many facilities just because we have access to the internet. </p>
                   <p style={{fontWeight:'550'}}>The new coronavirus is definitely a big problem and expected to become bigger as the clock ticks. But amid all this, there are still positive stories, messages, and reasons to remain hopeful. This is a great opportunity to realize how much we have been divided as humans for a long time and a great time to unite. </p>
                 </div>
                </div>
           
                  
                        </div>

                    </div>
                </div>
            </div>
           {/*  <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default COVIDNews