import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const CrimnalContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>Criminal law </h3>
                <p>UAE criminal law requires special knowledge because the law terms in UAE are derived from Islamic Shariah Laws. Our team of attorneys has a long history in dealing with criminal cases, we handle diverse litigation matters, we have represented clients before foreign state panels, Interpol and other international crime control agencies sometimes in unfolding complex cases that are international in scope.  </p>
                <p>Our criminal lawyers have considerable expertise in working with Prosecutor’s office. They provide services from an initial inquiry to a complex criminal matter and will advise you honestly and lawfully. </p>
                <p>We deal with all the sub-categories of criminal law including:</p>
              <ul style={{color:'#666666'}}>
                  <li>•	Cyber Crimes & Security</li>
                  <li>•	Data Security </li>
                  <li>•	Drug crimes</li>
                  <li>•	Financial crimes</li>
                  <li>•	Sex crimes  </li>
                  <li>•	Extradition </li>
                  <li>•	Other common crimes</li>
              </ul>
                {avatar && (
                    <div className="avatarWra">
                       {/*  <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}
export default CrimnalContent