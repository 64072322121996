import React, { useState } from "react";
import FormCounsellingPaid from "./counsellingPaidForm";
import { NavLink, Link } from "react-router-dom";
import SearchSidebar from "../SearchSidebar";
import "./style.scss";
import "./style.css";
const CounsellingPaid = ({ className }) => {
  const [search, setSearch] = useState();
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(search);
  };
  return (
    <div className="CounsellingPaid">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <div className="counselling-images-area">
              <img src={require("../../images/logo/logo2.png")} />
            </div>
            <hr class="line-Bar-style"></hr>
            <div className="counselling-left-content-area">
              <h2>We are doing our best to serve you</h2>
            </div>
            <div className="counselling-left-content-area-search">
              <p>Enter order number of your previous case</p>
              <form className="counselling-search-bar" onSubmit={submitHandler}>
                <input
                  placeholder="search here"
                  value={search}
                  // className={}
                  type="text"
                  onChange={(event) => setSearch(event.target.value)}
                />
                <button>
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
            <div className="counselling-left-content-area1">
              <h2>Order Summary</h2>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="counselling-right-content-area">
              <div className="counselling-right-content-area-header">
                <h2>
                  <img
                    style={{ width: "28px" }}
                    src={require("../../images/topHeader/48v-01.png")}
                  />{" "}
                  Counselling Paid Writing
                </h2>
              </div>
              <div className="counselling-right-content-area-form-content">
                <h2>Your Personal Details</h2>
              </div>
              <FormCounsellingPaid />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CounsellingPaid;
