import React, { useState } from 'react'
import CustomerFileForm from './CustomerFileForm'
import { NavLink, Link } from 'react-router-dom'
import SearchSidebar from '../SearchSidebar'
import './style.scss';
import './style.css';
const CorporateFileArea = ({ className }) => {
    const [search, setSearch] = useState()
    const submitHandler = e => {
        e.preventDefault()
        console.log(search)
    }
    return (
        <div className="customer-file">
            <div className="container">
                {/*     <div className="customer-file-logo-area">
                    <img src={require('../../images/logo/logo2.png')} />
                    
                    </div>
                    <hr class="line-Bar-style1"></hr>
                 <div className="row row-adjustment">
                 <div className="col-lg-3 col-3 cols-file-padding">
                     </div>
                 <div className="col-lg-5 col-9">
                     <div className="file-title-area">
                     <h3 className="customer-top-area-heading">  Customer File</h3>
              
                     </div>
         
                 </div>
                 <div className="col-lg-4 col-12">
                 <div className="file-date-area">
              <p>Date  <span className="date-label-deisgin-box">30</span> <span className="date-label-deisgin-box">05</span> <span className="date-label-deisgin-box">2020</span> </p>
                 </div>
                </div>
                 </div>   
                 <div className="file-no-area">
                <p>File no. BZ/000</p>
                </div>  */} 
                          
                <CustomerFileForm /> 
              
              
               
            </div>
        </div>
    )
}
export default CorporateFileArea