import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../Form/style.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  

  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class OpenFileTerms1 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        
        expired:true,
    } 
  }

    render() {

       
        return (
          <div>
          
                <div className="password-link-expiration-page">
                 <center>
                 <img style={{width:'150px',marginTop:'30px'}}  src={require('../../images/logo/logo2.png')} />
                <h1>Invoice Terms & Conditions <br/> <span style={{fontSize:'16px'}}>(BADER AL ZAABI ADVOCATE OFFICE)<br/> (for Opening a file)</span></h1>
                
                  </center>
                 <div className="invoice-terms-condtions" >
               <ol>
                  <li>   This a computer generated invoice to open a file payment. This is an electronic receipt; any signature or stamp on it is not required.

              </li>
       <li>The acknowledgment of this opening a file receipt by the customer of the paid amount is shown as a NON-REFUNDABLE payment. The customer has no right to claim the refund of opening a file payment.</li>
       <li>BADER HAMAD AL ZAABI’s ADVOCATES & LEGAL CONSULTANTS reserve the right to review and aim the above terms as per as the judges fit and as per the applicable laws.</li> 
          </ol>
                 </div>
                </div>   
          </div>
        )
    }
}
 
export default OpenFileTerms1