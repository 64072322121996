import React from 'react'
import SectionTitle from '../Title'
import './style.scss'
/*  Started Plan   Basic Plan   Advanced Plan*/
import { NavLink, Link } from 'react-router-dom'
const pricingpanel = [
    {
        icon: 'flaticon-auction-2',
        title: 'Basic',
        text: 'We allow you to obtain the right legal opinion of the first degree regarding your legal questions or any other advice you wish to know.',
        price: '$137',
    },
    {
        icon: 'flaticon-money-1',
        title: 'Silver ',
        text: 'We will write and prepare notices of justice for our clients, follow up with them, pay their fees and complete their procedures until the response is received and notify you of the result.',
        price: '$275',
    },
    {
        icon: 'flaticon-market',
        title: 'Gold',
        text: 'We draft contracts and agreements and documents and review them for individuals or companies and everything related to organizing the relationship between the contracting parties and is in the interest of the parties to the contract',
        price: '$1362',
    },
]
const PricingTable = ({ className, title, subTitle }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
            {/*         {pricingpanel.map((pricing,i) => ( */}
                        <div /* key={i}  */className="col-lg-4 col-md-6 col-12">
                            <div className="pricingWrapper">
                               {/*  <i className={`fi ${pricing.icon}`}></i> */}
                                <h3>Basic</h3>
                                <h2>$395</h2>
                                <p>In our basic plan, you will get premier class legal advice and opinions on your legal issues. </p>
                               <br />
                               <br />
                                <div className="btnStyle">
                                    <NavLink exact to='/contact-us'>Get Started</NavLink>
                                </div>
                                
                            </div>
                            </div>
                            <div /* key={i}  */className="col-lg-4 col-md-6 col-12">
                            <div className="pricingWrapper">
                               {/*  <i className={`fi ${pricing.icon}`}></i> */}
                                <h3>Silver</h3>
                                <h2>$675</h2>
                                <p>
                                In our silver plan, we will work on your judicial notices while taking all the necessary measures possible.

</p> 
<br />
                          
                                <div className="btnStyle">
                                    <NavLink exact to='/contact-us'>Get Started</NavLink>
                                </div>
                                
                            </div>
                            </div>
                            <div /* key={i}  */className="col-lg-4 col-md-6 col-12">
                            <div className="pricingWrapper">
                               {/*  <i className={`fi ${pricing.icon}`}></i> */}
                                <h3>Gold</h3>
                                <h2>$1362</h2>
                                <p>From viewing to drafting contracts and agreements, including organizing the relationship with the contracting parties.</p>
                                <div className="btnStyle">
                                    <NavLink exact to='/contact-us'>Get Started</NavLink>
                                </div>
                                
                            </div>
                        
                        </div>
                   {/*  ))} */}
                </div>
            </div>
        </div>
    )
}
export default PricingTable