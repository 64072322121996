import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

// images
import testmonial from "../../images/testimonial/1.png";
import testmonial2 from "../../images/testimonial/d.png";
import testmonial3 from "../../images/testimonial/a.png";
import testmonial4 from "../../images/testimonial/b.png";
import testmonial5 from "../../images/testimonial/c.png";
import testmonial6 from "../../images/testimonial/h.png";
import testmonial7 from "../../images/testimonial/e.png";

const sliders = [
  {
    text:
      "Here at Legal Emirates, I was treated much better than I could ever imagine from a law firm. They were so pleasing and helpful. They listened to my opinions first and guided me accordingly. Attorneys here went beyond and did everything they could to ensure the best outcome. I will definitely recommend this Law firm",
    images: testmonial2,
    title: "James Mary",
    subTitle: " DP World Ltd",
  },
  {
    text:
      "Legal Emirates was very lucky for me. They helped me with all my property and custody issues. I could not have asked for a better service, thanks again. I will come again if I feel a need in the future",
    images: testmonial3,
    title: "John Patricia",
    subTitle: "1st Business center",
  },
  {
    text:
      "People here are very professional and highly responsive. They helped me through a rough time in my divorce proceedings. I had had a very bad time with all this matter until I came here. Now I am done with this emotional divorce thing thanks to you people. Highly recommended",
    images: testmonial4,
    title: "Richard Susan",
    subTitle: "Commercial Bank of Dubai",
  },
  {
    text:
      "Legal Emirates have the most caring lawyers out there. Yes, I do personally know the CEO of this firm and that is why I am not going to say so many good things. They are professional, honest and get the job done in time. I had two separate issues and they helped me solve them within days. I would surely recommend Legal Emirates",
    images: testmonial5,
    title: "John parlus",
    subTitle: "Abu Dhabi Aviation",
  },
  {
    text:
      "They got me through a very hard time with all of my divorce and child support issues. Thanks to their team I am finally free of all this",
    images: testmonial6,
    title: "Thomas Sarah",
    subTitle: "Changlin Company",
  },
  {
    text:
      "I trusted them to protect my assets and business and they surpassed my expectations they were so professional. I am very pleased with all my litigation results. Decidedly suggested",
    images: testmonial7,
    title: "William Elizabeth",
    subTitle: "2nd Business center",
  },
];
const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
const Testmonial = ({ className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <Slider className="testmonialSlider" {...settings}>
              <div className="testimonialImages">
                <img src={testmonial} alt="" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-7 offset-lg-1 col-md-6">
            <div className="testimonialContent">
              <span>What People Say</span>
              <h2>Client Testimonials</h2>
              <Slider className="testmonialSlider" {...settings}>
                {sliders.map((slider, i) => (
                  <div key={i} className="slideItem">
                    <p>{slider.text}</p>
                    <div className="thumbWrap">
                      <div className="thumbImg">
                        <img src={slider.images} alt="" />
                      </div>
                      <div className="imgContent">
                        <h4>{slider.title}</h4>
                        <span>{slider.subTitle}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testmonial;
