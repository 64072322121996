import React, { Fragment,Component } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import SearchSidebar from '../../../components/SearchSidebar'
import CetagorySidebar from '../../../components/CetagorySidebar'
import RecentPosts from '../../../components/RecentPosts'
import BlogPost from '../../../components/BlogPost'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
// images
import breadcumb from '../../../images/breadcumb/recnet-news.jpg'
import BreakingNews from '../../../components/breaking'
import blog1 from '../../../images/blog-page/1.jpg'
import blog2 from '../../../images/blog-page/2.jpg'
import blog3 from '../../../images/blog-page/3.jpg'
import blog4 from '../../../images/blog-page/4.jpg'
import blog5 from '../../../images/blog-page/5.jpg'
import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Recent News' }
]
class RecentNews extends Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
            totalNews:[]
        };
      }
      componentWillMount(){
        fetch('https://newsapi.org/v2/top-headlines?country=ae&apiKey=0f501b6cc328413698222b75e7643db0')
        .then(response => response.json())
        .then(json => {
        if(json.status==="ok"){
this.setState({totalNews:json.articles})
        }
        })
        .catch(err => {console.error(err)
        alert("Something went wrong Please Again letter")
        }); 
      }
      componentDidMount(){
    
      }
    render(){
        if(this.state.totalNews.length>0){
            var newsArea=this.state.totalNews.map((news)=>{
                return      <div className="col-lg-4  col-12" >
            <div  className="blogPostWrapper">
                <div className="blogPostImg">
            <img style={{height:'220px'}} src={news.urlToImage} alt="" />
        </div>
        <div className="blogPostContent" style={{marginTop:'30px'}}>
            <h5>
            {news.title}
</h5>
            <p>{news.description}</p>
         <a href={news.url} className="design-linkdfd" target="_blank" >Read More</a>
        </div>
        </div>
                </div>
            })
        }
        
        return (
            <Fragment>
                <header className="headerArea">
     {/*            <BreakingNews />
                    <HeaderTop className="headerTop" />  */}
                    <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
                </header>
                <Breadcumb
                    className="breadcumbArea"
                    title="Recent News"
                    breadcumbMenu={breadcumbMenu}
                    background={breadcumb}
                />
                <div className="blogPostArea blogPostLeftArea">
                    <div className="container">
                        <div className="row">
                      {newsArea}
                            {/* <div className="col-lg-4">
                                <aside>
                                    <SearchSidebar className="searchSidebarWrapper" /> 
                                    <CetagorySidebar
                                        title="Cetagory"
                                        className="cetagoryWrap" />
                                    <RecentPosts className="recentPostWrapper" />
                                    <Tags />
                                    <Instagram />
                                </aside>
                            </div> */}
                        </div>
                    </div>
                </div>
               {/*  <NewsLetter
                    className="newsLetterArea"
                /> */}
                <FooterArea />
            </Fragment>
        )
    }
}

export default RecentNews